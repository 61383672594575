const initialState = {
  tickets: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "tickets/storeAllTickets":
      return { ...state, tickets: action.payload };
    case "tickets/init":
      return { ...initialState };
    default:
      return state;
  }
}

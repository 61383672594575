import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useEffect, useState } from "react";
import { nullLogTable, getLogTable } from "../../store/actions/logTables";
import { selectLogTable } from "../../store/selectors/logTable";
import NewTable from "../../components/newTable/newTable";
import logo from "../../geuzenetlog.png";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function AdminSubAdmin2() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const [tableSelect, setTableSelect] = useState("sync_log");
  const logTable = useSelector(selectLogTable);
  const headerRef = useRef();
  const topBarRef = useRef();
  const navigation = useSelector(selectNavigation);

  const dispatch = useDispatch();
  const customTimeFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear(),
      t = date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
    return `${y}-${m}-${d} ${t}`;
  };
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Admin", "Logs tables", "list"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (logTable.rows.length === 0 && validUser(appUser)) {
        dispatch(getLogTable(tableSelect));
      }
    };
    getItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logTable,
    dispatch,
    tableSelect,
    navigation.menuSub,
    navigation.menuMain,
  ]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className="logsTables">
        {" "}
        <div className="bigTableWrapper" ref={headerRef}>
          <div className="topBar" ref={topBarRef}></div>
          <select
            className="logsTableSelector"
            onChange={(e) => {
              setTableSelect(e.target.value);
              dispatch(nullLogTable());
            }}
          >
            <option value="sync_log">sync_log</option>
            <option value="dupa">dupa</option>
          </select>
          {logTable.rows.length > 0 ? (
            <NewTable
              topBarRef={topBarRef}
              wrapperRef={headerRef}
              data={logTable.rows.map((line) => {
                return {
                  ...line,
                  start_t: customTimeFormat(new Date(line.start_t), undefined),
                  reported: line.reported ? "Yes" : "No",
                };
              })}
              tit="logsTable"
              tabletype={"big"}
              columns={logTable.columns}
            />
          ) : (
            <img className="landingLogo" src={logo} alt="company logo" />
          )}
        </div>
      </div>
    );
  }
}

export default AdminSubAdmin2;

import "../css/adminSignalTest.css";
import {
  selectButtonState,
  selectAntennaTag,
} from "../store/selectors/navigation";
import axios from "axios";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import NewTable from "./newTable/newTable";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react"; // { useEffect, useRef, useState } // ,
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import {
  selectAntennaDetails,
  selectSignalData,
  selectLastBeep,
} from "../store/selectors/admin";
import { getSignal, storeSignalData } from "../store/actions/admin";
import SignalGauge from "../components/signalGauge";
import antIco from "../antIco2.png";
import antIcoActive from "../antIco3.png";
import orgIco from "../orgIco.png";
import signalIco from "../signal.png";
import colorsArray from "./colorsArray";
import AntennaSignalSpeedTest from "./antennaSignalSpeedTest";
import Button from "@mui/material/Button";

let ascRef = React.createRef();

let mounted;
const containerStyle = {
  width: "100%",
  height: "100vw",
  maxHeight: "500px",
  boxShadow: "-5px -5px 10px 5px rgba(0, 0, 0, 0.2)",
};
const AntennaSignalComp = (props) => {
  var Spiderfy = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
  const [oms, setOms] = useState(null);
  const [polyLines, setPolyLines] = useState(null);
  mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
  const dispatch = useDispatch();
  const signalData = useSelector(selectSignalData);
  const lastBeep = useSelector(selectLastBeep);
  const antennaTag = useSelector(selectAntennaTag);

  const details = useSelector(selectAntennaDetails);

  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [modemInfo, setModemInfo] = useState();
  const [isTimedOutExt, setisTimedOutExt] = useState(false);
  const [beeper, setBeeper] = useState(false);
  const [signal, setSignal] = useState([
    { rsrp: null, sinr: null, rsrq: null },
  ]);
  const [bands2, setBands2] = useState([]);
  const [bands3, setBands3] = useState([]);
  const [bands4, setBands4] = useState([]);

  const [locationSwitch, setlocationSwitch] = useState("address");
  const [userLocation, setuserLocation] = useState([]);
  const modemTag = props.modemTag;

  const serverBaseURL = `https://gznendpointapi.azurewebsites.net/api/antennastream/${modemTag}`;
  let focusTimeStamp;
  const getAverage = (array) => {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum / array.length;
  };
  const onLoad = (map) => {
    let newOms;
    function initOMS() {
      newOms = new Spiderfy.OverlappingMarkerSpiderfier(map, {
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        nearbyDistance: 0,
        circleFootSeparation: 40,
        bubblingMouseEvents: false,
        disableDefaultUI: true,
      });
    }
    initOMS();
    setPolyLines(
      colorsArray(25).map(
        (color) =>
          new window.google.maps.Polyline({
            strokeColor: color,
          })
      )
    );
    setOms(newOms);
  };
  if (JSON.stringify(bands2) !== JSON.stringify(bands3)) {
    setBands3(bands2);
  }
  const successCallback = (position) => {
    if (
      JSON.stringify({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }) !== JSON.stringify(userLocation)
    ) {
      setuserLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
  };

  const errorCallback = (error) => {
    console.log(error);
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, [locationSwitch]);

  let isTimedOut = false;
  let requestLoop = () => {
    if (mounted.current && !isTimedOut) {
      dispatch(getSignal(modemTag));
      setTimeout(() => {
        requestLoop();
      }, 500);
    }
  };
  let keepAliveLoop = async () => {
    axios.post(
      "https://gznendpointapi.azurewebsites.net/api/enableHfMode",
      { [modemTag]: "start" },
      {}
    );
    setTimeout(() => {
      if (mounted.current && !isTimedOut) {
        setTimeout(() => {
          if (mounted.current && !isTimedOut) {
            setTimeout(() => {
              if (mounted.current && !isTimedOut) {
                setTimeout(() => {
                  if (mounted.current && !isTimedOut) {
                    setTimeout(() => {
                      if (mounted.current && !isTimedOut) {
                        setTimeout(() => {
                          if (mounted.current && !isTimedOut) {
                            setTimeout(() => {
                              if (mounted.current && !isTimedOut) {
                                setTimeout(() => {
                                  if (mounted.current && !isTimedOut) {
                                    setTimeout(() => {
                                      if (mounted.current && !isTimedOut) {
                                        setTimeout(() => {
                                          if (mounted.current && !isTimedOut) {
                                            setTimeout(() => {
                                              if (
                                                mounted.current &&
                                                !isTimedOut
                                              ) {
                                                setTimeout(() => {
                                                  if (
                                                    mounted.current &&
                                                    !isTimedOut
                                                  ) {
                                                    setTimeout(() => {
                                                      if (
                                                        mounted.current &&
                                                        !isTimedOut
                                                      ) {
                                                        setTimeout(() => {
                                                          if (
                                                            mounted.current &&
                                                            !isTimedOut
                                                          ) {
                                                            setTimeout(() => {
                                                              if (
                                                                mounted.current &&
                                                                !isTimedOut
                                                              ) {
                                                                setTimeout(
                                                                  () => {
                                                                    if (
                                                                      mounted.current &&
                                                                      !isTimedOut
                                                                    ) {
                                                                      setTimeout(
                                                                        () => {
                                                                          if (
                                                                            mounted.current &&
                                                                            !isTimedOut
                                                                          ) {
                                                                            setTimeout(
                                                                              () => {
                                                                                if (
                                                                                  mounted.current &&
                                                                                  !isTimedOut
                                                                                ) {
                                                                                  setTimeout(
                                                                                    () => {
                                                                                      if (
                                                                                        mounted.current &&
                                                                                        !isTimedOut
                                                                                      ) {
                                                                                        setTimeout(
                                                                                          () => {
                                                                                            if (
                                                                                              mounted.current &&
                                                                                              !isTimedOut
                                                                                            ) {
                                                                                              keepAliveLoop();
                                                                                            }
                                                                                          },
                                                                                          1000
                                                                                        );
                                                                                      }
                                                                                    },
                                                                                    1000
                                                                                  );
                                                                                }
                                                                              },
                                                                              1000
                                                                            );
                                                                          }
                                                                        },
                                                                        1000
                                                                      );
                                                                    }
                                                                  },
                                                                  1000
                                                                );
                                                              }
                                                            }, 1000);
                                                          }
                                                        }, 1000);
                                                      }
                                                    }, 1000);
                                                  }
                                                }, 1000);
                                              }
                                            }, 1000);
                                          }
                                        }, 1000);
                                      }
                                    }, 1000);
                                  }
                                }, 1000);
                              }
                            }, 1000);
                          }
                        }, 1000);
                      }
                    }, 1000);
                  }
                }, 1000);
              }
            }, 1000);
          }
        }, 1000);
      }
    }, 1000);
  };

  let timer = null;

  const handleMouseMove = () => {
    if (isTimedOut) {
      isTimedOut = false;
      setisTimedOutExt(false);
      requestLoop();
      keepAliveLoop();
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("pointerdown", handleMouseMove);
      window.removeEventListener("keypress", handleMouseMove);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("pointerdown", handleMouseMove);
      window.addEventListener("keypress", handleMouseMove);
    } else if (!isTimedOut) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        isTimedOut = true;
        setisTimedOutExt(true);

        document.close();
        dispatch(storeSignalData({}));
      }, 5 * 60 * 1000);
    }
  };
  useEffect(() => {
    if (isTimedOutExt) {
      setTimeout(() => {
        dispatch(storeSignalData({}));
      }, 500);
    }
  }, [signalData]);
  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("pointerdown", handleMouseMove);
    window.addEventListener("keypress", handleMouseMove);
    handleMouseMove();
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("pointerdown", handleMouseMove);
      window.removeEventListener("keypress", handleMouseMove);
      if (timer) clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    setBeeper((beeper) => !beeper);
  }, [lastBeep]);
  useEffect(() => {
    if (oms && bands4.length > 0) {
      oms.clearMarkers();
      let serviceUser;
      const setUsersMark = () => {
        if (details.currentUser || locationSwitch === "myLocation") {
          serviceUser = {
            name:
              locationSwitch === "address" ? details.currentUser.name : "You",
            position: {
              lat:
                locationSwitch === "address"
                  ? parseFloat(details.currentUser.latitude)
                  : userLocation.lat,
              lng:
                locationSwitch === "address"
                  ? parseFloat(details.currentUser.longitude)
                  : userLocation.lng,
            },
          };
        }
        if (serviceUser) {
          const newMarker = window.google
            ? new window.google.maps.Marker()
            : null;
          newMarker.setTitle(serviceUser.name);
          newMarker.setPosition(serviceUser.position);
          newMarker.setIcon(orgIco);
          oms.addMarker(newMarker);
          oms.map.setCenter(serviceUser.position);
          return newMarker;
        }
      };
      setUsersMark();
      bands4.map((point, index) => {
        const i = index + 1;
        if (polyLines[i]) {
          polyLines[i].setPath();
          polyLines[i].latLngs.clear();
        }
        if (window.google) {
          const pointPosition = {
            lat: parseFloat(point.latitude),
            lng: parseFloat(point.longitude),
          };
          const newMarker = new window.google.maps.Marker();
          newMarker.setPosition(pointPosition);
          newMarker.setTitle(`${point.enodeB}`);
          let lineSymbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 8,
          };
          if (point.active === "Yes") {
            newMarker.setIcon(antIcoActive);
            polyLines[i].setOptions({
              strokeOpacity: 1,
              strokeWeight: 8,
            });
          } else {
            newMarker.setIcon(antIco);
            polyLines[i].setOptions({
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "40px",
                },
              ],
            });
          }
          if (serviceUser) {
            polyLines[i].setPath([serviceUser.position, pointPosition]);
            polyLines[i].setMap(oms.map);
          }
          oms.addMarker(newMarker);
          return newMarker;
        } else {
          return null;
        }
      });
      let bounds = new window.google.maps.LatLngBounds();
      oms.getMarkers().map((marker) => {
        const markerPosition = {
          lat: marker.position.lat(),
          lng: marker.position.lng(),
        };
        if (markerPosition.lat && markerPosition.lng) {
          let infowindow = new window.google.maps.InfoWindow({
            content: `${marker.title}`,
          });
          window.google.maps.event.addListener(marker, "click", (e) => {
            infowindow.open(oms.map, marker);
          });
          bounds.extend(markerPosition);
        }
        return null;
      });
      oms.map.setOptions({
        scaleControl: false,
        streetViewControl: false,
        fullscreenControl: true,
        rotateControl: true,
        mapTypeId: "satellite",
      });
      oms.map.fitBounds(bounds);
      // oms.map.setZoom(oms.map.getZoom() - 1);

      if (!serviceUser) {
        let allLats = oms.getMarkers().map((marker) => marker.position.lat());
        let allLngs = oms.getMarkers().map((marker) => marker.position.lng());
        let midpoint = { lat: getAverage(allLats), lng: getAverage(allLngs) };
        oms.getMarkers().map((marker, index) => {
          let i = index + 1;
          const markerPosition = {
            lat: marker.position.lat(),
            lng: marker.position.lng(),
          };
          polyLines[i].setPath([midpoint, markerPosition]);
          polyLines[i].setMap(oms.map);
          return null;
        });
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bands4, oms, locationSwitch, userLocation]);
  useEffect(() => {
    if (oms) {
      let marker = oms.getMarkers().find((m) => m.title === "You");
      if (marker) {
        marker.setPosition({
          lat: userLocation.lat,
          lng: userLocation.lng,
        });
      }
    }
  }, [oms, userLocation]);
  useEffect(() => {
    if (signalData) {
      setModemInfo({
        icc: signalData.icc,
        externalIp: signalData.externalIp,
        mno: signalData.mno,
        simMno: signalData.simMno,
        apn: signalData.apn,
      });
      setBands2((bands2) => {
        let index = bands2
          .map((object) => object.enodeB)
          .indexOf(signalData.enodeb);
        let newArr = [...bands2];
        let line = {
          enodeB: signalData.enodeb,
          pcc: signalData.pcids ? signalData.pcids[0] : null,
          rsrp: bands2[index]
            ? bands2[index].rsrp > signalData.rsrp
              ? bands2[index].rsrp
              : signalData.rsrp
            : signalData.rsrp,
          scc1: signalData.pcids ? signalData.pcids[1] : null,
          scc2: signalData.pcids ? signalData.pcids[2] : null,
          scc3: signalData.pcids ? signalData.pcids[3] : null,
          scc4: signalData.pcids ? signalData.pcids[4] : null,
          active: "Yes",
          latitude: signalData.lat,
          longitude: signalData.lon,
        };
        if (index === -1 && line.pcc !== "/") {
          return [
            ...newArr.map((band) => {
              return { ...band };
            }),
            line,
          ];
        } else if (line.pcc !== "/") {
          let newArr2 = newArr.map((band) => {
            return { ...band, active: "No" };
          });
          newArr2[index] = line;
          return newArr2;
        } else {
          return newArr;
        }
      });
      setSignal([
        {
          rsrp: signalData.rsrp,
          sinr: signalData.sinr + 5,
          rsrq: signalData.rsrq + 25,
        },
      ]);
    }
  }, [signalData]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_mapApi,
  });
  useEffect(() => {
    let connectionAttemp = 1;

    setConnectionStatus(`Connecting... (${connectionAttemp})`);
    keepAliveLoop();
    requestLoop();
  }, [serverBaseURL, props.buttonState, focusTimeStamp]);
  const rg = (r, g) => `rgb(${r},${g},0)`;
  const qualityBars = (signalProps) => {
    const sinrBarFill = () => {
      if (signalProps) {
        return signalProps.map((entry, index) => {
          return (
            <Cell
              fill={
                signalProps[index].sinr - 5 <= 0
                  ? rg(250, 0)
                  : signalProps[index].sinr - 5 <= 2
                  ? rg(250, 65)
                  : signalProps[index].sinr - 5 <= 4
                  ? rg(250, 100)
                  : signalProps[index].sinr - 5 <= 6
                  ? rg(250, 150)
                  : signalProps[index].sinr - 5 <= 8
                  ? rg(250, 200)
                  : signalProps[index].sinr - 5 <= 10
                  ? rg(250, 225)
                  : signalProps[index].sinr - 5 <= 12
                  ? rg(250, 250)
                  : signalProps[index].sinr - 5 <= 14
                  ? rg(225, 250)
                  : signalProps[index].sinr - 5 <= 16
                  ? rg(200, 250)
                  : signalProps[index].sinr - 5 <= 18
                  ? rg(150, 250)
                  : signalProps[index].sinr - 5 <= 20
                  ? rg(100, 250)
                  : rg(0, 250)
              }
            />
          );
        });
      } else {
        return <Cell fill={rg(250, 0)} />;
      }
    };
    const sinrBarLabel = () => {
      if (signalProps) {
        return `SINR: ${signalProps[0].sinr - 5} dB`;
      } else {
        return `SINR: -`;
      }
    };
    const rsrqBarFill = () => {
      if (signalProps) {
        return signalProps.map((entry, index) => {
          return (
            <Cell
              fill={
                signalProps[index].rsrq - 25 <= -20
                  ? rg(250, 0)
                  : signalProps[index].rsrq - 25 === -19
                  ? rg(250, 65)
                  : signalProps[index].rsrq - 25 === -18
                  ? rg(250, 100)
                  : signalProps[index].rsrq - 25 === -17
                  ? rg(250, 150)
                  : signalProps[index].rsrq - 25 === -16
                  ? rg(250, 200)
                  : signalProps[index].rsrq - 25 === -15
                  ? rg(250, 225)
                  : signalProps[index].rsrq - 25 === -14
                  ? rg(250, 250)
                  : signalProps[index].rsrq - 25 === -13
                  ? rg(225, 250)
                  : signalProps[index].rsrq - 25 === -12
                  ? rg(200, 250)
                  : signalProps[index].rsrq - 25 === -11
                  ? rg(150, 250)
                  : signalProps[index].rsrq - 25 === -10
                  ? rg(100, 250)
                  : rg(0, 250)
              }
            />
          );
        });
      } else {
        return <Cell fill={rg(250, 0)} />;
      }
    };
    const rsrqBarLabel = () => {
      if (signalProps) {
        return `RSRQ: ${signalProps[0].rsrq - 25} dB`;
      } else {
        return `RSRQ: -`;
      }
    };
    const barRender = (xAxisDomain, dataKey, barFill, barLabel) => {
      return (
        <ResponsiveContainer height={25} width="100%">
          <BarChart
            layout="vertical"
            height={30}
            data={signalProps}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis
              type="number"
              domain={xAxisDomain}
              tick={false}
              hide={true}
            />
            <YAxis dataKey="name" type="category" scale="band" hide={true} />
            <Bar dataKey={dataKey} barSize={25} background={{ fill: "#eee" }}>
              {barFill()}
            </Bar>
            <text
              x="50%"
              y="65%"
              style={{ fontWeight: "bold", fill: "black" }}
              width={200}
              textAnchor="middle"
            >
              {barLabel()}
            </text>
          </BarChart>
        </ResponsiveContainer>
      );
    };
    return (
      <div className="barsContainer">
        {barRender([0, 30], "sinr", sinrBarFill, sinrBarLabel)}
        {barRender([0, 20], "rsrq", rsrqBarFill, rsrqBarLabel)}
      </div>
    );
  };
  useEffect(() => {
    if (
      JSON.stringify(bands4) !==
      JSON.stringify(
        bands3.map((band) => {
          return {
            active: band.active,
            latitude: band.latitude,
            longitude: band.longitude,
            enodeB: band.enodeB,
          };
        })
      )
    ) {
      setBands4(
        bands3.map((band) => {
          return {
            active: band.active,
            latitude: band.latitude,
            longitude: band.longitude,
            enodeB: band.enodeB,
          };
        })
      );
    }
  }, [bands3]);
  return (
    <div ref={ascRef} className={"antennaSigCont"}>
      <div className="infoLine">
        <div className="infoLineKey">APN: </div>
        <div className="infoLineValue">{modemInfo ? modemInfo.apn : "-"}</div>
      </div>
      <div className="infoLine">
        <div className="infoLineKey">ICC: </div>
        <div className="infoLineValue">{modemInfo ? modemInfo.icc : "-"}</div>
      </div>
      <div className="infoLine">
        <div className="infoLineKey">External IP: </div>
        <div className="infoLineValue">
          {modemInfo ? modemInfo.externalIp : "-"}
        </div>
      </div>
      <div className="infoLine">
        <div className="infoLineKey">MNO: </div>
        <div className="infoLineValue">{modemInfo ? modemInfo.mno : "-"}</div>
      </div>
      <div className="infoLine">
        <div className="infoLineKey">Sim MNO: </div>
        <div className="infoLineValue">
          {modemInfo ? modemInfo.simMno : "-"}
        </div>
      </div>
      <AntennaSignalSpeedTest tag={modemTag} />
      {bands3 ? (
        bands3.length > 0 ? (
          <div className="tableContainer">
            <NewTable tit="antennaBandsTable" data={bands3} />
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <div className="gaugeContainer">
        {signalData ? (
          signalData.rsrp && signal[0].rsrp && !isTimedOutExt ? (
            <SignalGauge gaugePerc={(signal[0].rsrp + 120) / 60} />
          ) : (
            <SignalGauge gaugePerc={0} />
          )
        ) : (
          <SignalGauge gaugePerc={0} />
        )}
      </div>
      <div className={"beeper"}>
        <img
          className={beeper ? "blueFilter" : "redFilter"}
          src={signalIco}
          width={"50px"}
          alt=""
        />
      </div>
      {signalData
        ? signalData.rsrp && signalData.rsrq && !isTimedOutExt
          ? qualityBars(signal)
          : qualityBars(null)
        : qualityBars(null)}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "80%",
          marginBottom: "15px",
        }}
      >
        <Button
          variant={locationSwitch === "address" ? "contained" : "outlined"}
          sx={{
            fontFamily: "Quenda, serif !important",
            background: locationSwitch === "address" ? "#4ab217" : "white",
            color: locationSwitch === "address" ? "white" : "#4ab217",
            borderColor: "#4ab217",
            width: "125px",
            marginRight: "5px",
            ":hover": {
              bgcolor: locationSwitch === "address" ? "#4ab217" : "white",
              color: locationSwitch === "address" ? "white" : "#4ab217",
              borderColor: "#4ab217",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
            ":disabled": {
              color: locationSwitch === "address" ? "white" : "light grey",
            },
          }}
          onClick={(e) => {
            setlocationSwitch("address");
          }}
        >
          User address
        </Button>
        <Button
          variant={locationSwitch === "myLocation" ? "contained" : "outlined"}
          sx={{
            fontFamily: "Quenda, serif !important",
            background: locationSwitch === "myLocation" ? "#4ab217" : "white",
            color: locationSwitch === "myLocation" ? "white" : "#4ab217",
            borderColor: "#4ab217",
            width: "125px",
            marginRight: "5px",
            ":hover": {
              bgcolor: locationSwitch === "myLocation" ? "#4ab217" : "white",
              color: locationSwitch === "myLocation" ? "white" : "#4ab217",
              borderColor: "#4ab217",
            },
            ":disabled": {
              color: locationSwitch === "myLocation" ? "white" : "light grey",
            },
          }}
          onClick={(e) => {
            setlocationSwitch("myLocation");
          }}
        >
          My location
        </Button>
      </div>
      <>
        {bands2.length > 0 && connectionStatus && isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            zoom={15}
          ></GoogleMap>
        ) : (
          ""
        )}
      </>
    </div>
  );
};
export default AntennaSignalComp;

import "../../css/hardwareDetails.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "../../geuzenetlog.png";
import NewTable from "../../components/newTable/newTable";
import { useNavigate } from "react-router-dom";
import {
  AssetsSelect,
  AssetsTextInput,
  AssetsDatePicker,
  AssetsDateTimePicker,
} from "../../components/assetsInputs";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import {
  getHardwareProps,
  getHardwareDetails,
  updateHardwareDetails,
  storeAssetDetails,
} from "../../store/actions/assets";
import {
  selectHardwareProps,
  selectAssetDetails,
} from "../../store/selectors/assets";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
const checkObjectLength = (obj) => Object.keys(obj).length;

function HardwareDetails() {
  const appUser = useSelector(selectAppUser);
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  const headerRef = useRef();
  const navigation = useSelector(selectNavigation);
  const details = useSelector(selectAssetDetails);
  const hwProps = useSelector(selectHardwareProps);
  const isBigScreen2 = useMediaQuery({ query: "(min-width: 983px)" });
  const { tag } = useParams();
  const dispatch = useDispatch();

  const getModel = (id) => {
    if (hwProps.model && id) {
      return hwProps.model.find((model) => parseInt(model.id) === parseInt(id));
    } else {
      return { model: "-" };
    }
  };
  const getStatus = (id) => {
    if (hwProps.status && id) {
      return hwProps.status.find(
        (status) => parseInt(status.id) === parseInt(id)
      );
    } else {
      return { model: "-" };
    }
  };
  const getModelsOfType = (type) =>
    hwProps.model.filter((model) => parseInt(model.type_id) === parseInt(type));

  const setModel = (model, type) => {
    let modelProps = hwProps.model.find(
      (hwmodel) => parseInt(hwmodel.id) === parseInt(model)
    );
    if (modelProps) {
      setItem({
        ...item,
        type: type,
        model: model,
        brand: getModel(model).brand,
        fdd: getModel(model).fdd_band,
        tdd: getModel(model).tdd_band,
        nr: getModel(model).nr_band,
        lte: getModel(model).lte_category,
        wifi: getModel(model).wifi,
        gps: getModel(model).gps,
        poe: getModel(model).poe,
        dc: getModel(model).dc,
      });
    } else {
      setItem({
        ...item,
        type: type,
        model: model,
        brand: "?",
        fdd: "?",
        tdd: "?",
        nr: "?",
        lte: "?",
        wifi: "?",
        gps: "?",
        poe: "?",
        dc: "?",
      });
    }
  };

  const getType = (id) => {
    if (hwProps.model && id) {
      const model = hwProps.model.find(
        (model) => parseInt(model.id) === parseInt(id)
      );
      return hwProps.type.find(
        (type) => parseInt(type.id) === parseInt(model.type_id)
      );
    } else {
      return {};
    }
  };
  const [item, setItem] = useState({});
  const [filter, setFilter] = useState("");
  const [detailsEdit, setDetailsEdit] = useState(false);
  const cellLink = (type, value) => {
    if (type === "sim") {
      navigate(`/simcardsDetails/${value}`);
    }
  };
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Assets", `Device ${tag} details`, "details"));
      }
    };
    setMenu(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    setDetailsEdit(false);
    const getItems = () => {
      if (
        (checkObjectLength(hwProps) === 0 ||
          checkObjectLength(details) === 0) &&
        validUser(appUser)
      ) {
        dispatch(getHardwareProps());
        dispatch(getHardwareDetails(tag));
      }
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain]);

  const customTimeFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear(),
      t = date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
    return `${y}-${m}-${d} ${t}`;
  };

  const resetItem = () => {
    setItem({
      ...item,
      tag: details.tag_id,
      type: getType(item.model).id,
      model: details.model,
      brand: getModel(details.model).brand,
      supplier: details.supplier,
      invoice: details.invoiceNumber,
      invoiceDate: details.invoiceDate,
      serialNumber: details.serialNumber,
      simcard_id: details.simcard_id,
      mac: details.mac,
      imei: details.imei,
      status: details.status,
      stock_id: details.stock_id,
      service_id: details.service_id,
      fdd: getModel(details.model).fdd_band,
      tdd: getModel(details.model).tdd_band,
      nr: getModel(details.model).nr_band,
      lte: getModel(details.model).lte_category,
      wifi: getModel(details.model).wifi,
      gps: getModel(details.model).gps,
      poe: getModel(details.model).poe,
      dc: getModel(details.model).dc,
      update: new Date(Date.now()).toISOString(),
      updateLocal: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ),
      updateUTC: new Date(Date.now()),
    });
  };
  if (
    checkObjectLength(hwProps) > 0 &&
    checkObjectLength(details) > 0 &&
    !item.type
  ) {
    resetItem();
  }
  const sortByName = (a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const sortById = (a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };
  const dataLine = (desc, value, onClick) => {
    return (
      <div className="dasLine">
        <div className="dasDesc">{desc}</div>
        <div className="dasValue" onClick={onClick}>
          {value}
        </div>
      </div>
    );
  };
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return checkObjectLength(hwProps) > 0 && checkObjectLength(item) > 0 ? (
      <div className="HardwareDetailsWrapper">
        <div className="harDetails">
          <div className="HardwareDetails">
            <div className="topBar">
              {item.saved ? (
                <div className="savedNoteAAD">
                  State and/or Location updated!!
                </div>
              ) : (
                <div className="savedNoteAAD" style={{ color: "white" }}>
                  ||
                </div>
              )}
              {detailsEdit ? (
                <div className="mainButtonsAAD">
                  {!Object.values(item).includes("- Select -") ? (
                    <button
                      className="save"
                      onClick={(e) => {
                        dispatch(storeAssetDetails({}));
                        setItem({});
                        setDetailsEdit(false);
                        setItem({});
                        dispatch(updateHardwareDetails(tag, item));
                        setItem({});
                      }}
                    >
                      Save
                    </button>
                  ) : (
                    <button className="disabledANS">Save</button>
                  )}
                  <button
                    className="cancel"
                    onClick={(e) => {
                      dispatch(storeAssetDetails({}));
                      setItem({});
                      setDetailsEdit(false);
                      setItem({});
                      dispatch(getHardwareDetails(tag));
                      resetItem();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="mainButtonsAAD">
                  <button
                    className="edit"
                    onClick={(e) => {
                      setDetailsEdit(true);
                      resetItem();
                    }}
                  >
                    Edit
                  </button>
                  <button className="disabled" style={{ cursor: "default" }}>
                    {" "}
                  </button>
                </div>
              )}
            </div>
            <div
              className={
                isBigScreen2 ? "globalSelection wide" : "globalSelection"
              }
            >
              <div className="globalSelectionGroup">
                {dataLine("Tag:", item.tag)}
                {dataLine("Brand:", item.brand)}
                {dataLine("FDD bands:", item.fdd)}
                {dataLine("TDD bands:", item.tdd)}
                {dataLine("NR bands:", item.nr)}
                {dataLine("LTE category:", item.lte)}
                {dataLine(
                  "WiFi:",
                  item.wifi === "?" ? "?" : item.wifi ? "Yes" : "No"
                )}
                {dataLine(
                  "GPS:",
                  item.gps === "?" ? "?" : item.gps ? "Yes" : "No"
                )}
                {dataLine("PoE:", item.poe)}
                {dataLine("DC:", item.dc)}
                {dataLine(
                  "Simcard :",
                  item.simcard_id !== "No sim" ? item.simcard_id : "-",
                  (e) => {
                    cellLink("sim", item.simcard_id);
                  }
                )}
                {dataLine()}
              </div>
              <div className="globalSelectionSpacer"></div>

              <div className="globalSelectionGroup">
                {detailsEdit ? (
                  <AssetsSelect
                    desc="Type:"
                    value={item.type}
                    options={hwProps.type.map((type) => {
                      return { key: type.id, value: type.type };
                    })}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        type: value,
                      });
                      setModel(
                        getModelsOfType(value).includes(item.model)
                          ? item.model
                          : "- Select -",
                        value
                      );
                    }}
                  />
                ) : (
                  dataLine("Type:", getType(item.model).type)
                )}
                {detailsEdit ? (
                  <AssetsSelect
                    desc="Model:"
                    value={item.model}
                    options={getModelsOfType(item.type).map((model) => {
                      return { key: model.id, value: model.model };
                    })}
                    onChangeFunc={(value) => {
                      setModel(value, item.type);
                    }}
                    disabled={item.type === "- Select -"}
                  />
                ) : (
                  dataLine("Model:", getModel(item.model).model)
                )}
                {detailsEdit ? (
                  <AssetsSelect
                    desc="Supplier:"
                    value={item.supplier}
                    options={hwProps.supplier
                      .sort(sortByName)
                      .map((supplier) => {
                        return { key: supplier.id, value: supplier.name };
                      })}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        supplier: value,
                      });
                    }}
                  />
                ) : (
                  dataLine(
                    "Supplier:",
                    hwProps.supplier && item.supplier
                      ? hwProps.supplier.find(
                          (supplier) => supplier.id === item.supplier
                        ).name
                      : ""
                  )
                )}
                {detailsEdit ? (
                  <AssetsTextInput
                    desc="Invoice #:"
                    value={item.invoice}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        invoice: value,
                      });
                    }}
                  />
                ) : (
                  dataLine("Invoice #:", item.invoice)
                )}
                {detailsEdit ? (
                  <AssetsDatePicker
                    desc="Invoice date:"
                    value={item.invoiceDate}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        invoiceDate: value,
                      });
                    }}
                  />
                ) : (
                  dataLine(
                    "Invoice date:",
                    item.invoiceDate
                      ? new Date(item.invoiceDate).getFullYear() +
                          "-" +
                          (
                            "0" +
                            (new Date(item.invoiceDate).getMonth() + 1)
                          ).slice(-2) +
                          "-" +
                          ("0" + new Date(item.invoiceDate).getDate()).slice(-2)
                      : null
                  )
                )}
                {detailsEdit ? (
                  <AssetsTextInput
                    desc="S/N #:"
                    value={item.serialNumber}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        serialNumber: value,
                      });
                    }}
                  />
                ) : (
                  dataLine("S/N #:", item.serialNumber)
                )}
                {detailsEdit ? (
                  <AssetsTextInput
                    desc="MAC:"
                    value={item.mac}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        mac: value,
                      });
                    }}
                  />
                ) : (
                  dataLine("MAC:", item.mac)
                )}
                {detailsEdit ? (
                  <AssetsTextInput
                    desc="IMEI:"
                    value={item.imei}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        imei: value,
                      });
                    }}
                  />
                ) : (
                  dataLine("IMEI:", item.imei)
                )}
                {detailsEdit ? (
                  <AssetsSelect
                    desc="Status:"
                    value={item.status}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        status: value,
                        service_id: value !== "2" ? null : "- Select -",
                        stock_id: value === "2" ? null : "- Select -",
                      });
                      setFilter("");
                    }}
                    options={hwProps.status.map((status) => {
                      return { key: status.id, value: status.status };
                    })}
                  />
                ) : (
                  dataLine("Status:", getStatus(item.status).status)
                )}{" "}
                {detailsEdit ? (
                  <AssetsTextInput
                    desc="Location filter:"
                    value={filter}
                    onChangeFunc={(value) => {
                      setFilter(value);
                    }}
                  />
                ) : (
                  dataLine(
                    "Location:",
                    item.status === "2"
                      ? `${item.service_id}: ${
                          hwProps.service
                            ? hwProps.service.find(
                                (service) => service.id === item.service_id
                              ).description
                            : ""
                        }`
                      : hwProps.stock && item.stock_id
                      ? hwProps.stock.find(
                          (stock) => stock.id === item.stock_id
                        ).name
                      : "-"
                  )
                )}
                {detailsEdit ? (
                  <AssetsSelect
                    desc="Location:"
                    value={
                      item.status === "2" ? item.service_id : item.stock_id
                    }
                    options={
                      hwProps.service && item.status === "2"
                        ? hwProps.service
                            .filter(
                              (service) =>
                                (service.description
                                  ? service.description
                                  : "  "
                                )
                                  .toLowerCase()
                                  .includes(
                                    (filter ? filter : "").toLowerCase()
                                  ) ||
                                service.id.includes(
                                  (filter ? filter : "").toLowerCase()
                                )
                            )
                            .sort(sortById)
                            .map((service) => {
                              return {
                                key: service.id,
                                value: `${service.id}: ${service.description}`,
                              };
                            })
                        : hwProps.stock
                        ? hwProps.stock
                            .filter((stock) =>
                              stock.name
                                .toLowerCase()
                                .includes((filter ? filter : "").toLowerCase())
                            )
                            .map((stock) => {
                              return { key: stock.id, value: stock.name };
                            })
                        : []
                    }
                    disabled={item.status === "- Select -"}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        service_id: item.status === "2" ? value : null,
                        stock_id: item.status !== "2" ? value : null,
                      });
                    }}
                  />
                ) : (
                  dataLine()
                )}
                {detailsEdit ? (
                  <AssetsDateTimePicker
                    desc="Time:"
                    value={item.update}
                    onChangeFunc={(value) => {
                      console.log(value);
                      setItem({
                        ...item,
                        update: value.toISOString(),
                      });
                    }}
                  />
                ) : (
                  dataLine()
                )}
              </div>
            </div>

            {checkObjectLength(hwProps) > 0 &&
            checkObjectLength(item) > 0 &&
            details.fullHistory ? (
              <>
                <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>
                  Asset history
                </h2>
                <div className="smallTableWrapper" ref={headerRef}>
                  <NewTable
                    tit={"deviceHistory"}
                    tabletype="small"
                    className="histTable"
                    data={details.fullHistory.map((line) => {
                      return {
                        ...line,
                        timestamp: customTimeFormat(
                          new Date(line.timestamp),
                          undefined
                        ),
                      };
                    })}
                    width={
                      headerRef.current ? headerRef.current.clientWidth : 100
                    }
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    ) : (
      <img className="landingLogo ll" src={logo} alt="company logo" />
    );
  }
}
export default HardwareDetails;

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import logo from "../../geuzenetlog.png";

import { selectInvoicingSteps } from "../../store/selectors/navigation";
import { selectNewInvoices } from "../../store/selectors/invoicing";
import { getAppUser, setInvoicingSteps } from "../../store/actions/navigation";
import { MemoPanelComponent } from "../../components/invPreselPanel";

function NewInvoicesPreselect() {
  const invoicingSteps = useSelector(selectInvoicingSteps);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppUser());
  }, [dispatch]);

  const newInvoices = useSelector(selectNewInvoices);
  // useEffect(() => {
  //   console.log("tu", invoicingSteps);
  //   dispatch(
  //     setInvoicingSteps({
  //       ...invoicingSteps,
  //       generatingInvoices: false,
  //       allInvoicesIds: newInvoices.map((invoice) => invoice.id),
  //     })
  //   );
  // }, [newInvoices]);
  console.log(invoicingSteps.generatingInvoices);
  return (
    <div className="invoicingBody">
      <div>
        {!invoicingSteps.generatingInvoices ? (
          newInvoices.length > 0 ? (
            <div>
              {newInvoices
                .filter(
                  (invoice) =>
                    `${invoice.customer}`
                      .toLocaleLowerCase()
                      .includes(
                        invoicingSteps.preSelSearch.toLocaleLowerCase()
                      ) ||
                    `${invoice.twinfield_id}`
                      .toLocaleLowerCase()
                      .includes(
                        invoicingSteps.preSelSearch.toLocaleLowerCase()
                      ) ||
                    `${invoice.service_id} - ${invoice.user}`
                      .toLocaleLowerCase()
                      .includes(
                        invoicingSteps.preSelSearch.toLocaleLowerCase()
                      ) ||
                    `${
                      invoice.description.split("\n").reverse()[0].split(" ")[2]
                    } - ${
                      invoice.description.split("\n").reverse()[0].split(" ")[4]
                    }`
                      .toLocaleLowerCase()
                      .includes(
                        invoicingSteps.preSelSearch.toLocaleLowerCase()
                      ) ||
                    `${invoice.contract_id}`
                      .toLocaleLowerCase()
                      .includes(invoicingSteps.preSelSearch.toLocaleLowerCase())
                )
                .sort((a, b) => a.contract_id - b.contract_id)
                .map((invoice) => (
                  <MemoPanelComponent invoicePreSel={invoice} />
                ))}
            </div>
          ) : (
            <div>
              <MemoPanelComponent />
            </div>
          )
        ) : (
          <img className="landingLogo ll" src={logo} alt="company logo" />
        )}
      </div>
    </div>
  );
}

export default NewInvoicesPreselect;

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "inventoryCheck/storeData":
      return action.payload;
    case "inventoryCheck/setToNull":
      return initialState;
    default:
      return state;
  }
}

import axios from "axios";
import { dateTimeFormat, dateFormat } from "../../components/dateConv";
const key = process.env.REACT_APP_API_KEY;
const endpoint = `orders?code=${key}`;
const url = `/api/${endpoint}`;

const storeDeliveries = (deliveries) => {
  return {
    type: "orders/storeDeliveries",
    payload: deliveries,
  };
};

export const getDeliveries = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=getDeliveries`);
    console.log(response);
    response.data.forEach((delivery) => {
      if (delivery.implementation_date_t) {
        delivery.implementation_date_t = dateFormat(
          delivery.implementation_date_t
        );
      }
    });
    dispatch(storeDeliveries(response.data));
  };
};

const storeLastOrderCheck = (logData) => {
  return {
    type: "orders/storelastOrderCheck",
    payload: logData.data,
  };
};

export const getLastOrderCheck = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=lastOrderCheck`);
    dispatch(storeLastOrderCheck(response));
  };
};

const storeDirtyOrders = (dirtyOrders) => {
  return {
    type: "orders/storeDirtyOrders",
    payload: dirtyOrders,
  };
};

export const getDirtyOrders = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=dirtyOrders`);
    response.data.forEach((dirtyOrder) => {
      if (dirtyOrder.reported) {
        dirtyOrder.reported = dateTimeFormat(dirtyOrder.reported);
      }
    });
    dispatch(storeDirtyOrders(response.data));
  };
};

// +++

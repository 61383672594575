export const selectPoolsPM = (state) => state.admin.pools.poolsPM;
export const selectPoolPD = (state) => state.admin.pools.poolPD;
export const selectPoolDetails = (state) => state.admin.pools.poolDetails;
export const selectPoolTopUsers = (state) => state.admin.pools.poolTopUsers;
export const selectAntennaDetails = (state) =>
  state.admin.signal.antennaDetails;
export const selectAllModems = (state) => state.admin.signal.allModems;
export const selectOnlineModems = (state) => state.admin.signal.onlineModems;
export const selectUnsupported = (state) => state.admin.signal.unsupported;
export const selectDownload = (state) => state.admin.signal.download;
export const selectUpload = (state) => state.admin.signal.upload;
export const selectLastBeep = (state) => state.admin.signal.lastBeep;
export const selectSignalData = (state) => state.admin.signalData;

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getAllTables,
  getTheTable,
  cleanTheTable,
  updateTheTable,
  storeTheTable,
} from "../../store/actions/psqlEdit";
import {
  selectAllTables,
  selectError,
  selectTheTable,
  selectTheTableMeta,
} from "../../store/selectors/psqlEdit";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

const validUser = (user) => (user ? user.userRoles.includes("admin") : false);
function AdminSubAdmin2() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [tableSelector, setTableSelector] = useState("-select-");
  const allTables = useSelector(selectAllTables);
  const error = useSelector(selectError);
  const theTable = useSelector(selectTheTable);
  const [psqlTable, setPsqlTable] = useState([]);
  const theTableMeta = useSelector(selectTheTableMeta);
  const appUser = useSelector(selectAppUser);
  const navigation = useSelector(selectNavigation);

  const dispatch = useDispatch();
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Admin", "PSQL edit", "details"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);

  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (allTables.length === 0 && validUser(appUser)) {
        dispatch(getAllTables());
      }
      if (tableSelector !== "-select-" && theTable.length === 0) {
        dispatch(getTheTable(tableSelector));
      }
      if (theTable.length > 0) {
        setPsqlTable(theTable);
      }
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain, theTable]);

  const [edit, setEdit] = useState(false);
  const updateFieldChanged = (index, cell) => (e) => {
    if (e.target.value === "-select-") {
      let newArr = [...psqlTable];
      newArr[index][cell] = null;
      setPsqlTable([...newArr]);
    } else if (
      theTableMeta.find((cellEdit) => cellEdit.column === cell).values !==
      "bool"
    ) {
      let newArr = [...psqlTable];
      newArr[index][cell] = e.target.value;
      setPsqlTable([...newArr]);
    } else {
      let newArr = [...psqlTable];
      if (newArr[index][cell]) {
        newArr[index][cell] = false;
      } else {
        newArr[index][cell] = true;
      }
      setPsqlTable([...newArr]);
    }
  };
  const inputWidthCalc = (cell) => {
    if (cell)
      return {
        width: cell.length + 2 + "ch",
      };
    else
      return {
        width: 2 + "ch",
      };
  };
  return allTables.length > 0 ? (
    <>
      {error ? error : ""}
      <select
        className="psqlEditSelector"
        value={tableSelector}
        onChange={(e) => {
          setPsqlTable([]);
          dispatch(cleanTheTable());
          setEdit(false);
          setTableSelector(e.target.value);
        }}
      >
        <option value={"-select-"}>-select-</option>
        {allTables.map((table) => (
          <option value={table}>{table}</option>
        ))}
      </select>
      {psqlTable.length > 0 ? (
        edit ? (
          <>
            <div className="mainButtonsAAD">
              <button
                className="save"
                onClick={(e) => {
                  dispatch(updateTheTable(tableSelector, theTable));
                  if (error) {
                    if (error.slice(0, 5) !== "error") {
                      setEdit(false);
                    }
                  }
                }}
              >
                Save
              </button>
              <button
                className="cancel"
                onClick={(e) => {
                  setPsqlTable([]);
                  dispatch(cleanTheTable());
                  setEdit(false);
                }}
              >
                Cancel
              </button>
            </div>
            <div className="mainButtonsAAD">
              <button
                className="edit"
                onClick={(e) => {
                  dispatch(
                    storeTheTable([
                      ...psqlTable,
                      Object.fromEntries(
                        Object.keys(psqlTable[0]).map((key) => {
                          return [key, null];
                        })
                      ),
                    ])
                  );
                }}
              >
                Add
              </button>
            </div>
          </>
        ) : (
          <div className="mainButtonsAAD">
            <button
              className="edit"
              onClick={(e) => {
                setEdit(true);
              }}
            >
              Edit
            </button>
            <button className="disabled" style={{ cursor: "default" }}>
              {" "}
            </button>
          </div>
        )
      ) : (
        ""
      )}
      {psqlTable.length !== 0 ? (
        <>
          <table className="simpleTable">
            <thead>
              <tr>
                {Object.keys(psqlTable[0]).map((header) => (
                  <th>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {edit
                ? psqlTable.map((row) => (
                    <tr>
                      {Object.keys(row).map((cell) => {
                        return theTableMeta.find(
                          (cellEdit) => cellEdit.column === cell
                        ).edit ? (
                          <td>
                            {theTableMeta.find(
                              (cellEdit) => cellEdit.column === cell
                            ).values === "str" ? (
                              <input
                                value={row[cell]}
                                style={inputWidthCalc(row[cell])}
                                onChange={updateFieldChanged(
                                  psqlTable.indexOf(row),
                                  cell
                                )}
                              ></input>
                            ) : theTableMeta.find(
                                (cellEdit) => cellEdit.column === cell
                              ).values === "int" ? (
                              <input
                                type={"number"}
                                value={row[cell]}
                                style={inputWidthCalc(row[cell])}
                                onChange={updateFieldChanged(
                                  psqlTable.indexOf(row),
                                  cell
                                )}
                              ></input>
                            ) : theTableMeta.find(
                                (cellEdit) => cellEdit.column === cell
                              ).values === "bool" ? (
                              <input
                                type={"checkbox"}
                                checked={row[cell]}
                                onChange={updateFieldChanged(
                                  psqlTable.indexOf(row),
                                  cell
                                )}
                              ></input>
                            ) : theTableMeta.find(
                                (cellEdit) => cellEdit.column === cell
                              ).ref ? (
                              <select
                                value={row[cell] ? row[cell] : "-select-"}
                                style={
                                  row[cell] && row[cell] !== "-select-"
                                    ? inputWidthCalc(
                                        Object.values(
                                          theTableMeta
                                            .find(
                                              (cellEdit) =>
                                                cellEdit.column === cell
                                            )
                                            .values.find(
                                              (value) =>
                                                parseInt(
                                                  value[
                                                    theTableMeta
                                                      .find(
                                                        (cellEdit) =>
                                                          cellEdit.column ===
                                                          cell
                                                      )
                                                      .ref.split("/")[1]
                                                  ]
                                                ) === parseInt(row[cell])
                                            )
                                        ).join("-") + "xxx"
                                      )
                                    : inputWidthCalc("-select-")
                                }
                                onChange={updateFieldChanged(
                                  psqlTable.indexOf(row),
                                  cell
                                )}
                              >
                                {theTableMeta
                                  .find((cellEdit) => cellEdit.column === cell)
                                  .values.map((selVal) => (
                                    <option value={Object.values(selVal)[0]}>
                                      {Object.values(selVal).join("-")}
                                    </option>
                                  ))}
                                <option value={"-select-"}>{"-select-"}</option>
                              </select>
                            ) : (
                              row[cell]
                            )}
                          </td>
                        ) : theTableMeta.find(
                            (cellEdit) => cellEdit.column === cell
                          ).values !== "bool" ? (
                          <td>{row[cell]}</td>
                        ) : (
                          <td>
                            <input
                              type={"checkbox"}
                              checked={row[cell]}
                              disabled={true}
                            ></input>
                          </td>
                        );
                      })}
                    </tr>
                  ))
                : psqlTable.map((row) => {
                    return (
                      <tr>
                        {Object.keys(row).map((cell) =>
                          theTableMeta.find(
                            (cellEdit) => cellEdit.column === cell
                          ).values === "bool" ? (
                            <td>
                              <input
                                type={"checkbox"}
                                checked={row[cell]}
                                disabled={true}
                              ></input>
                            </td>
                          ) : theTableMeta.find(
                              (cellEdit) => cellEdit.column === cell
                            ).ref && row[cell] ? (
                            <td>
                              {Object.values(
                                theTableMeta
                                  .find((cellEdit) => cellEdit.column === cell)
                                  .values.find(
                                    (value) =>
                                      parseInt(
                                        value[
                                          theTableMeta
                                            .find(
                                              (cellEdit) =>
                                                cellEdit.column === cell
                                            )
                                            .ref.split("/")[1]
                                        ]
                                      ) === parseInt(row[cell])
                                  )
                              ).join("-")}
                            </td>
                          ) : (
                            <td>{row[cell]}</td>
                          )
                        )}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </>
      ) : (
        ""
      )}
    </>
  ) : (
    ""
  );
}

export default AdminSubAdmin2;

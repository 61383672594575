import "../../css/SimcardAddNew.css";
import logo from "../../geuzenetlog.png";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import NewTable from "../../components/newTable/newTable";
import {
  AssetsSelect,
  AssetsDateTimePicker,
} from "../../components/assetsInputs";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import {
  getSimProps,
  getAllSimcards,
  addSimcards,
} from "../../store/actions/assets";
import {
  selectSimProps,
  selectAllSimcards,
} from "../../store/selectors/assets";
const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function SimcardAddNew() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const dispatch = useDispatch();
  const scProps = useSelector(selectSimProps);
  const simcard = useSelector(selectAllSimcards);
  const navigation = useSelector(selectNavigation);
  const iccField = useRef();
  const iccInput = iccField.current;
  const msisdnField = useRef();
  const msisdnInput = msisdnField.current;
  const pinField = useRef();
  const pinInput = pinField.current;
  const pukField = useRef();
  const pukInput = pukField.current;
  const isBigScreen = useMediaQuery({ query: "(min-width: 1100px)" });
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Assets", "Add sims", "details"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (
        (Object.keys(scProps).length === 0 || simcard) &&
        validUser(appUser)
      ) {
        dispatch(getSimProps());
        dispatch(getAllSimcards());
      }
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain]);

  const [itemICC, setItemICC] = useState("");
  const [itemMSISDN, setItemMSISDN] = useState("");
  const [itemPIN, setItemPIN] = useState("0000");
  const [itemPUK, setItemPUK] = useState("");
  const [arrayStatus, setarrayStatus] = useState(false);
  const [saved, setSsaved] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [itemsArray, setItemsArray] = useState([]);

  const globalInit = {
    country: "- Select -",
    networkId: "- Select -",
    subscriptionId: "- Select -",
    statusId: "- Select -",
    location: "- Select -",
    time: new Date(Date.now()),
  };
  const [global, setGlobal] = useState(globalInit);
  const resetItem = () => {
    setItemICC("");
    setItemMSISDN("");
    setItemPIN("0000");
    setItemPUK("");
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      if (event.target.name === "iccInput") {
        msisdnInput.focus();
      } else if (event.target.name === "msisdnInput") {
        pinInput.focus();
      } else if (event.target.name === "pinInput") {
        pukInput.focus();
      } else if (event.target.name === "pukInput") {
        let obj = {
          ...global,
          icc: itemICC,
          msisdn: itemMSISDN,
          pin: itemPIN,
          puk: itemPUK,
        };
        let newArray = [...itemsArray];
        setNextId(nextId + 1);
        if (simcard.find((dbItem) => dbItem.icc === obj.icc)) {
          obj.dbSim = true;
        } else {
          obj.dbSim = false;
        }
        newArray.push(obj);
        setItemsArray(newArray);
        resetItem();
        iccInput.focus();
        arrayCheck(newArray);
      }
    }
  };
  const actualSubscriptions = () => {
    if (scProps.subscription) {
      return scProps.subscription.filter(
        (subscription) => subscription.actual === 1
      );
    }
  };
  const actualNetworksIds = () => {
    if (scProps.subscription) {
      return scProps.subscription
        .filter((subscription) => subscription.actual === 1)
        .map((sub) => {
          return sub.network_id;
        });
    }
  };
  const actualCountries = () => {
    if (scProps.network && scProps.subscription) {
      return scProps.network
        .filter((network) => actualNetworksIds().includes(network.id))
        .map((network) => {
          return network.country;
        });
    }
  };
  const isIccValid = () => {
    if (itemICC) {
      if (
        itemICC.length > 17 &&
        itemICC.length < 23 &&
        itemICC.slice(0, 2) === "89" &&
        !itemsArray.map((item) => item.icc).includes(itemICC)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const isMsisdnValid = () => {
    if (itemMSISDN) {
      if (itemMSISDN.length < 15) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const isPinValid = () => {
    if (itemPIN) {
      if (itemPIN.length === 0 || itemPIN.length === 4) {
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    setItemsArray(
      itemsArray.map((entry) => {
        return { ...entry, ...global };
      })
    );
    setSsaved(false); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global]);
  useEffect(() => {
    arrayCheck(
      itemsArray.map((entry) => {
        return { ...entry, ...global };
      })
    );
  }, [itemsArray, global]);

  const arrayCheck = (array) => {
    if (
      array.length > 0 &&
      array.filter((entry) => Object.values(entry).includes("- Select -"))
        .length === 0 &&
      array.filter((entry) => isNaN(entry.time.valueOf())).length === 0 &&
      array.filter((entry) => entry.dbTag).length === 0
    ) {
      setarrayStatus(true);
    } else {
      setarrayStatus(false);
    }
  };
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return scProps.status && simcard.length > 0 ? (
      <div className="SimcardAddNew">
        <div className="topBar">
          {saved ? (
            <div className="savedNoteAAS">State and/or Location updated!!</div>
          ) : (
            <div className="savedNoteAAS" style={{ color: "white" }}>
              ||
            </div>
          )}
          <div className="mainButtonsAAS">
            {arrayStatus ? (
              <button
                onClick={(e) => {
                  dispatch(addSimcards(itemsArray));
                  setarrayStatus(false);
                  setItemsArray([]);
                  setNextId(0);
                  resetItem();
                  setGlobal(globalInit);
                  setSsaved(true);
                  dispatch(getAllSimcards());
                  iccInput.focus();
                }}
              >
                Save
              </button>
            ) : (
              <button className="disabled">Save</button>
            )}
            <button
              className="cancellButton"
              onClick={() => {
                setarrayStatus(false);
                setItemsArray([]);
                setNextId(0);
                resetItem();
                setGlobal(globalInit);
                setSsaved(false);
                dispatch(getAllSimcards());
                iccInput.focus();
              }}
            >
              Reset
            </button>
          </div>
        </div>
        <div
          className={isBigScreen ? "globalSelection wide" : "globalSelection"}
        >
          <div className="globalSelectionGroup">
            <AssetsSelect
              desc="Country:"
              value={global.country}
              options={[...new Set(actualCountries())].map((country) => {
                return { key: country, value: country };
              })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  country: value,
                });
              }}
            />
            <AssetsSelect
              desc="Network:"
              value={global.networkId}
              options={scProps.network
                .filter(
                  (network) =>
                    network.country === global.country &&
                    actualNetworksIds().includes(network.id)
                )
                .map((network) => {
                  return { key: network.id, value: network.operator };
                })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  networkId: value,
                });
              }}
            />
            <AssetsSelect
              desc="Subscription: "
              value={global.subscriptionId}
              options={actualSubscriptions()
                .filter(
                  (subscription) => subscription.network_id === global.networkId
                )
                .map((subscription) => {
                  return { key: subscription.id, value: subscription.name };
                })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  subscriptionId: value,
                });
              }}
            />
          </div>
          <div className="globalSelectionSpacer"></div>
          <div className="globalSelectionGroup">
            <AssetsSelect
              desc="Status: "
              value={global.statusId}
              options={scProps.status.map((status) => {
                return { key: status.id, value: status.status };
              })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  statusId: value,
                });
              }}
            />
            <AssetsSelect
              desc="Location: "
              value={global.location}
              options={scProps.stock.map((stock) => {
                return { key: stock.id, value: stock.name };
              })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  location: value,
                });
              }}
            />
            <AssetsDateTimePicker
              desc="TIme:"
              value={global.time}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  time: value,
                });
              }}
            />
          </div>
        </div>
        <div className="notTop">
          <form className="scanFields">
            <div className="scanLineTag">
              <div>
                {"ICC : "}
                <input
                  name="iccInput"
                  className="iccInput"
                  ref={iccField}
                  value={itemICC}
                  onKeyDown={handleEnter}
                  onChange={(e) => {
                    setItemICC(e.target.value);
                  }}
                  type="number"
                />
              </div>
              <div>
                {"MSISDN : "}
                <input
                  name="msisdnInput"
                  className={
                    isIccValid() ? "msisdnInput" : "msisdnInputDisabled"
                  }
                  ref={msisdnField}
                  value={itemMSISDN}
                  onKeyDown={handleEnter}
                  onChange={(e) => {
                    setItemMSISDN(e.target.value);
                  }}
                  type="number"
                  disabled={isIccValid() ? false : true}
                />
              </div>
              <div>
                {"Pin : "}
                <input
                  name="pinInput"
                  className={
                    isIccValid() && isMsisdnValid()
                      ? "msisdnInput"
                      : "msisdnInputDisabled"
                  }
                  ref={pinField}
                  value={itemPIN}
                  onKeyDown={handleEnter}
                  onChange={(e) => {
                    setItemPIN(e.target.value);
                  }}
                  disabled={isIccValid() && isMsisdnValid() ? false : true}
                />
              </div>
              <div>
                {"Puk : "}
                <input
                  name="pukInput"
                  className={
                    isIccValid() && isMsisdnValid() && isPinValid()
                      ? "msisdnInput"
                      : "msisdnInputDisabled"
                  }
                  disabled={
                    isIccValid() && isMsisdnValid() && isPinValid()
                      ? false
                      : true
                  }
                  ref={pukField}
                  value={itemPUK}
                  onKeyDown={handleEnter}
                  onChange={(e) => {
                    setItemPUK(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        {itemsArray.length > 0 ? (
          <div className="smallTableWrapper" style={{ maxHeight: "42vh" }}>
            <NewTable
              data={itemsArray
                .slice(0)
                .map((line) => {
                  return (line = {
                    ...line,
                    button: (
                      <button
                        className="removeButtonAAD"
                        onClick={(e) => {
                          e.preventDefault();
                          let arr = itemsArray.filter(
                            (arrItem) => line.id !== arrItem.id
                          );
                          setItemsArray(arr);
                          arrayCheck(arr);
                        }}
                      >
                        Remove
                      </button>
                    ),
                  });
                })
                .map((line) => {
                  if (line.icc && line.dbSim) {
                    return (line = {
                      ...line,
                      remark: `Sim (ICC: ${line.icc}) already exist in the database`,
                    });
                  } else {
                    return line;
                  }
                })
                .reverse()}
              tit="newSims"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      <img className="landingLogo ll" src={logo} alt="company logo" />
    );
  }
}
export default SimcardAddNew;

import "../../css/services.css";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import logo from "../../geuzenetlog.png";
import { getDeliveries } from "../../store/actions/orders";
import {
  selectDeliveries,
  selectMapPoints,
} from "../../store/selectors/orders";
import NewTable from "../../components/newTable/newTable";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function Deliveries() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  document.title = "Geuzenet - Services overview";
  const appUser = useSelector(selectAppUser);

  const headerRef = useRef();

  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Orders", "Overview", "list"));
      }
    };

    setMenu();
  }, [dispatch, appUser]);
  const deliveries = useSelector(selectDeliveries);
  const mapPoints = useSelector(selectMapPoints);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (deliveries.length === 0 && validUser(appUser)) {
        dispatch(getDeliveries());
      }
    };
    getItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deliveries, navigation.menuSub, navigation.menuMain]);

  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div
        className={deliveries.length === 0 ? "Services loading" : "Services"}
      >
        <>
          <div className="topBar"></div>
          <div className="bigTableWrapper" ref={headerRef}>
            {deliveries.length > 0 ? (
              <>
                <NewTable
                  wrapperRef={headerRef}
                  tit={"ordersOverview"}
                  tabletype={"mid"}
                  data={deliveries}
                  width={
                    headerRef.current ? headerRef.current.clientWidth : 100
                  }
                />
              </>
            ) : (
              <img className="landingLogo" src={logo} alt="company logo" />
            )}
          </div>
        </>
      </div>
    );
  }
}

export default Deliveries;

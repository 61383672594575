import "../../css/HardwareAddNew.css";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewTable from "../../components/newTable/newTable";
import {
  AssetsSelect,
  AssetsTextInput,
  AssetsDatePicker,
  AssetsDateTimePicker,
} from "../../components/assetsInputs";
import logo from "../../geuzenetlog.png";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import {
  selectHardwareProps,
  selectAllHardware,
} from "../../store/selectors/assets";
import {
  getHardwareProps,
  getAllHardware,
  addHardware,
} from "../../store/actions/assets";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function HardwareAddNew() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);

  const hwProps = useSelector(selectHardwareProps);
  const hardware = useSelector(selectAllHardware);
  const tagField = useRef();
  const tagInput = tagField.current;
  const serialField = useRef();
  const serialInput = serialField.current;
  const imeiField = useRef();
  const imeiInput = imeiField.current;
  const isBigScreen2 = useMediaQuery({ query: "(min-width: 983px)" });

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Assets", "Add devices", "details"));
      }
    };

    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (
        (hwProps.length === 0 || hardware.length === 0) &&
        validUser(appUser)
      ) {
        dispatch(getHardwareProps());
        dispatch(getAllHardware());
      }
    };
    getItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain]);

  const [itemTag, setItemTag] = useState("");
  const [itemSerial, setItemSerial] = useState("");
  const [itemImei, setItemImei] = useState("");
  const [arrayStatus, setarrayStatus] = useState(false);
  const [saved, setSsaved] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [itemsArray, setItemsArray] = useState([]);

  const globalInit = {
    type: "- Select -",
    model: "- Select -",
    status: "1",
    supplier: "- Select -",
    invoiceNumber: null,
    invoiceDate: null,
    service_id: null,
    stock_id: "- Select -",
    time: new Date(Date.now()),
  };
  const [global, setGlobal] = useState(globalInit);
  const resetItem = () => {
    setItemTag("");
    setItemSerial("");
    setItemImei("");
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      console.log(event);
      if (event.target.name === "tagInput") {
        serialInput.focus();
      }
      if (event.target.name === "serialInput") {
        imeiInput.focus();

        // let obj = {
        //   ...global,
        //   id: nextId,
        //   tag: itemTag,
        //   serialNumber: itemSerial,
        // };
        // let newArray = [...itemsArray];
        // setNextId(nextId + 1);
        // if (hardware.find((dbItem) => dbItem.tag === obj.tag)) {
        //   obj.dbTag = true;
        // } else {
        //   obj.dbTag = false;
        // }
        // newArray.push(obj);
        // setItemsArray(newArray);
        // resetItem();
        // tagInput.focus();
        // }
      }
      if (event.target.name === "imeiInput") {
        let obj = {
          ...global,
          id: nextId,
          tag: itemTag,
          serialNumber: itemSerial,
          imei: itemImei,
        };
        let newArray = [...itemsArray];
        setNextId(nextId + 1);
        if (hardware.find((dbItem) => dbItem.tag === obj.tag)) {
          obj.dbTag = true;
        } else {
          obj.dbTag = false;
        }
        newArray.push(obj);
        setItemsArray(newArray);
        resetItem();
        tagInput.focus();
      }
    }
  };
  const sortByModel = (a, b) => {
    if (a.model.toLowerCase() < b.model.toLowerCase()) {
      return -1;
    }
    if (a.model.toLowerCase() > b.model.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const sortByName = (a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const isTagValid = () => {
    if (itemTag) {
      if (
        itemTag.length === 8 &&
        itemTag.slice(0, 3) === "GZN" &&
        !itemsArray.map((item) => item.tag).includes(itemTag) &&
        !isNaN(itemTag.slice(3, 8))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  useEffect(() => {
    setItemsArray(
      itemsArray.map((entry) => {
        return { ...entry, ...global };
      })
    );
    setSsaved(false); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global]);
  useEffect(() => {
    arrayCheck(
      itemsArray.map((entry) => {
        return { ...entry, ...global };
      })
    );
  }, [itemsArray, global]);

  const arrayCheck = (array) => {
    if (
      array.length > 0 &&
      array.filter((entry) => Object.values(entry).includes("- Select -"))
        .length === 0 &&
      array.filter((entry) => isNaN(entry.time.valueOf())).length === 0 &&
      array.filter(
        (entry) =>
          entry.invoiceDate !== null && isNaN(entry.invoiceDate.valueOf())
      ).length === 0 &&
      array.filter((entry) => entry.dbTag).length === 0
    ) {
      setarrayStatus(true);
    } else {
      setarrayStatus(false);
    }
  };
  const dataLine = (desc, value, onClick) => {
    return (
      <div className="dasLine">
        <div className="dasDesc">{desc}</div>
        <div className="dasValue" onClick={onClick}>
          {value}
        </div>
      </div>
    );
  };
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return Object.keys(hwProps).length !== 0 && hardware.length !== 0 ? (
      <div className="HardwareAddNew">
        <div className="topBar">
          {saved ? (
            <div className="savedNoteAAD">State and/or Location updated!!</div>
          ) : (
            <div className="savedNoteAAD" style={{ color: "white" }}>
              ||
            </div>
          )}
          <div className="mainButtonsAAD">
            {arrayStatus ? (
              <button
                onClick={(e) => {
                  dispatch(addHardware(itemsArray));
                  setarrayStatus(false);
                  setItemsArray([]);
                  setNextId(0);
                  setGlobal(globalInit);
                  resetItem();
                  setSsaved(true);
                  dispatch(getAllHardware());
                  tagInput.focus();
                }}
              >
                Save
              </button>
            ) : (
              <button className="disabledANS">Save</button>
            )}
            <button
              className="cancellButton"
              onClick={() => {
                setarrayStatus(false);
                setItemsArray([]);
                setNextId(0);
                setGlobal(globalInit);
                resetItem();
                setSsaved(false);
                dispatch(getAllHardware());
                tagInput.focus();
              }}
            >
              Reset
            </button>
          </div>
        </div>
        <div
          className={isBigScreen2 ? "globalSelection wide" : "globalSelection"}
        >
          <div className="globalSelectionGroup">
            <AssetsSelect
              desc="Type:"
              value={global.type}
              options={hwProps.type.map((type) => {
                return { key: type.id, value: type.type };
              })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  type: value,
                  model: "- Select -",
                });
              }}
            />
            <AssetsSelect
              desc="Model:"
              value={global.model}
              options={hwProps.model
                .filter(
                  (model) => parseInt(model.type_id) === parseInt(global.type)
                )
                .sort(sortByModel)
                .map((model) => {
                  return { key: model.id, value: model.model };
                })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  model: value,
                });
              }}
            />
            <AssetsSelect
              desc="Supplier:"
              value={global.supplier}
              options={hwProps.supplier.sort(sortByName).map((supplier) => {
                return { key: supplier.id, value: supplier.name };
              })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  supplier: value,
                });
              }}
            />
            {dataLine("", "")}
          </div>
          <div className="globalSelectionSpacer" />
          <div className="globalSelectionGroup">
            <AssetsTextInput
              desc="Invoice #:"
              value={global.invoiceNumber}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  invoiceNumber: value,
                });
              }}
            />
            <AssetsDatePicker
              desc="Invoice date:"
              value={global.invoiceDate}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  invoiceDate: value,
                });
              }}
            />
            <AssetsSelect
              desc="Location:"
              value={global.stock_id}
              options={hwProps.stock.map((stock) => {
                return { key: stock.id, value: stock.name };
              })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  stock_id: value,
                });
              }}
            />
            <AssetsDateTimePicker
              desc="Time:"
              value={global.time}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  time: value,
                });
              }}
            />
          </div>
        </div>
        <div className="notTop">
          <form className="scanFields">
            <div className="scanLineTag">
              <div>
                {"Tag id : "}
                <input
                  name="tagInput"
                  className="tagInput"
                  ref={tagField}
                  value={itemTag}
                  onKeyDown={handleEnter}
                  onChange={(e) => {
                    setItemTag(e.target.value);
                  }}
                />
              </div>
              <div>
                {"Serial # : "}
                <input
                  name="serialInput"
                  className={
                    isTagValid() ? "serialInput" : "serialInputDisabled"
                  }
                  disabled={isTagValid() ? false : true}
                  ref={serialField}
                  value={itemSerial}
                  onKeyDown={handleEnter}
                  onChange={(e) => {
                    setItemSerial(e.target.value);
                  }}
                />
              </div>
              <div>
                {"Imei : "}
                <input
                  name="imeiInput"
                  className={
                    isTagValid() ? "serialInput" : "serialInputDisabled"
                  }
                  disabled={isTagValid() ? false : true}
                  ref={imeiField}
                  value={itemImei}
                  onKeyDown={handleEnter}
                  onChange={(e) => {
                    setItemImei(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        {itemsArray.length > 0 ? (
          <div className="smallTableWrapper" style={{ maxHeight: "40vh" }}>
            <NewTable
              data={itemsArray
                .slice(0)
                .map((line) => {
                  return (line = {
                    ...line,
                    button: (
                      <button
                        className="removeButtonAAD"
                        onClick={(e) => {
                          e.preventDefault();
                          let arr = itemsArray.filter(
                            (arrItem) => line.id !== arrItem.id
                          );
                          setItemsArray(arr);
                          arrayCheck(arr);
                        }}
                      >
                        Remove
                      </button>
                    ),
                  });
                })
                .map((line) => {
                  if (line.tag && line.dbTag) {
                    return (line = {
                      ...line,
                      remark: `Tag ${line.tag} already exist in the database`,
                    });
                  } else {
                    return line;
                  }
                })
                .reverse()}
              tit="newAssets"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      <img className="landingLogo" src={logo} alt="company logo" />
    );
  }
}
export default HardwareAddNew;

import "../../css/services.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import Select from "@mui/material/Select";
import { MenuItem } from "@material-ui/core";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import logo from "../../geuzenetlog.png";
import NewTable from "../../components/newTable/newTable";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

import { getHardwareProps, getSimProps } from "../../store/actions/assets";
import {
  selectSimProps,
  selectHardwareProps,
} from "../../store/selectors/assets";

import {
  getFilteredContacts,
  setContactsToNull,
} from "../../store/actions/services";
import { selectFilteredContacts } from "../../store/selectors/services";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function Contacts() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const headerRef = useRef();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const navigation = useSelector(selectNavigation);
  const contacts = useSelector(selectFilteredContacts);
  const scProps = useSelector(selectSimProps);
  const hwProps = useSelector(selectHardwareProps);
  const [preFilteredContacts, setPreFilteredContacts] = useState([]);
  const [reqDate, setReqDate] = useState();
  const [reqUsers, setUsers] = useState(false);
  const [reqCustomers, setCustomers] = useState(true);
  const [reqAgents, setAgents] = useState(false);
  const [confFilter, setconfFilter] = useState([]);
  const [userCountryFilter, setuserCountryFilter] = useState([]);
  const [routerModelsFilter, setrouterModelsFilter] = useState([]);
  const [modemModelsFilter, setmodemModelsFilter] = useState([]);
  const [networksFilter, setnetworksFilter] = useState([]);
  const [subscriptionsFilter, setsubscriptionsFilter] = useState([]);
  const [emailsFilter, setemailsFilter] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Services", "Contacts", "list"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    if (validUser(appUser)) {
      setReqDate(
        `${new Date(Date.now()).getFullYear()}-${String(
          "0" + new Date(Date.now()).getMonth()
        ).slice(-2)}-${String("0" + new Date(Date.now()).getDate()).slice(-2)}`
      );
      dispatch(getHardwareProps());
      dispatch(getSimProps());
      dispatch(
        getFilteredContacts(
          `${new Date(Date.now()).getFullYear()}-${String(
            "0" + new Date(Date.now()).getMonth()
          ).slice(-2)}-${String("0" + new Date(Date.now()).getDate()).slice(
            -2
          )}`,
          reqCustomers,
          reqUsers,
          reqAgents
        )
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation.menuSub, navigation.menuMain]);

  useEffect(() => {
    if (reqDate && validUser(appUser)) {
      dispatch(setContactsToNull());
      dispatch(getFilteredContacts(reqDate, reqCustomers, reqUsers, reqAgents));
    }
    setPreFilteredContacts([]); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reqDate, reqCustomers, reqUsers, reqAgents]);

  let configurations = Array.from(
    new Set(contacts.map((contact) => contact.configuration))
  );
  let usersCountries = Array.from(
    new Set(contacts.map((contact) => contact.userCountry))
  );
  let routerModels = Array.from(
    new Set(
      [].concat.apply(
        [],
        contacts.map((contact) => [...contact.routers])
      )
    )
  );
  let modemModels = Array.from(
    new Set(
      [].concat.apply(
        [],
        contacts.map((contact) => [...contact.modems])
      )
    )
  );
  let networks = Array.from(
    new Set(
      [].concat.apply(
        [],
        contacts.map((contact) => [...contact.networks])
      )
    )
  );
  let subscriptions = Array.from(
    new Set(
      [].concat.apply(
        [],
        contacts.map((contact) => [...contact.subscriptions])
      )
    )
  );

  useEffect(() => {
    setPreFilteredContacts(contacts);
    setconfFilter(configurations);
    setuserCountryFilter(usersCountries);
    setrouterModelsFilter(routerModels);
    setmodemModelsFilter(modemModels);
    setnetworksFilter(networks);
    setsubscriptionsFilter(subscriptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);
  useEffect(() => {
    setPreFilteredContacts(
      contacts.filter(
        (contact) =>
          confFilter.includes(contact.configuration) &&
          userCountryFilter.includes(contact.userCountry) &&
          routerModelsFilter.some((i) => contact.routers.includes(i)) &&
          modemModelsFilter.some((i) => contact.modems.includes(i)) &&
          networksFilter.some((i) => contact.networks.includes(i)) &&
          subscriptionsFilter.some((i) => contact.subscriptions.includes(i)) &&
          emailsFilter.some((i) => contact.emails.includes(i))
      )
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    confFilter,
    userCountryFilter,
    routerModelsFilter,
    modemModelsFilter,
    networksFilter,
    subscriptionsFilter,
    emailsFilter,
  ]);
  function OuterMultiSelectColumnFilter(
    filterValue,
    setFilter,
    options,
    header
  ) {
    let optionsString = JSON.stringify(options.map((opt) => opt.id).sort());
    let filterString = JSON.stringify(filterValue.sort());

    return (
      <div className="multiSelectFilter">
        <Select
          multiple
          sx={{ width: "100%", height: 25 }}
          width={"100%"}
          value={filterValue}
          MenuProps={MenuProps}
          renderValue={(selected) => {
            return (
              <Box
                sx={{
                  gap: 5,
                  fontFamily: "Quenda",
                }}
              >
                {`${header} (${
                  filterValue.includes(header)
                    ? filterValue.length - 1
                    : filterValue.length
                }/${options.length})`}
              </Box>
            );
          }}
          onChange={(e) => {
            if (
              e.target.value.includes("all") &&
              optionsString !== filterString
            ) {
              setFilter(options.map((opt) => opt.id));
            } else if (
              e.target.value.includes("all") &&
              optionsString === filterString
            ) {
              setFilter([header]);
            } else {
              setFilter(e.target.value);
            }
          }}
        >
          <MenuItem value={"all"}>
            <Checkbox checked={optionsString === filterString} />
            <ListItemText primary={"All"} />
          </MenuItem>
          {options.map((option, i) => (
            <MenuItem key={i} value={option.id}>
              <Checkbox checked={filterValue.indexOf(option.id) > -1} />
              <ListItemText primary={option.desc} />
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className={"Services"}>
        <>
          <div className="topBar"></div>
          <div className="contactsForm">
            <input
              value={reqDate}
              className="dasValue"
              type="date"
              max={"2999-12-31"}
              min={"2000-01-01"}
              onChange={(e) => {
                setReqDate(e.target.value);
                dispatch(setContactsToNull());
              }}
            ></input>
            <div className="cuaChecks">
              <div>
                Customers
                <input
                  type={"checkbox"}
                  checked={reqCustomers}
                  onChange={(e) => {
                    setCustomers(!reqCustomers);
                  }}
                />
              </div>
              <div>
                Users
                <input
                  type={"checkbox"}
                  checked={reqUsers}
                  onChange={(e) => {
                    setUsers(!reqUsers);
                  }}
                />
              </div>
              <div>
                Agents
                <input
                  type={"checkbox"}
                  checked={reqAgents}
                  onChange={(e) => {
                    setAgents(!reqAgents);
                  }}
                />
              </div>
            </div>
          </div>
          {Object.keys(scProps).length > 0 &&
          Object.keys(hwProps).length > 0 &&
          contacts.length > 0 ? (
            <div className="orgFilters">
              <div className="orgFilter">
                {contacts.length > 0 && configurations.length > 0
                  ? OuterMultiSelectColumnFilter(
                      confFilter,
                      setconfFilter,
                      configurations.map((model) => {
                        return {
                          id: model,
                          desc: model,
                        };
                      }),
                      "Configuration"
                    )
                  : ""}
              </div>
              <div className="orgFilter">
                {contacts.length > 0 && usersCountries.length > 0
                  ? OuterMultiSelectColumnFilter(
                      userCountryFilter,
                      setuserCountryFilter,
                      usersCountries.map((model) => {
                        return {
                          id: model,
                          desc: model,
                        };
                      }),
                      "Country"
                    )
                  : ""}
              </div>
              <div className="orgFilter">
                {contacts.length > 0 && routerModels.length > 0
                  ? OuterMultiSelectColumnFilter(
                      routerModelsFilter,
                      setrouterModelsFilter,
                      routerModels.map((model) => {
                        return {
                          id: model,
                          desc:
                            model === "-"
                              ? model
                              : hwProps.model.find(
                                  (prop) => parseInt(prop.id) === model
                                ).model,
                        };
                      }),
                      "Router"
                    )
                  : ""}
              </div>
              <div className="orgFilter">
                {contacts.length > 0 && modemModels.length > 0
                  ? OuterMultiSelectColumnFilter(
                      modemModelsFilter,
                      setmodemModelsFilter,
                      modemModels.map((model) => {
                        return {
                          id: model,
                          desc:
                            model === "-"
                              ? model
                              : hwProps.model.find(
                                  (prop) => parseInt(prop.id) === model
                                ).model,
                        };
                      }),
                      "Modem"
                    )
                  : ""}
              </div>
              <div className="orgFilter">
                {contacts.length > 0 && networks.length > 0
                  ? OuterMultiSelectColumnFilter(
                      networksFilter,
                      setnetworksFilter,
                      networks.map((network) => {
                        return {
                          id: network,
                          desc:
                            network === "-"
                              ? network
                              : scProps.network.find(
                                  (prop) => parseInt(prop.id) === network
                                ).operator,
                        };
                      }),
                      "Network"
                    )
                  : ""}
              </div>
              <div className="orgFilter">
                {contacts.length > 0 && subscriptions.length > 0
                  ? OuterMultiSelectColumnFilter(
                      subscriptionsFilter,
                      setsubscriptionsFilter,
                      subscriptions.map((subscription) => {
                        return {
                          id: subscription,
                          desc:
                            subscription === "-"
                              ? subscription
                              : scProps.subscription.find(
                                  (prop) => parseInt(prop.id) === subscription
                                ).name,
                        };
                      }),
                      "Subscription"
                    )
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {Object.keys(scProps).length > 0 &&
          Object.keys(hwProps).length > 0 &&
          contacts.length > 0 ? (
            <div className="mailChecks">
              <div className="mailCheck">
                <span className="mailCheckDesc">General Geuzenet email</span>
                <input
                  className="mailCheckCheck"
                  type={"checkbox"}
                  checked={emailsFilter.includes("general")}
                  onChange={(e) => {
                    if (emailsFilter.includes("general")) {
                      setemailsFilter([]);
                    } else {
                      setemailsFilter(["general"]);
                    }
                  }}
                />
              </div>
              <div className="mailCheck">
                <span className="mailCheckDesc">Service email support</span>
                <input
                  className="mailCheckCheck"
                  type={"checkbox"}
                  checked={emailsFilter.includes("support")}
                  onChange={(e) => {
                    if (emailsFilter.includes("support")) {
                      setemailsFilter([]);
                    } else {
                      setemailsFilter(["support"]);
                    }
                  }}
                />
              </div>
              <div className="mailCheck">
                <span className="mailCheckDesc">Service email commercial</span>
                <input
                  className="mailCheckCheck"
                  type={"checkbox"}
                  checked={emailsFilter.includes("commercial")}
                  onChange={(e) => {
                    if (emailsFilter.includes("commercial")) {
                      setemailsFilter([]);
                    } else {
                      setemailsFilter(["commercial"]);
                    }
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="bigTableWrapper" ref={headerRef}>
            {Object.keys(scProps).length > 0 &&
            Object.keys(hwProps).length > 0 &&
            contacts.length > 0 ? (
              <NewTable
                wrapperRef={headerRef}
                tit={"servicesContacts"}
                tabletype={"big"}
                data={[
                  ...new Map(
                    preFilteredContacts.map((item) => [item.id, item])
                  ).values(),
                ]}
                width={headerRef.current ? headerRef.current.clientWidth : 100}
              />
            ) : (
              <img className="landingLogo" src={logo} alt="company logo" />
            )}
          </div>
        </>
      </div>
    );
  }
}

export default Contacts;

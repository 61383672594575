const initialState = {
  pools: { poolsPM: [], poolPD: [], poolTopUsers: [], poolDetails: {} },
  signal: {
    antennaDetails: {},
    allModems: [],
    download: null,
    upload: null,
    onlineModems: [],
    unsupported: [],
    signalData: {},
    lastBeep: "",
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "admin/setSignalDownload":
      return {
        ...state,
        signal: { ...state.signal, download: action.payload },
      };
    case "admin/setSignalUpload":
      return { ...state, signal: { ...state.signal, upload: action.payload } };
    case "admin/storePoolsPM":
      return { ...state, pools: { ...state.pools, poolsPM: action.payload } };
    case "admin/storePoolPD":
      return {
        ...state,
        pools: { ...state.pools, poolPD: action.payload },
      };
    case "admin/storePoolDetails":
      return {
        ...state,
        pools: { ...state.pools, poolDetails: action.payload },
      };
    case "admin/storePoolTopUsers":
      return {
        ...state,
        pools: { ...state.pools, poolTopUsers: action.payload },
      };
    case "admin/storeAntennaDetails":
      return {
        ...state,
        signal: { ...state.signal, antennaDetails: action.payload },
      };
    case "admin/storeAllModems":
      return {
        ...state,
        signal: {
          ...state.signal,
          allModems: action.payload.all,
          onlineModems: action.payload.online,
          unsupported: action.payload.unsupported,
        },
      };
    case "admin/setSignalData":
      return { ...state, signalData: action.payload };
    case "admin/updateTheBeep":
      return {
        ...state,
        signal: { ...state.signal, lastBeep: action.payload },
      };

    case "admin/init":
      return { ...initialState };
    default:
      return state;
  }
}

import axios from "axios";
const key = process.env.REACT_APP_API_KEY;
const endpoint = `psqlEdit?code=${key}`;
const url = `/api/${endpoint}`;

const storeAllTables = (tables) => {
  return {
    type: "psqlEdit/storeAllTables",
    payload: tables,
  };
};
const storeResponse = (error) => {
  return {
    type: "psqlEdit/storeError",
    payload: error,
  };
};

const storeTheTableMeta = (meta) => {
  return {
    type: "psqlEdit/storeTheTableMeta",
    payload: meta,
  };
};

// +++

export const cleanTheTable = (table) => {
  return {
    type: "psqlEdit/cleanTheTable",
  };
};

export const getAllTables = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=getAllTables`);
    // dispatch(storeLastWebcrmSync(response));
    if (response.data.intStatus === 200) {
      dispatch(storeAllTables(response.data.data));
    } else {
      dispatch(storeResponse(response.data.data));
    }
  };
};

export const getTheTable = (table) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=getTheTable`, {
      params: {
        table: table,
      },
    });
    if (response.data.intStatus === 200) {
      dispatch(storeResponse());
      dispatch(storeTheTable(response.data.data.theTable));
      dispatch(storeTheTableMeta(response.data.data.theTableMeta));
    } else {
      dispatch(storeResponse(response.data.data));
    }
  };
};

export const storeTheTable = (tables) => {
  return {
    type: "psqlEdit/storeTheTable",
    payload: tables,
  };
};

export const updateTheTable = (tableName, table) => {
  console.log(tableName, table);
  return async (dispatch, getState) => {
    try {
      let response = await axios.post(`${url}&&type=updateTheTable`, {
        tableName,
        table,
      });
      console.log(response);
      if (response.data.intStatus === 200) {
        dispatch(storeResponse(response.data.data));
        dispatch(cleanTheTable());
      } else if (response.data.intStatus === 500) {
        console.log(response.data);
        dispatch(storeResponse(response.data.data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

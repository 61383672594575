import axios from "axios";
const key = process.env.REACT_APP_API_KEY;
const endpoint = `logTable?code=${key}`;
const url = `/api/${endpoint}`;

const storeLogTable = (table) => {
  return {
    type: "logTable/storeLogTable",
    payload: table,
  };
};

export const getLogTable = (table) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=getTable${table}`);
    if (response.data.length === 0) {
      dispatch(nullLogTable());
    } else {
      dispatch(storeLogTable(response.data));
    }
  };
};

export const nullLogTable = () => {
  return { type: "logTable/setToNull" };
};

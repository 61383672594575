// import "../css/landing.css";
// import logo from "../geuzenetlog.png";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import ChangesConfirm from "./changesConfirm";
import NewInvoicesPreselect from "./newInvoicesPreselect";
import NewInvoicesProcess from "./newInvoicesProcess";
import {
  selectAppUser,
  selectInvoicingSteps,
} from "../../store/selectors/navigation";
import {
  selectNewInvoices,
  selectUnconfirmedContract,
} from "../../store/selectors/invoicing";

import {
  setCategories,
  getAppUser,
  reduxInit,
  setInvoicingSteps,
} from "../../store/actions/navigation";
import {
  generateInvoices,
  storeNewInvoices,
} from "../../store/actions/invoicing";
import {
  AssetsTextInput2,
  AssetsDatePicker2,
  AssetsCheckbox2,
} from "../../components/assetsInputs";

function Invoicing() {
  const [step, setStep] = useState(1);
  const newInvoices = useSelector(selectNewInvoices);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppUser());
  }, [dispatch]);
  const validUser = (user) =>
    user
      ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
      : false;
  // let frontUser;
  const appUser = useSelector(selectAppUser);
  const invoicingSteps = useSelector(selectInvoicingSteps);
  const unconfirmed = useSelector(selectUnconfirmedContract);
  useEffect(() => {
    dispatch(reduxInit());
    dispatch(
      setInvoicingSteps({
        ...invoicingSteps,
        invoicingDate: new Date(new Date().setHours(0, 0, 0, 0)),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Invoicing", "New invoices"));
      }
    };
    setMenu(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appUser]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className="newInvoicesWrapper">
        <div className="serDetails">
          <div className="ServiceDetails">
            <div className="topBar"></div>
            <div className="invNavMain">
              <button
                className={
                  step !== 1
                    ? "invNavMainButton"
                    : "invNavMainButton inactiveButton"
                }
                onClick={(e) => {
                  if (step === 1) {
                    return null;
                  } else if (step === 2) {
                    setStep(step - 1);
                  } else if (step === 3) {
                    dispatch(storeNewInvoices([]));
                    dispatch(
                      setInvoicingSteps({
                        ...invoicingSteps,
                        allInvoicesIds: [],
                        includedInvoicesIds: [],
                      })
                    );
                    setStep(step - 1);
                  } else if (step === 4) {
                    setStep(step - 1);
                  }
                }}
              >
                {`<< Prev`}
              </button>
              <div className="invNavSub">
                <div
                  className={
                    step === 2
                      ? "newInvoicesSteps"
                      : "newInvoicesSteps disabledInvoicesStep"
                  }
                >
                  <div className="newInvoicesStepsSub">
                    <AssetsCheckbox2
                      className={step === 2 ? "" : "disabledInvElement"}
                      desc={"General invoices:"}
                      value={invoicingSteps.generalInvoices}
                      onChangeFunc={() => {
                        if (step === 2) {
                          dispatch(
                            setInvoicingSteps({
                              ...invoicingSteps,
                              generalInvoices: !invoicingSteps.generalInvoices,
                            })
                          );
                        }
                      }}
                    />
                    <AssetsCheckbox2
                      className={step === 2 ? "" : "disabledInvElement"}
                      desc={"Partner fees:"}
                      value={invoicingSteps.partnerFees}
                      onChangeFunc={() => {
                        if (step === 2) {
                          dispatch(
                            setInvoicingSteps({
                              ...invoicingSteps,
                              partnerFees: !invoicingSteps.partnerFees,
                            })
                          );
                        }
                      }}
                    />
                    <AssetsCheckbox2
                      className={step === 2 ? "" : "disabledInvElement"}
                      desc={"Internet suitcases:"}
                      value={invoicingSteps.internetSuitcases}
                      // disabled={true}
                      onChangeFunc={() => {
                        if (step === 2) {
                          dispatch(
                            setInvoicingSteps({
                              ...invoicingSteps,
                              internetSuitcases:
                                !invoicingSteps.internetSuitcases,
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="newInvoicesStepsSub">
                    <AssetsDatePicker2
                      value={invoicingSteps.invoicingDate}
                      onChangeFunc={(e) => {
                        if (step === 2) {
                          dispatch(
                            setInvoicingSteps({
                              ...invoicingSteps,
                              invoicingDate: e,
                            })
                          );
                        }
                      }}
                      disabled={step !== 2}
                    />
                  </div>
                </div>
                <div
                  className={
                    step === 3
                      ? "newInvoicesSteps"
                      : "newInvoicesSteps disabledInvoicesStep"
                  }
                >
                  <div className="newInvoicesStepsSub">
                    <button
                      className={
                        step === 3 &&
                        invoicingSteps.includedInvoicesIds.length > 0
                          ? "invNavMainButton invNavIncludeButton"
                          : "invNavMainButton invNavIncludeButton inactiveButton "
                      }
                      onClick={(e) => {
                        if (
                          step === 3 &&
                          invoicingSteps.includedInvoicesIds.length > 0
                        ) {
                          dispatch(
                            setInvoicingSteps({
                              ...invoicingSteps,
                              includedInvoicesIds: [],
                            })
                          );
                        }
                      }}
                    >
                      Include none
                    </button>
                    <span
                      className={
                        step === 3
                          ? "includeCounter"
                          : " includeCounter disabledInvElement"
                      }
                    >
                      {invoicingSteps.includedInvoicesIds.length}/
                      {invoicingSteps.generatingInvoices ||
                      [1, 2].includes(step)
                        ? "?"
                        : invoicingSteps.allInvoicesIds.length}
                    </span>
                    <button
                      className={
                        step === 3 &&
                        invoicingSteps.includedInvoicesIds.length <
                          invoicingSteps.allInvoicesIds.length
                          ? "invNavMainButton invNavIncludeButton"
                          : "invNavMainButton invNavIncludeButton inactiveButton "
                      }
                      onClick={(e) => {
                        if (
                          step === 3 &&
                          invoicingSteps.includedInvoicesIds.length <
                            invoicingSteps.allInvoicesIds.length
                        ) {
                          dispatch(
                            setInvoicingSteps({
                              ...invoicingSteps,
                              includedInvoicesIds: newInvoices.map(
                                (invoice) => invoice.id
                              ),
                            })
                          );
                        }
                      }}
                    >
                      Include all
                    </button>
                  </div>
                  <div className="newInvoicesStepsSub">
                    <AssetsTextInput2
                      descClass={
                        step === 3 && newInvoices.length > 0
                          ? ""
                          : "disabledInvElement"
                      }
                      desc={"Search:"}
                      disabled={step !== 3 && newInvoices.length > 0}
                      onChangeFunc={(value) => {
                        dispatch(
                          setInvoicingSteps({
                            ...invoicingSteps,
                            preSelSearch: value,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className={
                    step === 4
                      ? "newInvoicesSteps"
                      : "newInvoicesSteps disabledInvoicesStep"
                  }
                >
                  <div className="newInvoicesStepsSub">
                    <AssetsCheckbox2
                      className={step === 4 ? "" : "disabledInvElement"}
                      desc={"Single invoices:"}
                      value={invoicingSteps.singleInvoices}
                      onChangeFunc={() => {
                        if (step === 4) {
                          dispatch(
                            setInvoicingSteps({
                              ...invoicingSteps,
                              invoiceIndex: 0,
                              singleInvoices: !invoicingSteps.singleInvoices,
                              collectiveInvoices:
                                !invoicingSteps.collectiveInvoices,
                            })
                          );
                        }
                      }}
                    />
                    <AssetsCheckbox2
                      className={step === 4 ? "" : "disabledInvElement"}
                      desc={"Collective invoices:"}
                      value={invoicingSteps.collectiveInvoices}
                      onChangeFunc={() => {
                        if (step === 4) {
                          dispatch(
                            setInvoicingSteps({
                              ...invoicingSteps,
                              invoiceIndex: 0,
                              singleInvoices: !invoicingSteps.singleInvoices,
                              collectiveInvoices:
                                !invoicingSteps.collectiveInvoices,
                            })
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="newInvoicesSteps disabledInvoicesStep">
                  {step === 1 ? (
                    <h3 style={{ margin: "1px" }}>step desc(1)</h3>
                  ) : step === 2 ? (
                    <h3 style={{ margin: "1px" }}>step desc(2)</h3>
                  ) : step === 3 ? (
                    <h3 style={{ margin: "1px" }}>step desc(3)</h3>
                  ) : (
                    <h3 style={{ margin: "1px" }}>step desc(4)</h3>
                  )}
                </div>
              </div>
              <button
                className={
                  step === 4 ||
                  unconfirmed !== "nothingToConfirm" ||
                  (step === 3 && invoicingSteps.generatingInvoices === true)
                    ? "invNavMainButton inactiveButton"
                    : "invNavMainButton"
                }
                onClick={(e) => {
                  if (step === 1 && unconfirmed === "nothingToConfirm") {
                    setStep(step + 1);
                  } else if (step === 2) {
                    dispatch(generateInvoices(invoicingSteps));
                    setStep(step + 1);
                  } else if (
                    step === 3 &&
                    invoicingSteps.generatingInvoices === false
                  ) {
                    setStep(step + 1);
                  }
                }}
              >
                {`Next >>`}
              </button>
            </div>
            <div className="invBodyMain">
              {step === 1 ? (
                <ChangesConfirm />
              ) : step === 3 ? (
                <NewInvoicesPreselect />
              ) : step === 4 ? (
                <NewInvoicesProcess />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Invoicing;

export const storeExportTable = (tabledata) => {
  return {
    type: "sync/storeExportTable",
    payload: tabledata,
  };
};
export const exportTableInit = () => {
  return {
    type: "sync/cleanExportTables",
  };
};

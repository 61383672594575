const initialState = { rows: [], columns: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "logTable/storeLogTable":
      return { ...action.payload };
    case "logTable/setToNull":
      return { ...initialState };
    default:
      return state;
  }
}

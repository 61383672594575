import "../css/adminSignalTest.css";
import Button from "@mui/material/Button";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import { selectDownload, selectUpload } from "../store/selectors/admin";
import { setSignalDownload, setSignalUpload } from "../store/actions/admin";
import axios from "axios";

let ascRef = React.createRef();

const AntennaSignalSpeedTest = (props) => {
  const dispatch = useDispatch();
  const download = useSelector(selectDownload);
  const upload = useSelector(selectUpload);

  const modemTag = props.tag;

  const getDownload = async () => {
    dispatch(setSignalDownload("loading"));
    dispatch(setSignalUpload("loading"));
    try {
      let res1 = await axios.post(
        "https://gznendpointapi.azurewebsites.net/api/speedtest",
        { modem: modemTag, direction: "download" },
        {}
      );
      if (
        res1.data.download_average === "0" ||
        Object.keys(res1.data)[0] === "error" ||
        Object.keys(res1.data)[0] === "ERROR"
      ) {
        setTimeout(() => {
          getDownload();
        }, "1000");
      } else {
        console.log("download:", res1.data);
        dispatch(setSignalDownload(res1.data.download_receive));
        setTimeout(() => {
          getUpload();
        }, "1000");
      }
    } catch (e) {
      setTimeout(() => {
        getDownload();
      }, "1000");
    }
  };
  const getUpload = async () => {
    console.log(modemTag, props);
    dispatch(setSignalUpload("loading"));
    try {
      let res1 = await axios.post(
        "https://gznendpointapi.azurewebsites.net/api/speedtest",
        { modem: modemTag, direction: "upload" },
        {}
      );
      if (
        res1.data.upload_average === "0" ||
        Object.keys(res1.data)[0] === "error"
      ) {
        setTimeout(() => {
          getUpload();
        }, "1000");
      } else {
        dispatch(setSignalUpload(res1.data.upload_send));
        console.log("upload:", res1.data);
      }
    } catch (e) {
      setTimeout(() => {
        getUpload();
      }, "1000");
    }
  };
  return (
    <div ref={ascRef} className={"antennaSigSpeed"}>
      <div>
        <Button
          disabled={download === "loading" || upload === "loading"}
          variant="contained"
          sx={{
            fontFamily: "Quenda, serif !important",

            background: "#4ab217",
            color: "white",
            width: "125px",
            ":hover": {
              bgcolor: "#4ab217",
              color: "white",
            },
            ":disabled": {
              color: "white",
            },
          }}
          onClick={(e) => {
            getDownload();
          }}
        >
          Speed test
        </Button>
      </div>
      {download && upload ? (
        <div className="speedResults">
          <div>
            Download:{" "}
            {download === "loading" ? (
              <AutorenewRoundedIcon className="loadingIcon" />
            ) : (
              download
            )}
          </div>
          <div>
            Upload:{" "}
            {upload === "loading" ? (
              <AutorenewRoundedIcon className="loadingIcon" />
            ) : (
              upload
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default AntennaSignalSpeedTest;

import "../../css/orders.css";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import logo from "../../geuzenetlog.png";
import NewTable from "../../components/newTable/newTable";
import {
  selectDirtyOrders,
  selectLastOrderCheck,
} from "../../store/selectors/orders";

import { getDirtyOrders, getLastOrderCheck } from "../../store/actions/orders";
import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function DirtyOrders() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Orders", "Dirty orders", "list"));
      }
    };

    setMenu();
  }, [dispatch, appUser]);
  const headerRef = useRef();
  const navigation = useSelector(selectNavigation);
  const lastWebcrmSync = useSelector(selectLastOrderCheck);
  const dirtyOrders = useSelector(selectDirtyOrders);
  const [message, setMessage] = useState({
    type: "empty",
    message: ".",
  });
  const customTimeFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear(),
      t = date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
    return `${y}-${m}-${d} ${t}`;
  };
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (dirtyOrders.length === 0) {
        dispatch(getDirtyOrders());
      }
    };
    if (validUser(appUser)) {
      getItems();
      dispatch(getLastOrderCheck());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dirtyOrders, navigation.menuSub, navigation.menuMain]);
  useEffect(() => {
    if (lastWebcrmSync.start_t) {
      setMessage({
        type: "lastSync",
        message: `Last successful sync at: ${customTimeFormat(
          new Date(lastWebcrmSync.start_t)
        )}`,
      });
    }
  }, [lastWebcrmSync]);
  if (lastWebcrmSync.start_t && message.message === ".") {
    setMessage({
      type: "lastSync",
      message: `Last successful sync at: ${customTimeFormat(
        new Date(lastWebcrmSync.start_t)
      )}`,
    });
  }
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className={dirtyOrders.length === 0 ? "Orders loading" : "Orders"}>
        <div className="bigTableWrapper serListTable" ref={headerRef}>
          <div className="topBar"></div>
          <div className="dirtySyncInfo">
            {message.type === "lastSync" ? (
              <div className="savedNoteAAD">{message.message}</div>
            ) : message.type === "newSync" ? (
              <div className="savedNoteAAD">{message.message}</div>
            ) : (
              ""
            )}
          </div>
          {dirtyOrders.length > 0 ? (
            <NewTable
              tabletype="big"
              tit="dirtyOrders"
              data={dirtyOrders.map((line) => {
                return {
                  ...line,
                  reported_t: line.reported_t
                    ? customTimeFormat(new Date(line.reported_t), undefined)
                    : null,
                };
              })}
              width={headerRef.current ? headerRef.current.clientWidth : 100}
            />
          ) : (
            <img className="landingLogo" src={logo} alt="company logo" />
          )}
        </div>
      </div>
    );
  }
}

export default DirtyOrders;

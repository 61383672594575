import axios from "axios";
const key = process.env.REACT_APP_API_KEY;

const storeKoffers = (data) => {
  return {
    type: "koffers/storeKoffers",
    payload: data,
  };
};
const storeAllKoffers = (data) => {
  return {
    type: "koffers/storeAllKoffers",
    payload: data,
  };
};
// export const cleanKoffers = () => {
//   return {
//     type: "koffers/cleanKoffers",
//   };
// };

// +++

export const getAllKoffers = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/services_koffers?code=${key}&&type=getKoffers`
    );
    console.log(response);
    dispatch(storeAllKoffers(response.data));
  };
};

export const getSelectedKoffers = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/koffers_read?code=${key}&&type=getKoffers`
    );
    console.log(response);
    dispatch(storeKoffers(response.data));
  };
};

export const updateKoffers = (cell) => {
  console.log(cell.value === "Yes" ? true : false);
  return async (dispatch, getState) => {
    const response = await axios.get(
      `/api/services_koffers?code=${key}&&type=updateKoffers&&id=${parseInt(
        cell.row.original.id
      )}&&visibility=${cell.value === "Yes" ? false : true}`
    );
    console.log(response.data.all.data);
    dispatch(storeKoffers(response.data.selected));
    dispatch(storeAllKoffers(response.data.all));
  };
};

export default function Hamburger({ isOpen }) {
  return (
    <>
      <div className="hamDiv">
        <div className="burger burger1" />
        <div className="burger burger2" />
        <div className="burger burger3" />
      </div>

      <style jsx="true">{`
        .hamburger {
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: space-around;
          flex-flow: column nowrap;
          z-index: 10;
        }
        .burger {
          width: 30px;
          height: 4px;
          border-radius: 10px;
          background-color: #f9323f;
          transition: all 0.3s linear;
          margin-top: 5px;
          margin-left: auto;
          margin-right: auto;
        }
        .burger1 {
          transform: ${isOpen
            ? "rotate(90deg) translateX(2px) translateY(15px)"
            : "rotate(0)"};
        }
        .burger2 {
          transform: ${isOpen
            ? "rotate(90deg) translateX(0px) translateY(0px)"
            : "rotate(0)"};
        }
        .burger3 {
          transform: ${isOpen
            ? "rotate(90deg) translateX(-14px) translateY(-15px)"
            : "rotate(0)"};
        }
      `}</style>
    </>
  );
}

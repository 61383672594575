import axios from "axios";
import { dispatch } from "d3";
const key = process.env.REACT_APP_API_KEY;
const endpoint = `g_admin_pools?code=${key}`;
const url = `/api/${endpoint}`;

export const adminInit = () => {
  return {
    type: "admin/init",
  };
};

const storePoolsPM = (dataUsage) => {
  return {
    type: "admin/storePoolsPM",
    payload: dataUsage,
  };
};
export const getDataUsagePPM = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=poolsPM`);
    dispatch(storePoolsPM(response.data));
  };
};

const storePoolPD = (dataUsage) => {
  return {
    type: "admin/storePoolPD",
    payload: dataUsage,
  };
};
export const getDataUsagePPD = (id) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=poolPD&&id=${id}`);
    dispatch(storePoolPD(response.data));
  };
};

const storePoolDetails = (details) => {
  return {
    type: "admin/storePoolDetails",
    payload: details,
  };
};
export const getPoolDetails = (id) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=poolDetails&&id=${id}`);
    dispatch(storePoolDetails(response.data));
  };
};

const storePoolTopUsers = (topUsers) => {
  return {
    type: "admin/storePoolTopUsers",
    payload: topUsers,
  };
};
export const getPoolTopUsers = (id) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${url}&&type=poolTopUsers&&id=${id}`);
    dispatch(storePoolTopUsers(response.data));
  };
};

export const updatePoolAlerts = (id, data) => {
  return async (dispatch, getState) => {
    dispatch(storePoolDetails({}));
    const res1 = await axios.post(`${url}&&type=updatePoolAlerts&&id=${id}`, {
      data,
    });
    let res2 = await axios.get(`${url}&&type=poolDetails&&id=${id}`);
    dispatch(storePoolDetails(res2.data));
    if (res1.data[0] === 1) {
      dispatch(poolDekaDayCheck());
    }
  };
};

export const storeAntennaDetails = (details) => {
  return {
    type: "admin/storeAntennaDetails",
    payload: details,
  };
};
export const getAntennaDetails = (tag) => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/g_admin_signal?code=${key}&&type=antenna_details&&id=${tag}`
    );
    dispatch(storeAntennaDetails(response.data[0]));
  };
};

const storeModems = (all, online, unsupported) => {
  return {
    type: "admin/storeAllModems",
    payload: {
      all: all,
      //  online: online,
      unsupported: unsupported,
    },
  };
};
export const getModemsTags = () => {
  return async (dispatch, getState) => {
    let allModems = await axios.get(
      `/api/g_admin_signal?code=${key}&&type=hardware`
    );
    let unsupported = await axios.get(
      `/api/g_admin_signal?code=${key}&&type=unsupportedHardware`
    );
    // let onlineCheck = await axios.get(
    //   "https://gznendpointapi.azurewebsites.net/api/onlineCheckAll"
    // );
    dispatch(
      storeModems(
        allModems.data,
        // onlineCheck.data.modem,
        unsupported.data
      )
    );
  };
};

const poolDekaDayCheck = () => {
  return async (dispatch, getState) => {
    axios.get(`/api/sync?code=${key}&&type=poolDekaDayCheck`);
  };
};

export const setSignalDownload = (value) => {
  return {
    type: "admin/setSignalDownload",
    payload: value,
  };
};
export const setSignalUpload = (value) => {
  return {
    type: "admin/setSignalUpload",
    payload: value,
  };
};
export const storeSignalData = (value) => {
  return {
    type: "admin/setSignalData",
    payload: value,
  };
};
const updateTheBeep = (value) => {
  return {
    type: "admin/updateTheBeep",
    payload: value,
  };
};
const checkTheBeep = (newBeep) => {
  return async (dispatch, getState) => {
    if (newBeep !== getState().admin.signal.lastBeep) {
      dispatch(updateTheBeep(newBeep));
    }
  };
};
export const getSignal = (tag) => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/g_admin_signal_v2?code=${key}&&tag=${tag}`
    );
    dispatch(checkTheBeep(response.data.time));
    dispatch(storeSignalData(response.data));
  };
};

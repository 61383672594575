import "../../css/serviceUsageDetails.css";
import colorsArrayFunc from "../../components/colorsArray";
import Slider from "@mui/material/Slider";
import "../../css/nouislider.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Line,
  Label,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NewTable from "../../components/newTable/newTable";
import logo from "../../geuzenetlog.png";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

import {
  getServiceUsageStamps,
  getServiceUsage,
  setUsageSelection,
} from "../../store/actions/services";
import {
  selectUsageStamps,
  selectUsage,
  selectUsageAcu,
  selectUsageSelection,
} from "../../store/selectors/services";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;

function UsageSub2() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const headerRef = useRef();
  const { id } = useParams();
  const serviceId = id;
  const navigate = useNavigate();
  const serviceTimestamps = useSelector(selectUsageStamps);
  const selection = useSelector(selectUsageSelection);
  const serviceUsage = useSelector(selectUsage);
  const serviceAcuArray = useSelector(selectUsageAcu);
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const appUser = useSelector(selectAppUser);
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(
          setCategories(
            "Services",
            `Data usage - ${
              serviceTimestamps.service
                ? `${serviceTimestamps.service.id} ${serviceTimestamps.service.description}`
                : serviceId
            }`,
            "details"
          )
        );
      }
    };

    setMenu(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appUser, serviceTimestamps]);
  useEffect(() => {
    dispatch(getAppUser());
    if (Object.keys(serviceTimestamps).length === 0 && validUser(appUser)) {
      dispatch(getServiceUsageStamps(serviceId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation.menuSub, navigation.menuMain]);

  const customDateFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear();
    return `${y}-${m}-${d}`;
  };

  const customShortDateFormat = (date, locale) => {
    let m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear();
    return `${y}-${m}`;
  };

  const customTimeFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear(),
      t = date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
    return `${y}-${m}-${d} ${t}`;
  };
  const [muiSliderValue, setmuiSliderValue] = useState();
  let barsArray = [];
  let colorsArray = colorsArrayFunc(50);
  if (serviceUsage.wans) {
    for (let i = 0; i < serviceUsage.wans.length; i++) {
      barsArray.push(
        <Bar
          key={i}
          animationDuration={250}
          maxBarSize={150}
          name={serviceUsage.wans[i]}
          dataKey={serviceUsage.wans[i]}
          fill={colorsArray[i]}
          stackId={"a"}
        >
          <Label dataKey="label1" />
        </Bar>
      );
    }
  }

  useEffect(() => {
    if (serviceTimestamps[selection.scope]) {
      dispatch(
        getServiceUsage(
          serviceId,
          selection.scope,
          serviceTimestamps[selection.scope].sliderStart,
          serviceTimestamps[selection.scope].lastDate
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection.scope, serviceTimestamps[selection.scope]]);
  useEffect(() => {
    setmuiSliderValue([
      serviceTimestamps[selection.scope]
        ? serviceTimestamps[selection.scope].expArr.indexOf(
            serviceTimestamps[selection.scope].sliderStartUnix
          )
        : 0,
      serviceTimestamps[selection.scope]
        ? serviceTimestamps[selection.scope].expArr.length - 1
        : 0,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection.scope, serviceTimestamps[selection.scope]]);

  let lackOfData;
  if (Object.keys(serviceTimestamps).length > 0) {
    if (
      (selection.scope === "perDay") &
      !serviceTimestamps.perMonth.firstDateUnix
    ) {
      lackOfData = true;
    } else if (
      (selection.scope === "perDay") &
      !serviceTimestamps.perDay.firstDateUnix
    ) {
      lackOfData = true;
    } else if (
      (selection.scope === "perHour") &
      !serviceTimestamps.perHour.firstDateUnix
    ) {
      lackOfData = true;
    } else {
      lackOfData = false;
    }
  }

  const getMarks = (arr) => {
    let res = [];
    if (arr.length <= 6) {
      res = arr;
    } else {
      res.push(arr[0]);
      for (
        let i = Math.round(arr.length / 8);
        i <= arr.length;
        i = i + Math.round(arr.length / 8)
      ) {
        res.push(arr[i]);
      }
    }
    return res;
  };
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className="serviceUsage">
        <>
          <div className="topBar"></div>
          <div>
            <button
              className={
                serviceTimestamps.perMonth
                  ? "serviceUsageButton"
                  : "serviceUsageButton inactive"
              }
              onClick={(e) => {
                if (serviceTimestamps.perMonth.firstDateUnix) {
                  navigate(`/serviceDetails/${serviceId}`);
                }
              }}
            >
              Service details
            </button>
          </div>
          <div
            className={
              !serviceUsage.loading
                ? "serviceUsageSelectors"
                : "serviceUsageSelectors loadingPar"
            }
          >
            <div className="serviceUsageScopeSelector">
              <div className="serviceUsageSelectorDescription">Scope: </div>
              <select
                className={!serviceUsage.loading ? "" : "loading"}
                value={selection.scope}
                onChange={(e) => {
                  dispatch(setUsageSelection(e.target.value));
                }}
              >
                <option value="perMonth">Months</option>
                <option value="perDay">Days</option>
                <option value="perHour">Hours</option>
              </select>
            </div>
            {serviceTimestamps[selection.scope] ? (
              serviceTimestamps[selection.scope].firstDateUnix &&
              serviceTimestamps[selection.scope].lastDateUnix &&
              serviceTimestamps[selection.scope].sliderStartUnix ? (
                <div className={"sliderContainer"}>
                  <Slider
                    min={0}
                    max={serviceTimestamps[selection.scope].expArr.length - 1}
                    value={muiSliderValue}
                    onChange={(e) => setmuiSliderValue(e.target.value)}
                    onChangeCommitted={(e) => {
                      dispatch(
                        getServiceUsage(
                          serviceId,
                          selection.scope,
                          new Date(
                            serviceTimestamps[selection.scope].expArr[
                              muiSliderValue[0]
                            ]
                          ).toISOString(),
                          new Date(
                            serviceTimestamps[selection.scope].expArr[
                              muiSliderValue[1]
                            ]
                          ).toISOString()
                        )
                      );
                      console.log(
                        new Date(
                          serviceTimestamps[selection.scope].expArr[
                            muiSliderValue[0]
                          ]
                        ).toISOString()
                      );
                    }}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) =>
                      selection.scope === "perMonth"
                        ? customShortDateFormat(
                            new Date(
                              serviceTimestamps[selection.scope].expArr[value]
                            )
                          )
                        : selection.scope === "perDay"
                        ? customDateFormat(
                            new Date(
                              serviceTimestamps[selection.scope].expArr[value]
                            )
                          )
                        : selection.scope === "perHour"
                        ? customTimeFormat(
                            new Date(
                              serviceTimestamps[selection.scope].expArr[value]
                            )
                          )
                        : value
                    }
                    marks={getMarks(
                      serviceTimestamps[selection.scope].expArr
                    ).map((value) => {
                      return {
                        value:
                          serviceTimestamps[selection.scope].expArr.indexOf(
                            value
                          ),
                        label:
                          selection.scope === "perMonth"
                            ? customShortDateFormat(new Date(value))
                            : selection.scope === "perDay"
                            ? customDateFormat(new Date(value))
                            : selection.scope === "perHour"
                            ? customTimeFormat(new Date(value))
                            : "",
                      };
                    })}
                  />
                </div>
              ) : (
                <div> </div>
              )
            ) : (
              <div> </div>
            )}{" "}
          </div>
          {serviceUsage.usageArray.length > 0 &&
          serviceUsage.usageArray[0] !== "noData" ? (
            <div
              style={{
                width: "100%",
                height: "25vh",
                margin: "1vh auto auto auto",
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={300}
                  data={serviceUsage.usageArray.map((line) => {
                    return {
                      ...line,
                      name: line.name
                        ? selection.scope === "perMonth"
                          ? customShortDateFormat(
                              new Date(line.name),
                              undefined
                            )
                          : selection.scope === "perDay"
                          ? customDateFormat(new Date(line.name), undefined)
                          : selection.scope === "perHour"
                          ? customTimeFormat(new Date(line.name), undefined)
                          : null
                        : null,
                    };
                  })}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip cursor={false} />
                  <Legend
                    onClick={(e) => {
                      navigate(`/hardwareDetails/${e.dataKey}`);
                    }}
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: "40px" }}
                  />
                  {barsArray}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          ) : serviceUsage.usageArray[0] !== "noData" && !lackOfData ? (
            <img className="landingLogo" src={logo} alt="company logo" />
          ) : (
            <h1>No data available</h1>
          )}{" "}
          {serviceAcuArray.length > 0 &&
          selection.scope === "perDay" &&
          serviceUsage.usageArray[0] !== "noData" ? (
            <div
              style={{
                width: "100%",
                height: "25vh",
                margin: "1vh auto auto auto",
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={300}
                  // data={serviceAcuArray}
                  data={serviceAcuArray.map((line) => {
                    return {
                      ...line,
                      day: line.day
                        ? customDateFormat(new Date(line.day), undefined)
                        : null,
                    };
                  })}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="perHour" />
                  <YAxis />
                  <Tooltip cursor={false} />
                  <Legend
                    onClick={(e) => {
                      navigate(`/hardwareDetails/${e.dataKey}`);
                    }}
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: "40px" }}
                  />
                  <Bar
                    maxBarSize={150}
                    animationDuration={250}
                    dataKey="usage"
                    fill="#35647e"
                  />
                  <Line
                    animationDuration={250}
                    type="monotone"
                    dataKey="daySize"
                    stroke="#ff7300"
                    strokeWidth={3}
                    dot={false}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          ) : (
            " "
          )}
          {serviceUsage.tableData.length > 0 &&
          serviceUsage.usageArray[0] !== "noData" ? (
            <div
              className={
                selection.scope === "perDay"
                  ? "bigTableWrapper serviceDataUsageTableWrapper25"
                  : "bigTableWrapper serviceDataUsageTableWrapper45"
              }
              ref={headerRef}
            >
              <NewTable
                tabletype={selection.scope !== "perDay" ? "big" : "mid"}
                tit="serviceDataUsageTable"
                wrapperRef={headerRef}
                // data={serviceUsage.tableData}
                data={serviceUsage.tableData.map((line) => {
                  return {
                    ...line,
                    time: line.time
                      ? selection.scope === "perMonth"
                        ? customShortDateFormat(new Date(line.time), undefined)
                        : selection.scope === "perDay"
                        ? customDateFormat(new Date(line.time), undefined)
                        : selection.scope === "perHour"
                        ? customTimeFormat(new Date(line.time), undefined)
                        : null
                      : null,
                  };
                })}
                width={headerRef.current ? headerRef.current.clientWidth : 100}
              />
            </div>
          ) : (
            ""
          )}
        </>
      </div>
    );
  }
}

export default UsageSub2;

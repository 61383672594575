export const selectServicesList = (state) => state.services.services;
export const selectMaintenanceNetworks = (state) =>
  state.services.maintenance.networks;
export const selectMaintenanceInfoSources = (state) =>
  state.services.maintenance.infoSources;
export const selectMaintenanceInfo = (state) => state.services.maintenance.info;
export const selectServiceDetails = (state) => state.services.serviceDetails;
export const selectFilteredContacts = (state) =>
  state.services.filteredContacts;
export const selectKoffers = (state) => state.services.koffers;
export const selectUsageAlerts = (state) => state.services.usageAlerts;
export const selectUsageStamps = (state) => state.services.dataUsage.timestamps;
export const selectUsage = (state) => state.services.dataUsage.serviceUsage;
export const selectUsageAcu = (state) =>
  state.services.dataUsage.serviceAcuArray;
export const selectUsageSelection = (state) =>
  state.services.dataUsage.selection;

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "sync/storeExportTable":
      return { ...state, ...action.payload };
    case "sync/cleanExportTables":
      return initialState;
    default:
      return state;
  }
}

import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useEffect } from "react";
import { getAssetsData } from "../../store/actions/inventoryCheck";

import { selectInventoryCheck } from "../../store/selectors/inventoryCheck";
import NewTable from "../../components/newTable/newTable";
import logo from "../../geuzenetlog.png";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function InventoryCheck() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const inventoryCheck = useSelector(selectInventoryCheck);
  const headerRef = useRef();
  const appUser = useSelector(selectAppUser);
  const navigation = useSelector(selectNavigation);

  const dispatch = useDispatch();
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Admin", "Inventory check", "list"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (inventoryCheck.length === 0 && validUser(appUser)) {
        dispatch(getAssetsData());
      }
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return inventoryCheck.length > 0 ? (
      <div className="logsTables">
        <div className="topBar"></div>
        <div className="bigTableWrapper" ref={headerRef}>
          <NewTable
            wrapperRef={headerRef}
            data={inventoryCheck.map((line) => {
              console.log(line.controller);
              return {
                ...line,
                controller:
                  line.controller === "uisp" ? "uisp1" : line.controller,
              };
            })}
            tit="inventoryCheck"
            tabletype={"big"}
          />
        </div>
      </div>
    ) : (
      <img className="landingLogo" src={logo} alt="company logo" />
    );
  }
}

export default InventoryCheck;

import "../../css/HardwareMoveBulk.css";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewTable from "../../components/newTable/newTable";
import {
  AssetsSelect,
  AssetsTextInput,
  AssetsDateTimePicker,
} from "../../components/assetsInputs";
import logo from "../../geuzenetlog.png";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import {
  getHardwareProps,
  getAllHardware,
  bulkUpdateHardware,
} from "../../store/actions/assets";
import {
  selectHardwareProps,
  selectAllHardware,
} from "../../store/selectors/assets";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;

function HardwareMoveBulk() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const hwProps = useSelector(selectHardwareProps);
  const hardware = useSelector(selectAllHardware);
  const tagField = useRef();
  const tagInput = tagField.current;

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Assets", "Update devices", "details"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (
        (hwProps.length === 0 || hardware.length === 0) &&
        validUser(appUser)
      ) {
        dispatch(getHardwareProps());
        dispatch(getAllHardware());
      }
    };
    getItems();
    if (tagInput) {
      tagInput.focus();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, tagInput]);

  const [itemsArray, setItemsArray] = useState([]);
  const [arrayStatus, setarrayStatus] = useState(false);
  const [filter, setFilter] = useState("");
  const [saved, setSsaved] = useState(false);
  const [nextId, setNextId] = useState(0);
  const isBigScreen = useMediaQuery({ query: "(min-width: 1100px)" });
  const [itemTag, setItemTag] = useState("");

  const globalInit = {
    status: "- Select -",
    service_id: null,
    stock_id: null,
    time: new Date(Date.now()),
  };
  const [global, setGlobal] = useState(globalInit);
  const resetItem = () => {
    setGlobal(globalInit);
    setItemTag("");
    setFilter("");
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      if (event.target.name === "tagInput" && isTagValid()) {
        let obj = {
          ...global,
          id: nextId,
          tag: itemTag,
        };
        let newArray = [...itemsArray];
        setNextId(nextId + 1);
        if (hardware.find((dbItem) => dbItem.tag === obj.tag)) {
          const dbItem = hardware.find((dbItem) => dbItem.tag === obj.tag);
          obj.dbTag = true;
          obj.dbStatus = dbItem.status;
          obj.dbStatusId = dbItem.status_id;
          obj.dbLocation = dbItem.location;
        } else {
          obj.dbTag = false;
        }
        newArray.push(obj);
        setItemsArray(newArray);
        setItemTag("");
        arrayCheck(itemsArray);
      }
    }
  };
  const sortById = (a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };
  const isTagValid = () => {
    if (itemTag) {
      if (
        itemTag.length === 8 &&
        itemTag.slice(0, 3) === "GZN" &&
        !itemsArray.map((item) => item.tag).includes(itemTag) &&
        !isNaN(itemTag.slice(3, 8))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const serviceFilter = (filterValue) => {
    return hwProps.service.filter(
      (service) =>
        (service.description ? service.description : "  ")
          .toLowerCase()
          .includes(filterValue.toLowerCase()) ||
        service.id.includes(filterValue.toLowerCase())
    );
  };
  const stockFilter = (filterValue) => {
    return hwProps.stock.filter((stock) =>
      stock.name.toLowerCase().includes(filterValue.toLowerCase())
    );
  };
  useEffect(() => {
    setItemsArray(
      itemsArray.map((entry) => {
        return { ...entry, ...global };
      })
    );
    setSsaved(false); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global]);
  useEffect(() => {
    arrayCheck(
      itemsArray.map((entry) => {
        return { ...entry, ...global };
      })
    );
  }, [itemsArray, global]);
  const arrayCheck = (array) => {
    if (
      array.length > 0 &&
      array.filter((entry) => Object.values(entry).includes("- Select -"))
        .length === 0 &&
      array.filter((entry) => isNaN(entry.time.valueOf())).length === 0 &&
      array.filter((entry) => !entry.dbTag).length === 0 &&
      array.filter(
        (entry) => entry.status === "99" && [2, 3].includes(entry.dbStatusId)
      ).length === 0
    ) {
      setarrayStatus(true);
    } else {
      setarrayStatus(false);
    }
  };
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return Object.keys(hwProps).length !== 0 && hardware.length !== 0 ? (
      <div className="HardwareMoveBulk">
        <div className="topBar">
          {saved ? (
            <div className="savedNoteAUD">State and/or Location updated!!</div>
          ) : (
            <div className="savedNoteAUD" style={{ color: "white" }}>
              ||
            </div>
          )}
          <div className="mainButtonsAUD">
            {arrayStatus ? (
              <button
                onClick={(e) => {
                  dispatch(bulkUpdateHardware(itemsArray));
                  setarrayStatus(false);
                  setItemsArray([]);
                  setNextId(0);
                  resetItem();
                  setSsaved(true);
                  dispatch(getAllHardware());
                  tagInput.focus();
                }}
              >
                Save
              </button>
            ) : (
              <button className="disabled">Save</button>
            )}
            <button
              className="cancellButton"
              onClick={() => {
                setarrayStatus(false);
                setItemsArray([]);
                setNextId(0);
                resetItem();
                setSsaved(false);
                dispatch(getAllHardware());
                tagInput.focus();
              }}
            >
              Reset
            </button>
          </div>
        </div>
        <div
          className={isBigScreen ? "globalSelection wide" : "globalSelection"}
        >
          <div className="globalSelectionGroup">
            <AssetsSelect
              desc="Status:"
              value={global.status}
              options={[
                ...hwProps.status.map((status) => {
                  return { key: status.id, value: status.status };
                }),
                { key: "99", value: "- Unchanged -" },
              ]}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  status: value,
                  stock_id: value === "2" ? null : "- Select -",
                  service_id: value === "2" ? "- Select -" : null,
                });
                setFilter("");
              }}
            />
            <AssetsDateTimePicker
              desc="TIme:"
              value={global.time}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  time: value,
                });
              }}
            />
          </div>
          <div className="globalSelectionSpacer"></div>
          <div className="globalSelectionGroup">
            <AssetsTextInput
              desc="Location filter:"
              value={filter}
              onChangeFunc={(value) => {
                setFilter(value);
              }}
              disabled={global.status === "- Select -"}
            />
            <AssetsSelect
              desc="Location:"
              value={
                global.status === "- Select -"
                  ? ""
                  : global.status === "2"
                  ? global.service_id
                  : global.stock_id
              }
              options={
                global.status === "- Select -"
                  ? []
                  : global.status === "2"
                  ? serviceFilter(filter)
                      .sort(sortById)
                      .map((service) => {
                        return {
                          key: service.id,
                          value: `${service.id}: ${service.description}`,
                        };
                      })
                  : stockFilter(filter).map((stock) => {
                      return { key: stock.id, value: stock.name };
                    })
              }
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  stock_id: global.status === "2" ? null : value,
                  service_id: global.status !== "2" ? null : value,
                });
              }}
              disabled={global.status === "- Select -"}
            />
          </div>
        </div>
        <div className="notTop">
          <form className="scanFields">
            <div className="scanLineTag">
              <div>
                {"Tag id : "}
                <input
                  name="tagInput"
                  className="tagInput"
                  ref={tagField}
                  value={itemTag}
                  onKeyDown={handleEnter}
                  onChange={(e) => {
                    setItemTag(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        {itemsArray.length > 0 ? (
          <div className="smallTableWrapper" style={{ maxHeight: "52vh" }}>
            <NewTable
              data={itemsArray
                .slice(0)
                .map((line) => {
                  return (line = {
                    ...line,
                    button: (
                      <button
                        className="removeButtonAAD"
                        onClick={(e) => {
                          e.preventDefault();
                          let arr = itemsArray.filter(
                            (arrItem) => line.id !== arrItem.id
                          );
                          setItemsArray(arr);
                          arrayCheck(arr);
                        }}
                      >
                        Remove
                      </button>
                    ),
                  });
                })
                .map((line) => {
                  if (line.tag && !line.dbTag) {
                    return (line = {
                      ...line,
                      remark: `Tag ${line.tag} doesnt exist in the database`,
                    });
                  } else {
                    return line;
                  }
                })
                .map((line) => {
                  if (
                    line.status === "99" &&
                    [2, 3].includes(line.dbStatusId)
                  ) {
                    return (line = {
                      ...line,
                      remark: `${line.tag}<Unchanged> option not allowed (current status:${line.dbStatus})`,
                    });
                  } else {
                    return line;
                  }
                })
                .reverse()}
              tit="updateAssets"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      <img className="landingLogo" src={logo} alt="company logo" />
    );
  }
}
export default HardwareMoveBulk;

import "../../css/poolsDataUsage.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";
import NewTable from "../../components/newTable/newTable";
import logo from "../../geuzenetlog.png";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import { getDataUsagePPM } from "../../store/actions/admin";
import { selectPoolsPM } from "../../store/selectors/admin";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function PoolsUsage() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dataUsagePPM = useSelector(selectPoolsPM);
  const navigation = useSelector(selectNavigation);
  const appUser = useSelector(selectAppUser);
  const headerRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Admin", "Pools usage", "list"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (dataUsagePPM.length === 0 && validUser(appUser)) {
        dispatch(getDataUsagePPM());
      }
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div
        className={
          dataUsagePPM.length === 0 ? "DataUsage loading" : "DataUsage"
        }
      >
        <>
          <div className="topBar"></div>
          <div
            className="bigTableWrapper poolsDataUsageWrapper"
            ref={headerRef}
          >
            {dataUsagePPM.length > 0 ? (
              <NewTable
                tabletype={"big"}
                tit="poolsDataUsage"
                data={dataUsagePPM}
                width={headerRef.current ? headerRef.current.clientWidth : 100}
              />
            ) : (
              <img className="landingLogo" src={logo} alt="company logo" />
            )}
          </div>
        </>
      </div>
    );
  }
}

export default PoolsUsage;

import axios from "axios";
import { dateFormat, dateTimeFormat } from "../../components/dateConv";
import { reduxInit } from "../../store/actions/navigation";
const key = process.env.REACT_APP_API_KEY;
const maintenanceEndpoint = `services_maintenance?code=${key}`;
const maintenanceUrl = `/api/${maintenanceEndpoint}`;

export const servicesInit = () => {
  return {
    type: "services/init",
  };
};

const storeAllServices = (allServices) => {
  return {
    type: "services/storeAllServices",
    payload: allServices,
  };
};

export const getAllServices = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/services_overview?code=${key}`);
    dispatch(storeAllServices(response.data));
  };
};

const storeNetworks = (networks) => {
  return {
    type: "services/storeMaintenanceNetworks",
    payload: networks,
  };
};

export const getMaintenanceNetworks = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${maintenanceUrl}&&type=getNetworks`);
    dispatch(storeNetworks(response.data));
  };
};

const storeMaintenanceSources = (networks) => {
  return {
    type: "services/storeMaintenanceInfoSources",
    payload: networks,
  };
};

export const getMaintenanceSources = (network) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`${maintenanceUrl}&&type=${network}sources`);
    dispatch(storeMaintenanceSources(response.data));
  };
};

const storeMaintenanceInfo = (info) => {
  return {
    type: "services/storeMaintenanceInfo",
    payload: info,
  };
};

export const readMaintenanceInfo = (network, source) => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `${maintenanceUrl}&&type=${network}_read&&file=${source}`
    );
    dispatch(storeMaintenanceInfo(response.data));
  };
};

export const nullInfoSources = () => {
  return { type: "services/nullMaintenanceInfoSources" };
};

export const nullInfo = () => {
  return { type: "services/nullMaintenanceInfo" };
};

export const storeFilteredContacts = (data) => {
  return {
    type: "services/storeFilteredContacts",
    payload: data,
  };
};

export const getFilteredContacts = (
  date,
  reqCustomers,
  reqUsers,
  reqAgents
) => {
  let reqDate = new Date(date.slice(0, 4), date.slice(5, 7), date.slice(8, 10));
  if (reqDate) {
    return async (dispatch, getState) => {
      let response = await axios.get(
        `/api/services_contacts?code=${key}&&date=${reqDate.toISOString()}&&customer=${reqCustomers}&&user=${reqUsers}&&agent=${reqAgents}`
      );
      dispatch(storeFilteredContacts(response.data));
    };
  }
};

export const setContactsToNull = () => {
  return {
    type: "services/setFilteredContactsToNull",
  };
};

const storeAllKoffers = (data) => {
  return {
    type: "services/storeAllKoffers",
    payload: data,
  };
};

export const getAllKoffers = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/services_koffers?code=${key}&&type=getKoffers`
    );
    dispatch(storeAllKoffers(response.data));
  };
};

const storeUsageAlerts = (servicesAlerts) => {
  return {
    type: "services/storeServicesAlerts",
    payload: servicesAlerts,
  };
};

export const getServicesUsageAlerts = (month) => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/services_overuse?code=${key}&&type=overuse&&start=${month}`
    );
    dispatch(storeUsageAlerts(response.data));
  };
};

export const updateServiceUsageAlertDone = (cell) => {
  console.log(cell.value === "Yes" ? true : false);
  return async (dispatch, getState) => {
    const response = await axios.get(
      `/api/services_overuse?code=${key}&&type=overuseDoneUpdate&&id=${parseInt(
        cell.row.original.id
      )}&&done=${cell.value === "Yes" ? false : true}`
    );
    dispatch(storeUsageAlerts(response.data));
  };
};

export const storeServiceDetails = (details) => {
  return {
    type: "services/storeServiceDetails",
    payload: details,
  };
};

export const getServiceDetails = (type, id) => {
  if (type === "Overview") {
    return async (dispatch, getState) => {
      let response = await axios.get(
        `/api/services_details?code=${key}&&id=${id}`
      );
      response.data.assetsHistory.forEach((assetsHistoryEntry) => {
        if (assetsHistoryEntry.start) {
          assetsHistoryEntry.start = dateTimeFormat(assetsHistoryEntry.start);
        }
        if (assetsHistoryEntry.end) {
          assetsHistoryEntry.end = dateTimeFormat(assetsHistoryEntry.end);
        }
      });
      response.data.fullHistory.forEach((histEntry) => {
        if (histEntry.start) {
          histEntry.start = dateFormat(histEntry.start);
        }
        if (histEntry.implementation) {
          histEntry.implementation = dateFormat(histEntry.implementation);
        }
      });
      dispatch(storeServiceDetails(response.data));
    };
  }
};

const storeServiceUsageStamps = (serviceTimestamps) => {
  let { perMonth, perDay, perHour, service } = serviceTimestamps;
  let monthsArray = [];
  let daysArray = [];
  let hoursArray = [];
  const makeArray = (array, scope, increment) => {
    for (
      let i = new Date(scope.firstDate).valueOf();
      i <= new Date(scope.lastDate).valueOf();
      i = increment(i)
    ) {
      array.push(new Date(i).valueOf());
    }
  };
  makeArray(
    monthsArray,
    perMonth,
    (value) =>
      new Date(new Date(value).setMonth(new Date(value).getMonth() + 1))
  );
  makeArray(
    daysArray,
    perDay,
    (value) => new Date(new Date(value).setDate(new Date(value).getDate() + 1))
  );
  makeArray(
    hoursArray,
    perHour,
    (value) =>
      new Date(new Date(value).setHours(new Date(value).getHours() + 1))
  );
  const getUnix = (date) => (date ? new Date(date).valueOf() : null);
  let daysSliderStart = new Date(
    new Date(perDay.lastDate).getFullYear(),
    new Date(perDay.lastDate).getMonth(),
    1
  ).toISOString();
  let hoursSliderStart = new Date(
    new Date(perHour.lastDate).getFullYear(),
    new Date(perHour.lastDate).getMonth(),
    new Date(perHour.lastDate).getDate()
  ).toISOString();
  if (hoursSliderStart === perDay.lastDate) {
    hoursSliderStart = new Date(
      new Date(perHour.lastDate).getFullYear(),
      new Date(perHour.lastDate).getMonth(),
      new Date(perHour.lastDate).getDate() - 1
    ).toISOString();
  }
  let payload = {
    perMonth: {
      ...perMonth,
      sliderStart: perMonth.firstDate,
      firstDateUnix: getUnix(perMonth.firstDate),
      sliderStartUnix: getUnix(perMonth.firstDate),
      lastDateUnix: getUnix(perMonth.lastDate),
      expArr: monthsArray,
    },
    perDay: {
      ...perDay,
      sliderStart: daysSliderStart,
      firstDateUnix: getUnix(perDay.firstDate),
      sliderStartUnix: getUnix(daysSliderStart),
      lastDateUnix: getUnix(perDay.lastDate),
      expArr: daysArray,
    },
    perHour: {
      ...perHour,
      sliderStart: hoursSliderStart,
      firstDateUnix: getUnix(perHour.firstDate),
      sliderStartUnix: getUnix(hoursSliderStart),
      lastDateUnix: getUnix(perHour.lastDate),
      expArr: hoursArray,
    },
    service: service,
  };
  return {
    type: "services/storeServiceUsageTimestamps",
    payload: payload,
  };
};

export const getServiceUsageStamps = (id) => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/services_usage?code=${key}&&type=serviceTimestamps&&id=${id}`
    );
    dispatch(storeServiceUsageStamps(response.data));
    dispatch(setUsageSelection("perMonth"));
  };
};

const storeServiceAcuArray = (serviceUsage) => {
  return {
    type: "services/storeUsageAcuArray",
    payload: serviceUsage,
  };
};
const storeServiceUsage = (serviceUsage) => {
  return {
    type: "services/storeServiceUsage",
    payload: serviceUsage,
  };
};

export const getServiceUsage = (service, scope, start, end) => {
  return async (dispatch, getState) => {
    let responseSU = await axios.get(
      `/api/services_usage?code=${key}&&type=serviceUsage&&id=${service}&&scope=${scope}&&start=${start}&&end=${end}`
    );
    if (scope === "perDay") {
      let responseSAU = await axios.get(
        `/api/services_usage?code=${key}&&type=serviceUsageAcu&&id=${service}&&start=${start}&&end=${end}`
      );
      dispatch(storeServiceAcuArray(responseSAU.data));
      console.log(responseSAU.data);
    }
    if (Object.keys(responseSU.data).length > 0) {
      dispatch(storeServiceUsage(responseSU.data));
    }
  };
};

export const setUsageSelection = (scope) => {
  return {
    type: "services/setUsageSelection",
    payload: { scope: scope },
  };
};
export const newTicketReq = (data, id, confluenceId) => {
  console.log(confluenceId);
  return async (dispatch, getState) => {
    dispatch(servicesInit());
    let response = await axios.post(
      `/api/services_details?code=${key}&&type=newTicket`,
      { data: { ...data, confluenceId: confluenceId } }
    );
    console.log(response);

    dispatch(getServiceDetails("Overview", id));
  };
};

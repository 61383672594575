import "../../css/SimcardDetails.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../../geuzenetlog.png";
import NewTable from "../../components/newTable/newTable";
import { useMediaQuery } from "react-responsive";
import {
  AssetsSelect,
  AssetsTextInput,
  AssetsDateTimePicker,
  AssetsCheckbox,
} from "../../components/assetsInputs";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import {
  getSimProps,
  getAllSimcards,
  getSimDetails,
  storeAssetDetails,
  updateSimDetails,
} from "../../store/actions/assets";
import {
  selectSimProps,
  selectAllSimcards,
  selectAssetDetails,
} from "../../store/selectors/assets";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
const checkObjectLength = (obj) => Object.keys(obj).length;
function SimcardDetails() {
  const appUser = useSelector(selectAppUser);
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerRef = useRef();
  const navigation = useSelector(selectNavigation);
  const details = useSelector(selectAssetDetails);
  const scProps = useSelector(selectSimProps);
  const { tag } = useParams();
  const dispatch = useDispatch();
  const [item, setItem] = useState({});
  const [filter, setFilter] = useState("");
  const isBigScreen2 = useMediaQuery({ query: "(min-width: 983px)" });
  const simcards = useSelector(selectAllSimcards);
  const customTimeFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear(),
      t = date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
    return `${y}-${m}-${d} ${t}`;
  };

  const filterModems = (value) => {
    return scProps.modem.filter(
      (modem) =>
        modem.tag.toLowerCase().includes(value.toLowerCase()) ||
        modem.location.toLowerCase().includes(value.toLowerCase())
    );
  };
  const findModem = (value) => {
    return scProps.modem.find((modem) => modem.tag === value);
  };
  const oldSimId = (modem) => {
    if (modem) {
      if (findModem(modem)) {
        if (findModem(modem).simcard_id) {
          return findModem(modem).simcard_id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const [info, setInfo] = useState(".");
  const [saved, setSsaved] = useState(false);
  const [detailsEdit, setDetailsEdit] = useState(false);

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Assets", `Sim ${tag} details`, "details"));
      }
    };
    setMenu(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    setDetailsEdit(false);
    const getItems = () => {
      if (
        (checkObjectLength(scProps) === 0 ||
          checkObjectLength(details) === 0 ||
          simcards.length === 0) &&
        validUser(appUser)
      ) {
        dispatch(getAllSimcards());
        dispatch(getSimProps());
        dispatch(getSimDetails(tag));
      }
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain]);

  const currentSimCheck = () => item.id === item.oldSim;
  const checkItem = (item) => {
    if (item) {
      if (currentSimCheck() && checkObjectLength(item) > 0) {
        setItem({ ...item, oldSim: null });
      }
      if (!currentSimCheck() && item.oldSim) {
        const simLookup = () =>
          simcards.find((sim) => parseInt(sim.id) === parseInt(item.oldSim));
        setInfo(
          `Modem ${
            item.modem
          } alredy have simcard assigned. That simcard (ID: ${
            simLookup().id
          }, ICC: ${simLookup().icc}) will be moved to Stock -> Unknown`
        );
      } else {
        setInfo(".");
      }
    }
  };
  useEffect(() => {
    checkItem(item); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  const resetItem = () => {
    setItem({
      ...item,
      id: details.id,
      icc: details.icc,
      msisdn: details.msisdn,
      subscription: details.subscription,
      network: details.network_id,
      pin: details.pin,
      puk: details.puk,
      oldModem: details.modem,
      oldSim: null,
      modem: details.modem,
      inModem: details.modem ? true : false,
      status: details.status,
      stock_id: details.stock_id,
      time: new Date(Date.now()),
    });
  };
  if (
    checkObjectLength(scProps) > 0 &&
    checkObjectLength(details) > 0 &&
    !item.id
  ) {
    dispatch(getSimDetails(tag));
    resetItem();
  }
  const dataLine = (desc, value, onClick) => {
    return (
      <div className="dasLine">
        <div className="dasDesc">{desc}</div>
        <div className="dasValue" onClick={onClick}>
          {value}
        </div>
      </div>
    );
  };
  const modelProps = (prop) =>
    scProps.network && item.network
      ? scProps.network.find((network) => network.id === item.network)[prop]
      : "";
  const poolProps = (prop) =>
    scProps.subscription &&
    scProps.pool &&
    subscriptionProps(item.subscription, "pool_id")
      ? scProps.pool.find(
          (pool) => pool.id === subscriptionProps(item.subscription, "pool_id")
        )[prop]
      : "";

  const subscriptionProps = (id, prop) =>
    scProps.subscription && id
      ? scProps.subscription.find((subscription) => subscription.id === id)
        ? scProps.subscription.find((subscription) => subscription.id === id)[
            prop
          ]
        : ""
      : "";
  const statusProps = (id, prop) =>
    scProps.status && id
      ? scProps.status.find((status) => status.id === id)[prop]
      : "";
  const stringCut = (string, stringLength) => {
    const allParts = string.split(" ");
    let newIndex = 0;
    let newArr = [];
    for (let part of allParts) {
      if (
        `${newArr[newIndex] ? newArr[newIndex] : ""}${part} `.length >
        stringLength
      ) {
        newIndex++;
      }
      newArr[newIndex] = `${newArr[newIndex] ? newArr[newIndex] : ""}${part} `;
    }
    return newArr;
  };
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return checkObjectLength(scProps) > 0 && checkObjectLength(item) > 0 ? (
      <div className="SimcardDetailsWrapper">
        <div className="simDetails">
          <div className="SimcardDetails">
            <div className="topBar">
              {saved ? (
                <div className="savedNoteAUD">
                  State and/or Location updated!!
                </div>
              ) : info === "." ? (
                <div className="savedNoteAUD" style={{ color: "white" }}>
                  ||
                </div>
              ) : (
                <div className="savedNoteAUD" style={{ color: "#f9323f" }}>
                  {info}
                </div>
              )}
              {detailsEdit ? (
                <div className="mainButtonsAAD">
                  {!Object.values(item).includes("- Select -") ? (
                    <button
                      className="save"
                      onClick={(e) => {
                        dispatch(storeAssetDetails({}));
                        setItem({});
                        setDetailsEdit(false);
                        setItem({});
                        dispatch(updateSimDetails(tag, item));
                        setItem({});
                        dispatch(getAllSimcards());
                        setSsaved(true);
                      }}
                    >
                      Save
                    </button>
                  ) : (
                    <button className="disabledANS">Save</button>
                  )}
                  <button
                    className="cancel"
                    onClick={(e) => {
                      setItem({});
                      setFilter("");
                      setDetailsEdit(false);
                      setItem({});
                      dispatch(getSimDetails(tag));
                      resetItem();
                      setSsaved(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : simcards.length > 0 ? (
                <div className="mainButtonsAAD">
                  <button
                    className="edit"
                    onClick={(e) => {
                      setFilter("");
                      setSsaved(false);
                      setDetailsEdit(true);
                      resetItem();
                    }}
                  >
                    Edit
                  </button>
                  <button className="disabled" style={{ cursor: "default" }}>
                    {" "}
                  </button>
                </div>
              ) : (
                " "
              )}
            </div>
            <div
              className={
                isBigScreen2 ? "globalSelection wide" : "globalSelection"
              }
            >
              <div className="globalSelectionGroup">
                {dataLine("Id:", item.id)}
                {dataLine("ICC:", item.icc)}
                {dataLine("MSISDN:", item.msisdn)}
                {dataLine("Country:", modelProps("country"))}
                {dataLine("Network:", modelProps("operator"))}
                {dataLine("Pin:", item.pin)}
                {dataLine("Puk:", item.puk)}
                {dataLine()}
              </div>
              <div className="globalSelectionSpacer"></div>
              <div className="globalSelectionGroup">
                {detailsEdit ? (
                  <AssetsSelect
                    desc="Subscription:"
                    value={item.subscription}
                    options={scProps.subscription
                      .filter(
                        (subscriptionByNetwork) =>
                          subscriptionByNetwork.network_id === item.network
                      )
                      .map((subscription) => {
                        return {
                          key: subscription.id,
                          value:
                            subscription.actual === 0
                              ? `${subscription.name} (N/A)`
                              : subscription.name,
                        };
                      })}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        subscription: value,
                      });
                    }}
                  />
                ) : (
                  dataLine(
                    "Subscription:",
                    subscriptionProps(item.subscription, "name")
                  )
                )}
                {dataLine("Pool:", poolProps("name"))}
                {dataLine(
                  "Bundle size:",
                  subscriptionProps(item.subscription, "bundle_size")
                )}
                {detailsEdit ? (
                  <AssetsSelect
                    desc="Sim status:"
                    value={item.status}
                    options={scProps.status.map((status) => {
                      return { key: status.id, value: status.status };
                    })}
                    onChangeFunc={(value) =>
                      setItem({
                        ...item,
                        status: value,
                      })
                    }
                  />
                ) : (
                  dataLine("Sim status:", statusProps(item.status, "status"))
                )}
                {detailsEdit ? (
                  <AssetsCheckbox
                    desc={"In modem:"}
                    value={item.inModem}
                    onChangeFunc={(value) => {
                      setFilter("");
                      setItem({
                        ...item,
                        inModem: item.inModem ? false : true,
                        stock_id: item.inModem
                          ? details.stock_id
                            ? details.stock_id
                            : "- Select -"
                          : null,
                        modem: item.inModem
                          ? null
                          : details.modem
                          ? details.modem
                          : "- Select -",
                        oldSim: item.inModem ? null : oldSimId(value),
                      });
                    }}
                  />
                ) : (
                  dataLine(
                    "Location:",
                    stringCut(details.fullHistory[0].location, 40)[0]
                  )
                )}
                {detailsEdit ? (
                  <AssetsTextInput
                    desc={item.inModem ? "Modems filter:" : "Stock filter"}
                    value={filter}
                    onChangeFunc={(value) => {
                      setFilter(value);
                      if (
                        item.inModem &&
                        !filterModems(value)
                          .map((modem) => modem.tag)
                          .includes(item.modem)
                      ) {
                        setItem({ ...item, modem: "- Select -" });
                      }
                      if (
                        !item.inModem &&
                        !scProps.stock
                          .filter((stock) =>
                            stock.name
                              .toLowerCase()
                              .includes(filter.toLowerCase())
                          )
                          .map((stock) => stock.id)
                          .includes(item.stock_id)
                      ) {
                        setItem({ ...item, stock_id: "- Select -" });
                      }
                    }}
                  />
                ) : (
                  dataLine(
                    "",
                    stringCut(details.fullHistory[0].location, 40)[1]
                  )
                )}
                {detailsEdit ? (
                  item.inModem ? (
                    <AssetsSelect
                      desc="Modem:"
                      value={item.modem}
                      options={filterModems(filter).map((modem) => {
                        return { key: modem.tag, value: modem.location };
                      })}
                      onChangeFunc={(value) => {
                        setItem({
                          ...item,
                          oldSim: oldSimId(value),
                          modem: value,
                        });
                      }}
                    />
                  ) : (
                    <AssetsSelect
                      desc="Stock:"
                      value={item.stock_id}
                      options={scProps.stock
                        .filter((stock) =>
                          stock.name
                            .toLowerCase()
                            .includes(filter.toLowerCase())
                        )
                        .map((stock) => {
                          return { key: stock.id, value: stock.name };
                        })}
                      onChangeFunc={(value) => {
                        setItem({
                          ...item,
                          oldSim: null,
                          stock_id: value,
                        });
                      }}
                    />
                  )
                ) : (
                  dataLine(
                    "",
                    stringCut(details.fullHistory[0].location, 40)[2]
                  )
                )}
                {detailsEdit ? (
                  <AssetsDateTimePicker
                    desc="TIme:"
                    value={item.time}
                    onChangeFunc={(value) => {
                      setItem({
                        ...item,
                        time: value,
                      });
                    }}
                  />
                ) : (
                  dataLine(
                    "",
                    stringCut(details.fullHistory[0].location, 40)[3]
                  )
                )}
              </div>
            </div>
            <>
              <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>
                Asset history
              </h2>
              <div className="smallTableWrapper" ref={headerRef}>
                <NewTable
                  tabletype={"small"}
                  tit={"simHistory"}
                  data={details.fullHistory.map((line) => {
                    return {
                      ...line,
                      timestamp: customTimeFormat(
                        new Date(line.timestamp),
                        undefined
                      ),
                    };
                  })}
                  width={
                    headerRef.current ? headerRef.current.clientWidth : 100
                  }
                />
              </div>
            </>
          </div>
        </div>
      </div>
    ) : (
      <img className="landingLogo" src={logo} alt="company logo" />
    );
  }
}

export default SimcardDetails;

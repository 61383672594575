import "../css/table.css";
import React, { useEffect, useState } from "react";
import { getSelectedKoffers } from "../store/actions/koffers";
import { selectKoffers } from "../store/selectors/koffers";

import { useDispatch, useSelector } from "react-redux";

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import kofferIco from "../kofferIco.png";

const containerStyle = {
  width: "100%",
  height: "600px",
  boxShadow: "-5px -5px 10px 5px rgba(0, 0, 0, 0.2)",
};

function KoffersMap() {
  const dispatch = useDispatch();
  const koffers = useSelector(selectKoffers);
  const [activeMarker, setActiveMarker] = useState();

  useEffect(() => {
    if (koffers.all.length === 0) {
      dispatch(getSelectedKoffers());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [koffers.all]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_mapApi, // ,
    // ...otherOptions
  });
  var Spiderfy = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

  const [oms, setOms] = useState(null);
  const onLoad = (map) => {
    let newOms;
    function initOMS() {
      newOms = new Spiderfy.OverlappingMarkerSpiderfier(map, {
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        nearbyDistance: 0,
        circleFootSeparation: 40,
        bubblingMouseEvents: false,
      });
    }
    initOMS();
    setOms(newOms);
  };
  useEffect(() => {
    if (oms && koffers.selected.length > 0) {
      oms.clearMarkers();

      koffers.selected.map((point) => {
        const newMarker = window.google
          ? new window.google.maps.Marker()
          : null;
        newMarker.setPosition({
          lat: parseFloat(point.latitude),
          lng: parseFloat(point.longitude),
        });
        newMarker.setIcon(kofferIco);
        newMarker.setTitle(`${point.customer_name}`);
        // }
        let infowindow = new window.google.maps.InfoWindow({
          content: `<div class="mapInfoWindow">
                      <p style="margin:6px;font-size:14px;"> ${point.customer_name} </p>
                      <p style="margin:6px;"><span style="font-size:10px;vertical-align: 2px;"> &#128222 </span><span style="font-size:14px;"> 088 248 1000 </span></p>
                    </div>`,
        });
        window.google.maps.event.clearListeners(newMarker, "click");
        if (activeMarker === point.id) {
          infowindow.open(oms.map, newMarker);
          setActiveMarker(point.id);
          console.log(newMarker);
        }
        window.google.maps.event.addListener(newMarker, "click", (e) => {
          setActiveMarker(point.id);
          infowindow.open(oms.map, newMarker);
          console.log(newMarker);
        });
        window.google.maps.event.addListener(oms.map, "click", (e) => {
          setActiveMarker(null);
          infowindow.close(oms.map, newMarker);
        });
        oms.addMarker(newMarker);
        return newMarker;
      });
    }
    if (oms) {
      console.log(activeMarker);
      if (oms.getMarkers().length === 0) {
        oms.map.setCenter({ lat: 52.29808970000001, lng: 4.707587 });
      } else if (oms.getMarkers().length === 1) {
        console.log(oms.getMarkers()[0].position.lat());
        oms.map.setCenter({
          lat: oms.getMarkers()[0].position.lat(),
          lng: oms.getMarkers()[0].position.lng(),
        });
      } else if (oms.getMarkers().length > 1 && !activeMarker) {
        let bounds = new window.google.maps.LatLngBounds();
        oms.getMarkers().map((marker) => {
          return bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng(),
          });
        });
        oms.map.fitBounds(bounds);
      }
    }
  }, [koffers, activeMarker, oms]);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          zoom={15}
        ></GoogleMap>
      ) : (
        ""
      )}
    </>
  );
}

export default KoffersMap;

import "../css/table.css";
// import "npm-overlapping-marker-spiderfier";
import React, { useEffect, useState, useRef } from "react";
import logo from "../geuzenetlog.png";

import {
  AssetsTextInput,
  AssetsTextInput2,
  AssetsSelect2,
  AssetsCheckbox3,
  AssetsTextMultiInput,
} from "../components/assetsInputs";
import {
  cleanCamp,
  getOffer,
  confirmOffer,
  storeOffer,
} from "../store/actions/camp";
import { selectCamp } from "../store/selectors/camp";
import { selectNavigation } from "../store/selectors/navigation";
import { useDispatch, useSelector } from "react-redux";
import carGif from "../890-f-1-car.gif";

function Camp5g() {
  useEffect(() => {
    dispatch(cleanCamp()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const camp = useSelector(selectCamp);
  const campLoading = useSelector(selectNavigation).campLoading;
  const [serviceId, setServiceId] = useState("");
  const [secCode, setSecCode] = useState("");
  const [contactSalut, setContactSalut] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [period, setPeriod] = useState(0);
  const [redund, setRedund] = useState(false);
  const [remarks, setRemarks] = useState("");
  const getOffset = (timeZone, date) => {
    const timeZoneUTC = "UTC";
    const utcDate = new Date(date.toLocaleString("en-US", { timeZoneUTC }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
    return tzDate.getTime() - utcDate.getTime();
  };
  const addDays = (value, days) => {
    let date = new Date(value.valueOf() + getOffset("Europe/Berlin", value));
    date.setDate(date.getDate() + days);
    return new Date(date.valueOf() - getOffset("Europe/Berlin", date));
  };
  const topRef = useRef();
  useEffect(() => {
    if (
      !camp.period &&
      !camp.contactSalut &&
      !camp.contactFirstName &&
      !camp.contactLastName &&
      !camp.contactEmail &&
      !camp.contactPhone
    ) {
      setContactSalut(
        ["heer", "Herr", "Mr."].includes(camp.salutation)
          ? "heer"
          : ["mevrouw", "Frau", "Ms."].includes(camp.salutation)
          ? "mevrouw"
          : "-"
      );
      setContactFirstName(camp.first_name);
      setContactLastName(camp.last_name);
      setContactEmail(camp.email);
      setContactPhone(
        camp.direct_phone ? camp.direct_phone : camp.mobile_phone
      ); // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [
    camp.salutation,
    camp.first_name,
    camp.last_name,
    camp.email,
    camp.direct_phone,
    camp.mobile_phone,
  ]);
  useEffect(() => {
    setContactPhone(camp.direct_phone ? camp.direct_phone : camp.mobile_phone); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camp.direct_phone, camp.mobile_phone]);
  console.log(camp.loading);
  const customAddedDateFormat = (date, locale, years) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear();
    return `${d}-${m}-${y + years}`;
  };

  const executeScroll = (topRef) => {
    setTimeout(() => topRef.current.scrollIntoView(), 500);
    // if (topRef.current) {
    //   topRef.current.scrollIntoView();
    // }
  };
  const getDays = (year, month) => {
    return new Date(year, month, 0).getDate();
  };
  const getDutchDate = (date) => {
    return new Date(date.getTime() + getOffset("Europe/Amsterdam", date));
  };
  const getMonthsDiff = (startingDate, endingDate) => {
    let daysDiff =
      getDutchDate(endingDate).getDate() - getDutchDate(startingDate).getDate();
    let monthsDiff =
      getDutchDate(endingDate).getMonth() -
      getDutchDate(startingDate).getMonth();
    let endingDateMonthLength = getDays(
      getDutchDate(endingDate).getFullYear(),
      getDutchDate(endingDate).getMonth()
    );
    if (daysDiff !== 0) {
      monthsDiff = monthsDiff + daysDiff / endingDateMonthLength;
    }
    let yearsDiff =
      getDutchDate(endingDate).getFullYear() -
      getDutchDate(startingDate).getFullYear();
    return Math.round((yearsDiff * 12 + monthsDiff) * 100) / 100;
  };
  console.log(
    "oko",
    camp.earliest_end_date_t
      ? new Date().setMonth(
          addDays(new Date(), -1).getMonth() +
            Math.round(
              getMonthsDiff(
                addDays(new Date(), -1),
                new Date(camp.earliest_end_date_t)
              )
            )
        )
      : ""
  );
  let today = new Date();
  return campLoading ? (
    <>
      {/* <div ref={topRef}></div> */}
      <div className="campLoading">
        {/* <div ref={topRef}>dupa</div> */}
        <img
          // ref={topRef}
          className="landingLogo ll"
          src={logo}
          alt="company logo"
        />
      </div>
      {/* {executeScroll()} */}
    </>
  ) : ["init", "notInCampaign", "wrongCode", "campIsDone"].includes(camp) ? (
    <>
      <div className="offerHeader initHeader">
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="contactInputs initInputs">
          <AssetsTextInput
            desc={"Service ID : "}
            onChangeFunc={(e) => setServiceId(e)}
            value={serviceId}
          />
          <AssetsTextInput
            desc={"Security code : "}
            onChangeFunc={(e) => setSecCode(e)}
            value={secCode}
          />
          <button
            className={
              isNaN(serviceId) || serviceId.length !== 4 || secCode.length !== 6
                ? "campConfButtonInactive"
                : "campConfButton"
              // : "sdbInactive"
            }
            onClick={(e) => {
              if (
                isNaN(serviceId) ||
                serviceId.length !== 4 ||
                secCode.length !== 6
              ) {
                return;
              } else {
                dispatch(getOffer({ serviceId: serviceId, secCode: secCode }));
              }
            }}
          >
            Haal het aanbod op
          </button>
        </div>
      </div>
      {camp === "notInCampaign" || camp === "wrongCode" ? (
        <div className="">
          <div className="errorRes">
            <strong className="">
              Het Service ID of de Security Code is incorrect.
            </strong>
          </div>
          <div className="spacer"></div>
        </div>
      ) : camp === "campIsDone" ? (
        <div className="">
          <div className="errorRes">
            <strong className="">Deze upgrade is al besteld.</strong>
          </div>
          <div className="spacer"></div>
        </div>
      ) : (
        ""
      )}
    </>
  ) : camp.completed === "completed" ? (
    <>
      <div ref={topRef}></div>
      <div className="offerHeader">
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="carAnim">
          <img src={carGif} alt="speeding car gif" />
        </div>
        <p>
          <div className="spacer"></div>
          <strong className="campThanksHeader">
            Hartelijk dank voor je bestelling!
          </strong>
          <div className="spacer"></div>
        </p>
        <p>
          <div className="spacer"></div>
          <div>
            We gaan er direct mee aan de slag! Binnenkort nemen we contact met
            je op om een afspraak in te plannen. Hieronder hebben we alles nog
            even voor je op een rijtje gezet. Als je vragen hebt of als er iets
            niet klopt, kun je ons bereiken op 088 248 1000 of via{" "}
            <a href={`mailto:planning@geuzenet.nl`}>planning@geuzenet.nl</a>.
          </div>
          <div className="spacer"></div>
        </p>
      </div>
      <div className="spacer"></div>
      <div className="offerHeader  offerComleted">
        <div className="offerTableLine">
          <div>Klantnaam</div>
          <div>{camp.customerName}</div>
        </div>
        <div className="offerTableLine">
          <div>Gebruiker</div>
          <div>{camp.userName}</div>
        </div>
        <div className="offerTableLine">
          <div>Adres</div>
          <div>{camp.userAddress}</div>
        </div>
        <div className="offerTableLine">
          <div>Contract nummer</div>
          <div>{camp.contract_id}</div>
        </div>
        <div className="offerTableLine">
          <div>Service ID</div>
          <div>{camp.service_id}</div>
        </div>
      </div>
      <div className="offerHeader">
        <p>
          <div className="spacer"></div>
          <strong>Dit is wat we gaan doen.</strong>
          <div className="spacer"></div>
        </p>
        <ul>
          <li>
            {`${
              camp.bullet1 === 1
                ? "Er wordt één 5G-unit gewisseld."
                : camp.bullet1 === 2
                ? "Er worden twee 5G-units gewisseld."
                : ""
            }`}
          </li>
          <li>
            {`${
              camp.bullet2 === 1
                ? "De verbinding bevindt zich in Nederland."
                : camp.bullet2 === 2
                ? "De verbinding bevindt zich in het buitenland."
                : ""
            }`}
          </li>
          <li>
            {`${
              camp.bullet3 === 1
                ? "Een installateur van Geuzenet voert de wissel uit. "
                : camp.bullet3 === 2
                ? "Wij sturen de apparatuur toe om zelf te wisselen en begeleiden het op afstand."
                : ""
            }`}
          </li>
          {camp.bullet4 === 1 ? (
            <li>
              {`Op jouw locatie is mogelijk een hoogwerker nodig en we overleggen de meerkosten daarvan vooraf.`}
            </li>
          ) : (
            ""
          )}
          {redund ? <li>{`Je verbinding wordt redundant gemaakt.`}</li> : ""}
        </ul>
        <div className="spacer"></div>

        {!redund ? (
          <div>
            <strong>{`Je contract wordt verlengd tot en met ${customAddedDateFormat(
              new Date(camp.earliest_end_date_t).valueOf() >
                new Date().valueOf()
                ? addDays(
                    new Date(
                      new Date().setMonth(
                        addDays(new Date(), -1).getMonth() +
                          Math.round(
                            getMonthsDiff(
                              addDays(new Date(), -1),
                              new Date(camp.earliest_end_date_t)
                            )
                          )
                      )
                    ),
                    -1
                  )
                : addDays(new Date(), -1),
              undefined,
              period === 12 ? 1 : period === 24 ? 2 : 3
            )} en je betaalt:`}</strong>
            <ul className="campCostBullet">
              <li>
                {`een eenmalige vergoeding van €${
                  period === 12
                    ? camp.amount1
                    : period === 24
                    ? camp.amount2
                    : camp.amount3
                } voor de 5G-upgrade`}
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <strong>{`Je contract wordt verlengd tot en met ${customAddedDateFormat(
              new Date(camp.earliest_end_date_t).valueOf() >
                new Date().valueOf()
                ? new Date(camp.earliest_end_date_t)
                : addDays(new Date(), -1),
              undefined,
              period === 12 ? 1 : period === 24 ? 2 : 3
            )} en je betaalt:`}</strong>
            <ul className="campCostBullet">
              <li>
                {`een eenmalige vergoeding van €${
                  period === 12
                    ? camp.amount1
                    : period === 24
                    ? camp.amount2
                    : camp.amount3
                } voor de 5G-upgrade`}
              </li>
              <li>
                {`een eenmalige vergoeding van €250 en maandelijks €39 voor de redundantie`}
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="offerHeader">
        <p>
          <div className="spacer"></div>
          <strong>We hebben de volgende contactgegevens van je.</strong>
          <div className="spacer"></div>
        </p>
        <div className="offerTableLine">
          <div>Aanhef</div>
          <div>{contactSalut ? contactSalut : camp.salutation}</div>
        </div>
        <div className="offerTableLine">
          <div>Voornaam</div>
          <div>{contactFirstName ? contactFirstName : camp.first_name}</div>
        </div>
        <div className="offerTableLine">
          <div>Achternaam</div>
          <div>{contactLastName ? contactLastName : camp.last_name}</div>
        </div>
        <div className="offerTableLine">
          <div>E-Mail</div>
          <div>{contactEmail ? contactEmail : camp.email}</div>
        </div>
        <div className="offerTableLine">
          <div>Telefoon</div>
          <div>{contactPhone ? contactPhone : camp.contactPhone}</div>
        </div>
        <div className="offerTableLine">
          <div>Opmerkingen</div>
          <div>{remarks ? remarks : remarks}</div>
        </div>
      </div>
      {executeScroll(topRef)}
      {console.log(Document)}
    </>
  ) : (
    <>
      {/* <div ref={topRef}></div> */}
      <div className="offerHeader">
        <p>
          <div className="spacer"></div>
          <strong className="offerHeaderTit .tcb-style-wrap ul:not([class*='menu'])">
            Contract
          </strong>
          <div className="spacer"></div>
        </p>
        <div className="offerTableLine">
          <div>Klantnaam</div>
          <div>{camp.customerName}</div>
        </div>
        <div className="offerTableLine">
          <div>Gebruiker</div>
          <div>{camp.userName}</div>
        </div>
        <div className="offerTableLine">
          <div>Adres</div>
          <div>{camp.userAddress}</div>
        </div>
        <div className="offerTableLine">
          <div>Contract nummer</div>
          <div>{camp.contract_id}</div>
        </div>
        <div className="offerTableLine">
          <div>Service ID</div>
          <div>{camp.service_id}</div>
        </div>
      </div>
      <div className="offerHeader">
        <p>
          <div className="spacer"></div>
          <strong>Kies het aanbod</strong>
          <div className="spacer"></div>
        </p>
        <div>
          Selecteer hieronder het upgrade-aanbod dat bij jou past. Als je
          uiterlijk 31 oktober bestelt, ontvang je een aantrekkelijke korting op
          de standaardprijs van €995
          {`${camp.bullet1 === 2 ? " per 5G-unit" : ""}`}.
        </div>
        <div className="spacer"></div>
        <div>
          Goed om te weten: je abonnementskosten blijven hetzelfde. Je betaalt
          alleen de eenmalige kosten voor de upgrade. De kosten zijn afhankelijk
          van de nieuwe contractperiode. Deze eenmalige kosten zijn een
          vergoeding voor de investering in de nieuwste generatie 5G outdoor
          units en voor de installatie, configuratie en het testen van de
          verbinding.
        </div>
        <div className="spacer"></div>
        <div className="offerHeader">
          <p>
            <div className="spacer"></div>
            <strong>
              Wat we voor je gaan doen wanneer je ons de opdracht geeft:
            </strong>
            <div className="spacer"></div>
          </p>
          <ul>
            <li>
              {`${
                camp.bullet1 === 1
                  ? "Er wordt één 5G-unit gewisseld."
                  : camp.bullet1 === 2
                  ? "Er worden twee 5G-units gewisseld."
                  : ""
              }`}
            </li>
            <li>
              {`${
                camp.bullet2 === 1
                  ? "De verbinding bevindt zich in Nederland."
                  : camp.bullet2 === 2
                  ? "De verbinding bevindt zich in het buitenland."
                  : ""
              }`}
            </li>
            <li>
              {`${
                camp.bullet3 === 1
                  ? "Een installateur van Geuzenet voert de wissel uit. "
                  : camp.bullet3 === 2
                  ? "Wij sturen de apparatuur toe om zelf te wisselen en begeleiden het op afstand."
                  : ""
              }`}
            </li>
            {camp.bullet4 === 1 ? (
              <li>
                {`Op jouw locatie is mogelijk een hoogwerker nodig en we overleggen de meerkosten daarvan vooraf.`}
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
        <div className="spacer"></div>
        <p>
          <div className="spacer"></div>
          <strong>Maak een keuze</strong>
          <div className="spacer"></div>
        </p>
        <div className="campCheck">
          {
            <AssetsCheckbox3
              value={period === 12}
              checked={period === 12}
              onChangeFunc={(e) => {
                if (period !== 12) {
                  setPeriod(12);
                  dispatch(storeOffer({ ...camp, period: 12 }));
                }
              }}
            />
          }
          Verleng met 1 jaar tot en met{" "}
          {customAddedDateFormat(
            new Date(camp.earliest_end_date_t).valueOf() > new Date().valueOf()
              ? addDays(
                  new Date(
                    new Date().setMonth(
                      addDays(new Date(), -1).getMonth() +
                        Math.round(
                          getMonthsDiff(
                            addDays(new Date(), -1),
                            new Date(camp.earliest_end_date_t)
                          )
                        )
                    )
                  ),
                  -1
                )
              : addDays(new Date(), -1),
            undefined,
            1
          )}
          {` en betaal eenmalig\u00a0`}
          <b>{`€${camp.amount1}\u00a0`}</b>
          {`in plaats van ${camp.bullet1 === 1 ? "€995" : "€1990"}`}
        </div>
        <div className="campCheck">
          {
            <AssetsCheckbox3
              value={period === 24}
              onChangeFunc={(e) => {
                if (period !== 24) {
                  setPeriod(24);
                  dispatch(storeOffer({ ...camp, period: 24 }));
                }
              }}
            />
          }
          Verleng met 2 jaar tot en met{" "}
          {customAddedDateFormat(
            new Date(camp.earliest_end_date_t).valueOf() > new Date().valueOf()
              ? addDays(
                  new Date(
                    new Date().setMonth(
                      addDays(new Date(), -1).getMonth() +
                        Math.round(
                          getMonthsDiff(
                            addDays(new Date(), -1),
                            new Date(camp.earliest_end_date_t)
                          )
                        )
                    )
                  ),
                  -1
                )
              : addDays(new Date(), -1),
            undefined,
            2
          )}
          {` en betaal eenmalig\u00a0`}
          <b>{`€${camp.amount2}\u00a0`}</b>
          {`in plaats van ${camp.bullet1 === 1 ? "€995" : "€1990"}`}
        </div>
        <div className="campCheck">
          {
            <AssetsCheckbox3
              value={period === 36}
              onChangeFunc={(e) => {
                if (period !== 36) {
                  setPeriod(36);
                  dispatch(storeOffer({ ...camp, period: 36 }));
                }
              }}
            />
          }
          Verleng met 3 jaar tot en met{" "}
          {customAddedDateFormat(
            new Date(camp.earliest_end_date_t).valueOf() > new Date().valueOf()
              ? addDays(
                  new Date(
                    new Date().setMonth(
                      addDays(new Date(), -1).getMonth() +
                        Math.round(
                          getMonthsDiff(
                            addDays(new Date(), -1),
                            new Date(camp.earliest_end_date_t)
                          )
                        )
                    )
                  ),
                  -1
                )
              : addDays(new Date(), -1),
            undefined,
            3
          )}
          {` en betaal eenmalig\u00a0`}
          <b>{`€${camp.amount3}\u00a0`}</b>
          {`in plaats van ${camp.bullet1 === 1 ? "€995" : "€1990"}`}
        </div>
        <div className="spacer"></div>
        {camp.bullet1 === 1 ? (
          <>
            <p>
              <div className="spacer"></div>
              <strong>Optie: Redundantie</strong>
              <div className="spacer"></div>
            </p>
            <div>
              Wil je extra zekerheid? Maak je verbinding redundant, zodat je
              altijd verbonden blijft. Wij voorzien je van een tweede 5G-unit,
              waardoor je via twee verschillende 5G-netwerken online bent.
              Combineer deze optie met de 5G-upgrade voor een eenmalige kosten
              van €250 en een maandelijkse toeslag van €39.
            </div>
            <div className="spacer"></div>

            <div className="campCheck">
              {
                <AssetsCheckbox3
                  value={redund}
                  onChangeFunc={(e) => {
                    setRedund(!redund);
                  }}
                />
              }
              Maak mijn verbinding redundant.
            </div>
          </>
        ) : (
          ""
        )}
        <div className="spacer"></div>
      </div>
      <div className="offerHeader">
        <div className="spacer"></div>
        <p>
          <strong>Contactgegevens</strong>
        </p>
        <div className="spacer"></div>
        <div>
          We hebben de volgende contactgegevens van je. Pas deze zonodig aan.
        </div>
        <div className="spacer"></div>
        <div className="contactInputs">
          <div>
            <AssetsSelect2
              desc="Aanhef :"
              options={[
                { key: "heer", value: "heer" },
                { key: "mevrouw", value: "mevrouw" },
              ]}
              value={contactSalut}
              onChangeFunc={(e) => {
                setContactSalut(e);
                dispatch(storeOffer({ ...camp, salut: e }));
              }}
            />
          </div>
          <div>
            <AssetsTextInput
              desc={"Voornaam : "}
              onChangeFunc={(e) => {
                setContactFirstName(e);
                dispatch(storeOffer({ ...camp, contactFirstName: e }));
              }}
              value={contactFirstName}
            />
          </div>
          <div>
            <AssetsTextInput
              desc={"Achternaam : "}
              onChangeFunc={(e) => {
                setContactLastName(e);
                dispatch(storeOffer({ ...camp, contactLastName: e }));
              }}
              value={contactLastName}
            />
          </div>
          <div>
            <AssetsTextInput
              desc={"E-Mail : "}
              onChangeFunc={(e) => {
                setContactEmail(e);
                dispatch(storeOffer({ ...camp, contactEmail: e }));
              }}
              value={contactEmail}
            />
          </div>
          <div>
            <AssetsTextInput
              desc={"Telefoon : "}
              onChangeFunc={(e) => {
                setContactPhone(e);
                dispatch(storeOffer({ ...camp, contactPhone: e }));
              }}
              value={contactPhone}
            />
          </div>
        </div>
        <div className="offerHeader">
          <div className="spacer"></div>
          <p>
            <strong>Heb je nog opmerkingen?</strong>
          </p>
          <div className="spacer"></div>

          <div>
            Als je nog een vraag of opmerking hebt, kun je die hieronder
            plaatsen.
          </div>
          <div className="campRemarks">
            <AssetsTextMultiInput
              desc={"Opmerkingen : "}
              onChangeFunc={(e) => {
                if (e.length <= 500) {
                  setRemarks(e);
                }
              }}
              value={remarks}
            />
          </div>
        </div>
      </div>
      <div className="spacer"></div>

      <div className="offerHeader">
        <div className="spacer"></div>
        <p>
          <strong>Upgrade bestellen</strong>
        </p>
        <div className="spacer"></div>
        <div>
          Klik op de groene knop om te bestellen. Door te bestellen, bevestig je
          dat je akkoord gaat met onze{" "}
          <a
            href="https://geuzenet.nl/algemene-voorwaarden"
            target="_blank"
            rel="noreferrer"
          >
            algemene voorwaarden
          </a>
          .
        </div>
      </div>
      <div>
        <div className="spacer"></div>
        <button
          className={
            contactFirstName
              ? // contactFirstName.length > 0 &&
                //   contactLastName.length > 0 &&
                contactEmail.length > 3 &&
                contactEmail.includes("@") &&
                contactEmail[0] !== "@" &&
                contactEmail[contactEmail.length - 1] !== "@" &&
                period !== 0
                ? "campConfButton"
                : "campConfButtonInactive"
              : "campConfButtonInactive"
            // : "sdbInactive"
          }
          onClick={(e) => {
            if (
              // contactFirstName.length > 0 &&
              // contactLastName.length > 0 &&
              contactEmail.length > 3 &&
              contactEmail.includes("@") &&
              contactEmail[0] !== "@" &&
              contactEmail[contactEmail.length - 1] !== "@" &&
              period !== 0
            ) {
              dispatch(
                confirmOffer({
                  contactSalut: contactSalut,
                  contactFirstName: contactFirstName
                    ? contactFirstName.length > 0
                      ? contactFirstName
                      : "-"
                    : "-",
                  contactLastName: contactLastName
                    ? contactLastName.length > 0
                      ? contactLastName
                      : "-"
                    : "-",
                  contactEmail: contactEmail,
                  contactPhone: contactPhone,
                  orgId: camp.orgId,
                  serviceId: camp.service_id,
                  contractLength: period,
                  upgradePrice:
                    period === 12
                      ? camp.amount1
                      : period === 24
                      ? camp.amount2
                      : camp.amount3,
                  redund: redund,
                  remarks: remarks,
                  mailContent: `<!DOCTYPE html>
                  <html lang="en"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                  <style>
                  @import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
                  
                  .App {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    min-width: 400px;
                    min-height: 100vh;
                  }
                  select {
                    font-family: "Open Sans";
                    margin-right: 0;
                  }
                  .App-header {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: 100%;
                  }
                  h2 {
                    margin: 0;
                    color: #35647e;
                  }
                  .wide {
                    flex-direction: unset;
                  }
                  .oko5 {
                    width: 100%;
                    height: 3px;
                  }
                  .oko5 > * {
                    border-radius: 0 !important;
                    height: 20px;
                    width: 100%;
                    padding: 0;
                    margin: 3px;
                    border: none;
                  }
                  .wide {
                    height: 100%;
                    align-items: stretch;
                  }
                  
                  .offerHeader {
                    padding: 1px;
                    margin: 0;
                    box-sizing: content-box;
                    overflow-wrap: break-word;
                    display: block;
                    unicode-bidi: isolate;
                    /* font-family: "Open Sans"; */
                    font-size: 16px;
                    line-height: 1.75em;
                    color: #35647e;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    width: 100%;
                    max-width: 800px;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: left;
                  }
                  .offerHeader > * {
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 0;
                  }
                  .offerHeader > ul {
                    padding-left: 29px;
                  }
                  .offerTableLine {
                    display: flex;
                    width: 100%;
                  }
                  .offerTableLine > table > tbody > tr > * {
                    /* width: 170; */ 
                  }
                  .offerTableLine:first-child {
                    min-width: 175px;
                    max-width: 25%;
                  }
                  .offerHeader > div > form {
                    justify-content: left;
                  }
                  .offerHeader > div > form > * {
                    min-width: 11%;
                    text-align: left;
                  }
                  .offerHeader > div > form > div > div > div > div > span {
                    margin-left: 5px;
                    font-size: 14px;
                  }
                  .offerHeader > div > form > .dasDesc {
                    margin-top: -3px;
                  }
                  .noOfferResponse {
                    text-align: center;
                  }
                  .campCheck {
                    display: flex;
                    flex-direction: row;
                  }
                  .campCheck > form {
                    margin-right: 6px;
                    margin-left: -5px;
                  }
                  .campCheck > form > div {
                    margin-right: 0px;
                  }
                  .spacer {
                    height: 10px;
                  }
                  .offerHeader > div > div > form > .dasDesc,
                  .campInit > div > form > .dasDesc {
                    text-shadow: none;
                    margin-right: 0;
                    max-width: 130px;
                  }
                  .antennaBandsTable {
                    width: 80%;
                  }
                  .heading {
                    width: 100% !important;
                  }
                  .oko,
                  .oko6 {
                    display: flex;
                    justify-content: space-evenly;
                  }
                  .oko2 {
                    width: 10%;
                  }
                  .oko4 {
                    margin-left: 4px;
                  }
                  table {
                    color: #35647e;
                  }
                  </style>
                    <body >
                      <table cellpadding="0" cellspacing="0" border="0">
                        <tr>
                          <td id="root">
                            <table cellpadding="0" cellspacing="0" border="0">
                              <tr>
                                <td class="App">
                                  <header class="App-header">
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="offerHeader">
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20" height="10"></td>
                                            </tr>
                                          <b>Beste ${contactFirstName} ${contactLastName},</b>
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                          </table>
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td>Hartelijk dank voor je bestelling!</td>
                                            </tr>
                                          </table>
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                          </table>
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td width="750">We gaan er direct mee aan de slag! Binnenkort nemen we contact met je op om een afspraak in te plannen. Hieronder hebben we alles nog even voor je op een rijtje gezet. Als je vragen hebt of als er iets niet klopt, kun je ons bereiken op 088 248 1000 of via <a href={'mailto:planning@geuzenet.nl'}>planning@geuzenet.nl</a>.</td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="offerHeader">
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Klantnaam</td>
                                                  <td>${camp.customerName}</td>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Gebruiker</td>
                                                  <td>${camp.userName}</td>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Adres</td>
                                                  <td>${camp.userAddress}</td>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Contract nummer</td>
                                                  <td>${camp.contract_id}</td>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Service</td>
                                                  <td>${camp.service_id}</td>
                                                </table>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="offerHeader">
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                          </table>
                                          <b>Dit is wat we gaan doen:</b>
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                          </table>
                                          &bull; ${
                                            camp.bullet1 === 1
                                              ? "Er wordt één 5G-unit gewisseld."
                                              : camp.bullet1 === 2
                                              ? "Er worden twee 5G-units gewisseld."
                                              : ""
                                          }
                                          <br>
                                          &bull; ${
                                            camp.bullet2 === 1
                                              ? "De verbinding bevindt zich in Nederland."
                                              : camp.bullet2 === 2
                                              ? "De verbinding bevindt zich in het buitenland."
                                              : ""
                                          }
                                          <br>
                                          &bull; ${
                                            camp.bullet3 === 1
                                              ? "Een installateur van Geuzenet voert de wissel uit. "
                                              : camp.bullet3 === 2
                                              ? "Wij sturen de apparatuur toe om zelf te wisselen en begeleiden het op afstand."
                                              : ""
                                          }
                                          <br>
                                          ${
                                            camp.bullet4 === 1
                                              ? `&bull; Op jouw locatie is mogelijk een hoogwerker nodig en we overleggen de meerkosten daarvan vooraf.<br>`
                                              : ""
                                          }
                                          ${
                                            redund
                                              ? `&bull; Je verbinding wordt redundant gemaakt.<br>`
                                              : ""
                                          }
                                        </td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="offerHeader">
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                          </table>
                                          ${
                                            !redund
                                              ? `
                                          <b>
                                            ${`Je contract wordt verlengd tot en met ${customAddedDateFormat(
                                              new Date(
                                                camp.earliest_end_date_t
                                              ).valueOf() > new Date().valueOf()
                                                ? addDays(
                                                    new Date(
                                                      new Date().setMonth(
                                                        addDays(
                                                          new Date(),
                                                          -1
                                                        ).getMonth() +
                                                          Math.round(
                                                            getMonthsDiff(
                                                              addDays(
                                                                new Date(),
                                                                -1
                                                              ),
                                                              new Date(
                                                                camp.earliest_end_date_t
                                                              )
                                                            )
                                                          )
                                                      )
                                                    ),
                                                    -1
                                                  )
                                                : addDays(new Date(), -1),
                                              undefined,
                                              period === 12
                                                ? 1
                                                : period === 24
                                                ? 2
                                                : 3
                                            )} en je betaalt:`}
                                          </b>
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                          </table>
                                          &bull; ${`een eenmalige vergoeding van €${
                                            period === 12
                                              ? camp.amount1
                                              : period === 24
                                              ? camp.amount2
                                              : camp.amount3
                                          } voor de 5G-upgrade.`}
                                          <br>`
                                              : `
                                          <b>${`Je contract wordt verlengd tot en met ${customAddedDateFormat(
                                            new Date(
                                              camp.earliest_end_date_t
                                            ).valueOf() > new Date().valueOf()
                                              ? new Date(
                                                  camp.earliest_end_date_t
                                                )
                                              : addDays(new Date(), -1),
                                            undefined,
                                            period === 12
                                              ? 1
                                              : period === 24
                                              ? 2
                                              : 3
                                          )} en je betaalt:`}
                                          </b>
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                          </table>
                                          &bull; ${`een eenmalige vergoeding van €${
                                            period === 12
                                              ? camp.amount1
                                              : period === 24
                                              ? camp.amount2
                                              : camp.amount3
                                          } voor de 5G-upgrade.`}
                                          <br>
                                          &bull; ${`een eenmalige vergoeding van €250 en maandelijks €39 voor de redundantie.`}
                                          <br>`
                                          }</td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="offerHeader">
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>
                                            <tr>
                                              <td><b>We hebben de volgende contactgegevens van je:</b></td>
                                            <tr>
                                              <td class="spacer" height="20"></td>
                                            </tr>                                            
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Aanhef</td>
                                                  <td>${
                                                    contactSalut
                                                      ? contactSalut
                                                      : ""
                                                  }</td>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Voornaam</td>
                                                  <td>${
                                                    contactFirstName
                                                      ? contactFirstName
                                                      : ""
                                                  }</td>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Achternaam</td>
                                                  <td>${
                                                    contactLastName
                                                      ? contactLastName
                                                      : ""
                                                  }</td>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">E-Mail</td>
                                                  <td>${
                                                    contactEmail
                                                      ? contactEmail
                                                      : ""
                                                  }</td>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135">Telefoon</td>
                                                  <td>${
                                                    contactPhone
                                                      ? contactPhone
                                                      : ""
                                                  }</td>
                                                </table>
                                              </td>  
                                            </tr>
                                            <tr>
                                              <td class="offerTableLine">
                                                <table cellpadding="0" cellspacing="0" border="0">
                                                  <td width="135" valign="top">Opmerkingen</td>
                                                  <td width="750">${
                                                    remarks ? remarks : ""
                                                  }</td>
                                                </table>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="spacer" height="20"></td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="spacer" height="20"></td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="spacer" height="20"></td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="spacer" height="20"></td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="offerHeader">
                                          <table cellpadding="0" cellspacing="0" border="0">
                                            <tr>
                                              <td>
                                            <table cellpadding="0" cellspacing="0" border="0">
                                              <tr>
                                                <td>Met vriendelijke groet,</td>
                                              </tr>
                                            </table>
                                            <table cellpadding="0" cellspacing="0" border="0">
                                              <tr>
                                                <td>Team Geuzenet</td>
                                              </tr>
                                            </table>
                                        </td>
                                      </tr>
                                    </table>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                      <tr>
                                        <td class="spacer" height="20"></td>
                                      </tr>
                                    </table>
`,
                })
              );
            }
          }}
        >
          Bestel de 5G upgrade
        </button>
        <div className="spacer"></div>
      </div>
    </>
  );
}

export default Camp5g;

import axios from "axios";
import { setCampLoading } from "./navigation";
const key = process.env.REACT_APP_API_KEY;

export const setLoading = (loading) => {
  return {
    type: "camp/setLoading",
    payload: loading,
  };
};
export const storeOffer = (data) => {
  return {
    type: "camp/storeCamp",
    payload: data,
  };
};
export const cleanCamp = () => {
  return {
    type: "camp/cleanCamp",
  };
};

export const getOffer = (req) => {
  return async (dispatch, getState) => {
    dispatch(setCampLoading(true));
    let response = await axios.post(
      `/api/campaign?code=${key}&&type=getOffer`,
      { ...req }
    );
    dispatch(storeOffer(response.data));
    dispatch(setCampLoading(false));
  };
};
export const confirmOffer = (req) => {
  return async (dispatch, getState) => {
    dispatch(setCampLoading(true));
    let response = await axios.post(
      `/api/campaign?code=${key}&&type=confirmOffer`,
      { ...req }
    );
    dispatch(storeOffer({ ...getState().camp, completed: response.data }));
    dispatch(setCampLoading(false));
  };
};

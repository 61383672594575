import "../../css/poolDetails.css";
import {
  ComposedChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import NewTable from "../../components/newTable/newTable";
import logo from "../../geuzenetlog.png";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import {
  getDataUsagePPD,
  getPoolDetails,
  getPoolTopUsers,
  updatePoolAlerts,
} from "../../store/actions/admin";
import {
  selectPoolPD,
  selectPoolDetails,
  selectPoolTopUsers,
} from "../../store/selectors/admin";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function UsageSub1() {
  const appUser = useSelector(selectAppUser);
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { id } = useParams();
  const navigation = useSelector(selectNavigation);
  const dataUsagePPD = useSelector(selectPoolPD);
  const topUsers = useSelector(selectPoolTopUsers);
  const poolDetails = useSelector(selectPoolDetails);
  let chartRef = useRef();
  const headerRef = useRef();
  const dispatch = useDispatch();
  const [alerts, setAlerts] = useState({
    tenth: 0,
  });
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(
          setCategories(
            "Admin",
            `Pool ${poolDetails.name ? poolDetails.name : ""} Data Usage`,
            "details"
          )
        );
      }
    };
    setMenu(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (dataUsagePPD.length === 0 && validUser(appUser)) {
        dispatch(getDataUsagePPD(id));
        dispatch(getPoolTopUsers(id));
        dispatch(getPoolDetails(id));
      }
    };
    getItems();
    setAlerts({
      tenth: poolDetails.tenth_day_alert_value,
      twentieth: poolDetails.twentieth_day_alert_value,
      total: poolDetails.total_alert_value,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataUsagePPD,
    dispatch,
    id,
    poolDetails,
    navigation.menuSub,
    navigation.menuMain,
  ]);
  console.log(poolDetails);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return dataUsagePPD.length > 0 ? (
      <div
        className={
          dataUsagePPD.length === 0 ? "DataUsage loading" : "DataUsage"
        }
      >
        <div className="topBar"></div>
        <div className="poolDataChart" ref={headerRef}>
          {dataUsagePPD.length > 0 ? (
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                ref={chartRef}
                width={500}
                height={300}
                data={dataUsagePPD}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {" "}
                <Brush dataKey="day" height={30} stroke="#8884d8" />
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend
                  verticalAlign="top"
                  wrapperStyle={{ lineHeight: "40px" }}
                />
                <Bar dataKey="usage" fill="#35647e" />
                <Line
                  type="monotone"
                  dataKey="bundelSize"
                  stroke="#ff7300"
                  strokeWidth={3}
                  dot={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
          ) : (
            <></>
          )}
        </div>
        <div className="usageAlerts">
          <h2>Usage alerts</h2>
          <div className="usageAlertsSelectors">
            <div className="usageAlertsSelector">
              10th alert:{" "}
              <input
                type="number"
                value={alerts.tenth}
                onChange={(e) => {
                  setAlerts({
                    ...alerts,
                    tenth: parseInt(e.target.value),
                  });
                }}
              ></input>
              %
            </div>
            <div className="usageAlertsSelector">
              20th alert:{" "}
              <input
                type="number"
                value={alerts.twentieth}
                onChange={(e) => {
                  setAlerts({
                    ...alerts,
                    twentieth: parseInt(e.target.value),
                  });
                }}
              ></input>
              %
            </div>
            <div className="usageAlertsSelector">
              Total alert:{" "}
              <input
                type="number"
                value={alerts.total}
                onChange={(e) => {
                  setAlerts({
                    ...alerts,
                    total: parseInt(e.target.value),
                  });
                }}
              ></input>
              %
            </div>
          </div>
          <div className="mainButtonsAAD">
            <button
              className="cancellButton"
              onClick={(e) => {
                setAlerts({
                  tenth: poolDetails.tenth_day_alert_value,
                  twentieth: poolDetails.twentieth_day_alert_value,
                  total: poolDetails.total_alert_value,
                });
              }}
            >
              Reset
            </button>
            <button
              onClick={(e) => {
                dispatch(updatePoolAlerts(id, alerts));
                setAlerts({});
              }}
            >
              Set
            </button>
          </div>
        </div>
        {topUsers.length > 0 ? (
          <div className="smallTableWrapper" ref={headerRef}>
            <NewTable
              tabltype={"small"}
              tit="poolTopUsage"
              data={topUsers}
              width={headerRef.current ? headerRef.current.clientWidth : 100}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <img className="landingLogo" src={logo} alt="company logo" />
    );
  }
}
export default UsageSub1;

const initialState = {
  hardware: [],
  simcards: [],
  details: {},
  hardwareProps: {},
  simProps: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "assets/storeHardwareProps":
      return { ...state, hardwareProps: action.payload };
    case "assets/storeSimProps":
      return { ...state, simProps: action.payload };
    case "assets/storeAllHardware":
      return { ...state, hardware: action.payload };
    case "assets/storeAllSimcards":
      return { ...state, simcards: action.payload };
    case "assets/storeAssetDetails":
      return { ...state, details: action.payload };
    case "assets/init":
      return { ...initialState };
    default:
      return state;
  }
}

import "../../css/services.css";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import logo from "../../geuzenetlog.png";

import NewTable from "../../components/newTable/newTable";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

import { getServicesUsageAlerts } from "../../store/actions/services";
import { selectUsageAlerts } from "../../store/selectors/services";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;

function Services() {
  const appUser = useSelector(selectAppUser);
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const customShortDateFormat = (date, locale) => {
    let m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear();
    return `${y}-${m}`;
  };
  const customDateFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear();
    return `${y}-${m}-${d}`;
  };

  const headerRef = useRef();

  const dispatch = useDispatch();

  const navigation = useSelector(selectNavigation);
  const dateNow = new Date(Date.now());
  const thisMonth = new Date(
    `${dateNow.getFullYear()}`,
    `${dateNow.getMonth()}`
  ).toISOString();
  const alerts = useSelector(selectUsageAlerts);

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Services", "Overuse", "list"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (alerts.length === 0 && validUser(appUser)) {
        dispatch(getServicesUsageAlerts(thisMonth));
      }
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain, thisMonth]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className={alerts.length === 0 ? "Services loading" : "Services"}>
        <>
          <div className="topBar"></div>
          <div className="bigTableWrapper serAlertsTable" ref={headerRef}>
            {alerts.length > 0 ? (
              <NewTable
                tabletype={"big"}
                thisMonth={customShortDateFormat(
                  new Date(thisMonth),
                  undefined
                )}
                tit="serivceUsageAlerts"
                data={alerts.map((line) => {
                  return {
                    ...line,
                    month: line.month
                      ? customShortDateFormat(new Date(line.month), undefined)
                      : null,
                    reported_on: line.reported_on
                      ? customDateFormat(new Date(line.reported_on), undefined)
                      : null,
                    bundle_gb: line.bundle_gb,
                    current_gb: line.current_gb,
                    done: line.done ? "Yes" : "No",
                  };
                })}
                width={headerRef.current ? headerRef.current.clientWidth : 100}
              />
            ) : (
              <img className="landingLogo" src={logo} alt="company logo" />
            )}
          </div>
        </>
      </div>
    );
  }
}

export default Services;

import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useEffect, useState } from "react";

import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import NewTable from "../../components/newTable/newTable";
import logo from "../../geuzenetlog.png";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  getMaintenanceNetworks,
  getMaintenanceSources,
  readMaintenanceInfo,
  nullInfoSources,
  nullInfo,
} from "../../store/actions/services";
import {
  selectMaintenanceNetworks,
  selectMaintenanceInfoSources,
  selectMaintenanceInfo,
} from "../../store/selectors/services";
const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function AdminSubAdmin2() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const navigation = useSelector(selectNavigation);
  const [networkSelect, setNetworkSelect] = useState(null);
  const [sourceSelect, setSourceSelect] = useState(null);
  const networks = useSelector(selectMaintenanceNetworks);
  const infoSources = useSelector(selectMaintenanceInfoSources);
  const maintenanceInfo = useSelector(selectMaintenanceInfo);
  const headerRef = useRef();
  const topBarRef = useRef();

  const dispatch = useDispatch();
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Services", "Maintenance", "list"));
      }
    };

    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());

    if (networks.length < 1 && validUser(appUser)) {
      dispatch(getMaintenanceNetworks());
    }
    if (networkSelect && infoSources.length < 1 && validUser(appUser)) {
      dispatch(getMaintenanceSources(networkSelect));
    }
    if (
      networkSelect &&
      infoSources.length > 0 &&
      sourceSelect &&
      validUser(appUser)
    ) {
      dispatch(readMaintenanceInfo(networkSelect, sourceSelect));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    networkSelect,
    networks,
    infoSources,
    sourceSelect,
    navigation.menuSub,
    navigation.menuMain,
  ]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <>
        <div className="logsTables">
          {" "}
          <div className="bigTableWrapper" ref={headerRef}>
            <div className="topBar" ref={topBarRef}></div>
            <div className="maintenanceSelectors">
              {networks.length > 0 ? (
                <div>
                  Network:{" "}
                  <select
                    className="logsTableSelector"
                    onChange={(e) => {
                      setNetworkSelect(e.target.value);
                      dispatch(nullInfoSources());
                      dispatch(nullInfo());
                      setSourceSelect(null);
                    }}
                  >
                    {networkSelect ? (
                      ""
                    ) : (
                      <option value={null}>{"-Select-"}</option>
                    )}
                    {networks.map((network) => {
                      return (
                        <option
                          value={`${network.operator}_${network.country}_${network.type}`}
                        >
                          {`${network.operator} ${network.country}(${network.type})`}
                        </option>
                      );
                    })}
                  </select>{" "}
                </div>
              ) : (
                ""
              )}
              {networks.length > 0 ? (
                <div>
                  Source:{" "}
                  <select
                    disabled={
                      (infoSources[0] === "unavaible" &&
                        infoSources.length === 1) ||
                      infoSources.length === 0
                    }
                    className="logsTableSelector"
                    onChange={(e) => {
                      setSourceSelect(e.target.value);
                      dispatch(nullInfo());
                    }}
                  >
                    {infoSources[0] !== "unavaible" &&
                    infoSources.length > 0 ? (
                      <option value={null}>{"-Select-"}</option>
                    ) : (
                      ""
                    )}
                    {infoSources.map((source) => {
                      return <option value={`${source}`}>{`${source}`}</option>;
                    })}
                  </select>{" "}
                </div>
              ) : (
                ""
              )}
            </div>

            {maintenanceInfo.length > 0 ? (
              <NewTable
                topBarRef={topBarRef}
                wrapperRef={headerRef}
                data={maintenanceInfo}
                tit="maintenanceTable"
                network={networkSelect}
                tabletype={"big"}
              />
            ) : (
              ""
            )}
            {networks.length < 1 ||
            (networks.length > 0 && networkSelect && infoSources.length < 1) ||
            (networks.length > 0 &&
              networkSelect &&
              infoSources.length > 0 &&
              sourceSelect &&
              maintenanceInfo.length < 1) ? (
              <img className="landingLogo" src={logo} alt="company logo" />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

export default AdminSubAdmin2;

import "../../css/assets.css";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import logo from "../../geuzenetlog.png";
import NewTable from "../../components/newTable/newTable";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import { getAllHardware, getAllSimcards } from "../../store/actions/assets";
import {
  selectAllHardware,
  selectAllSimcards,
} from "../../store/selectors/assets";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function Assets() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const headerRef = useRef();
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const hardware = useSelector(selectAllHardware);
  const simcards = useSelector(selectAllSimcards);

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Assets", "Sims", "list"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (
        (simcards.length === 0 || hardware.length === 0) &&
        validUser(appUser)
      ) {
        dispatch(getAllSimcards());
        dispatch(getAllHardware());
      }
    };
    getItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div
        className={
          hardware.length === 0 && simcards.length === 0
            ? "Assets loading"
            : "Assets"
        }
      >
        <>
          <div className="topBar"></div>
          <div className="bigTableWrapper scListTable" ref={headerRef}>
            {simcards.length > 0 ? (
              <NewTable
                wrapperRef={headerRef}
                tit={"simsOverview"}
                tabletype={"big"}
                data={simcards}
                // width={headerRef.current ? headerRef.current.clientWidth : 100}
              />
            ) : (
              <img className="landingLogo" src={logo} alt="company logo" />
            )}
          </div>
        </>
      </div>
    );
  }
}

export default Assets;

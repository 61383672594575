import axios from "axios";
const key = process.env.REACT_APP_API_KEY;

export const ticketsInit = () => {
  return {
    type: "tickets/init",
  };
};

const storeAllTickets = (allTickets) => {
  return {
    type: "tickets/storeAllTickets",
    payload: allTickets,
  };
};

export const getAllTickets = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/tickets_overview?code=${key}`);
    console.log(response);
    dispatch(storeAllTickets(response.data));
  };
};

const initialState = {
  contractsList: [],
  contractDetails: {},
  selectedContract: 0,
  contractToConfirm: null,
  orderlineEndDateEdit: { id: 0, date: null },
  newInvoices: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "invoicing/setOrderlineEndDateEdit":
      return { ...state, orderlineEndDateEdit: action.payload };
    case "invoicing/storeContractsList":
      return { ...state, contractsList: action.payload };
    case "invoicing/setSelectedContract":
      return { ...state, selectedContract: action.payload };
    case "invoicing/storeContractDetails":
      return { ...state, contractDetails: action.payload };
    case "invoicing/storeUnconifrmed":
      return { ...state, contractToConfirm: action.payload };
    case "invoicing/storeNewInvoices":
      return { ...state, newInvoices: action.payload };
    case "invoicing/nullContractDetails":
      return { ...state, contractDetails: {} };
    case "invoicing/nullContractToConfirm":
      return { ...state, contractToConfirm: null };
    default:
      return state;
  }
}

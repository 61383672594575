import { combineReducers } from "redux";

import navigation from "./reducers/navigation";
import tickets from "./reducers/tickets";
import services from "./reducers/services";
import assets from "./reducers/assets";
import admin from "./reducers/admin";

// import sync from "./reducers/sync";
// import triggers from "./reducers/triggers";
import orders from "./reducers/orders";
import tableXlsxExport from "./reducers/tableXlsxExport";
// import tablesFilters from "./reducers/tablesFilters";
import logTable from "./reducers/logTable";
import inventoryCheck from "./reducers/inventoryCheck";
import psqlEdit from "./reducers/psqlEdit";
import koffers from "./reducers/koffers";
import camp from "./reducers/camp";
import invoicing from "./reducers/invoicing";

export default combineReducers({
  navigation,
  tickets,
  services,
  assets,
  admin,
  camp,
  //
  // sync,
  // triggers,
  orders,
  tableXlsxExport,
  // tablesFilters,
  logTable,
  inventoryCheck,
  psqlEdit,
  koffers,
  invoicing,
});

import "../../css/SimcardMoveBulk.css";
import logo from "../../geuzenetlog.png";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import NewTable from "../../components/newTable/newTable";
import {
  AssetsSelect,
  AssetsTextInput,
  AssetsDateTimePicker,
  AssetsCheckbox,
} from "../../components/assetsInputs";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
import {
  getSimProps,
  getAllSimcards,
  bulkUpdateSimcards,
} from "../../store/actions/assets";
import {
  selectSimProps,
  selectAllSimcards,
} from "../../store/selectors/assets";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;

function SimcardMoveBulk() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const scProps = useSelector(selectSimProps);
  const simcard = useSelector(selectAllSimcards);
  const iccField = useRef();
  const iccInput = iccField.current;
  const idField = useRef();
  const idInput = idField.current;

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Assets", "Update sims", "details"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (
        (Object.keys(scProps).length === 0 || simcard.length === 0) &&
        validUser(appUser)
      ) {
        dispatch(getSimProps());
        dispatch(getAllSimcards());
      }
    };
    getItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain]);
  const [itemsArray, setItemsArray] = useState([]);
  const [arrayStatus, setarrayStatus] = useState(false);
  const [filter, setFilter] = useState("");
  const [saved, setSsaved] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [info, setInfo] = useState(".");
  const isBigScreen = useMediaQuery({ query: "(min-width: 1100px)" });
  const [simId, setSimId] = useState();
  const [simICC, setSimICC] = useState();

  const globalInit = {
    statusChange: false,
    locationChange: false,
    inModem: false,
    status: "- Select -",
    modem: "- Select -",
    stock_id: "- Select -",
    time: new Date(Date.now()),
  };
  const [global, setGlobal] = useState(globalInit);
  const resetItem = () => {
    // setItem();
    setGlobal(globalInit);
    setSimId("");
    setSimICC("");
    setFilter("");
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      let item;
      if (
        (simId !== "" || simICC !== "") &&
        itemsArray.filter(
          (arrItem) => arrItem.icc === simICC || arrItem.simId === simId
        ).length === 0
      ) {
        let newArray = [...itemsArray];
        let dbItem;
        let dbState;
        if (event.target.name === "idInput") {
          dbItem = simcard.find((dbItem) => dbItem.id === simId);
          dbState = {
            db: dbItem ? "idTrue" : "idFalse",
            remark: dbItem
              ? ""
              : `Item with ID ${simId} doesn't exist in database!`,
          };
          idInput.focus();
        } else if (event.target.name === "iccInput") {
          dbItem = simcard.find((dbItem) => dbItem.icc === simICC);
          dbState = {
            db: dbItem ? "iccTrue" : "iccFalse",
            remark: dbItem
              ? ""
              : `Item with ICC ${simICC} doesn't exist in database!`,
          };
          iccInput.focus();
        }
        item = {
          id: nextId,
          ...global,
          ...dbState,
          simId: dbItem ? dbItem.id : "",
          icc: dbItem ? dbItem.icc : "",
          msisdn: dbItem ? dbItem.msisdn : "",
          dbStatus: dbItem ? dbItem.status : "",
          dbLocation: dbItem ? dbItem.location : "",
        };
        newArray.push(item);
        setItemsArray(newArray);
        setNextId(nextId + 1);
        setSimICC("");
        setSimId("");
      }
    }
  };
  const locationFilter = (filterValue) => {
    return scProps.modem.filter((modem) =>
      (modem.location ? modem.location : "  ")
        .toLowerCase()
        .includes(filterValue.toLowerCase())
    );
  };
  const stockFilter = (filterValue) => {
    return scProps.stock.filter((stock) =>
      stock.name.toLowerCase().includes(filterValue.toLowerCase())
    );
  };
  useEffect(() => {
    setItemsArray(
      itemsArray.map((entry) => {
        return { ...entry, ...global };
      })
    );
    setSsaved(false); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global]);
  useEffect(() => {
    arrayCheck(
      itemsArray.map((entry) => {
        return { ...entry, ...global };
      })
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsArray, global]);
  const arrayCheck = (array) => {
    array = array.map((entry) => {
      return {
        ...entry,
        oldSim: scProps.modem.find((modem) => modem.tag === entry.modem)
          ? scProps.modem.find((modem) => modem.tag === entry.modem).simcard_id
          : null,
      };
    });
    if (array) {
      const selectedModem = array[0]
        ? scProps.modem.find((modem) => modem.tag === array[0].modem)
          ? scProps.modem.find((modem) => modem.tag === array[0].modem)
          : ""
        : "";
      const currentSimCheck =
        selectedModem && array[0]
          ? selectedModem.simcard_id === parseInt(array[0].simId)
          : "";
      if (
        array.filter((entry) => entry.db !== "idTrue" && entry.db !== "iccTrue")
          .length > 0
      ) {
        setInfo("Incorrect ID or ICC in the list!");
        setarrayStatus(false);
      } else if (
        array.filter((entry) => !entry.locationChange && !entry.statusChange)
          .length > 0
      ) {
        setInfo(".");
        setarrayStatus(false);
      } else if (array.length < 1) {
        setInfo(".");
        setarrayStatus(false);
      } else if (array.length > 1 && array[0].modem) {
        setInfo("Only one simcard per modem allowed ");
        setarrayStatus(false);
      } else if (!currentSimCheck && selectedModem.simcard_id) {
        setInfo(
          `Modem ${
            selectedModem.tag
          } alredy have simcard assigned. That simcard (ID: ${
            simcard.find(
              (sim) => parseInt(sim.id) === parseInt(selectedModem.simcard_id)
            ).id
          }, ICC: ${
            simcard.find(
              (sim) => parseInt(sim.id) === parseInt(selectedModem.simcard_id)
            ).icc
          }) will be moved to Stock -> Unknown`
        );
        setarrayStatus(true);
      } else {
        setInfo(".");
        setarrayStatus(true);
      }
    }
  };
  const dataLine = (desc, value, onClick) => {
    return (
      <div className="dasLine">
        <div className="dasDesc">{desc}</div>
        <div className="dasValue" onClick={onClick}>
          {value}
        </div>
      </div>
    );
  };
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return Object.keys(scProps).length > 0 && simcard.length > 0 ? (
      <div className="SimcardMoveBulk">
        <div className="topBar">
          {saved ? (
            <div className="savedNoteAUD">State and/or Location updated!!</div>
          ) : info === "." ? (
            <div className="savedNoteAUD" style={{ color: "white" }}>
              ||
            </div>
          ) : (
            <div className="savedNoteAUD" style={{ color: "#f9323f" }}>
              {info}
            </div>
          )}
          <div className="mainButtonsAUS">
            {arrayStatus ? (
              <button
                onClick={(e) => {
                  setInfo(".");
                  dispatch(bulkUpdateSimcards(itemsArray));
                  setarrayStatus(false);
                  setItemsArray([]);
                  resetItem();
                  setNextId(0);
                  setSsaved(true);
                  dispatch(getAllSimcards());
                  if (idInput) {
                    idInput.focus();
                  }
                }}
              >
                Save
              </button>
            ) : (
              <button className="disabled">Save</button>
            )}
            <button
              className="cancellButton"
              onClick={() => {
                setInfo(".");
                setarrayStatus(false);
                setItemsArray([]);
                setNextId(0);
                resetItem();
                setSsaved(false);
                dispatch(getAllSimcards());
                if (idInput) {
                  idInput.focus();
                }
              }}
            >
              Reset
            </button>
          </div>
        </div>
        <div
          className={isBigScreen ? "globalSelection wide" : "globalSelection"}
        >
          <div className="globalSelectionGroup">
            <AssetsSelect
              desc="Status:"
              value={global.status}
              options={scProps.status.map((status) => {
                return { key: status.id, value: status.status };
              })}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  status: value,
                  statusChange: value === "- Select -" ? false : true,
                });
              }}
            />
            <AssetsDateTimePicker
              desc="TIme:"
              value={global.time}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  time: value,
                });
              }}
            />
            {dataLine()}
          </div>
          <div className="globalSelectionSpacer"></div>
          <div className="globalSelectionGroup">
            <AssetsCheckbox
              desc={"In modem:"}
              value={global.inModem}
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  inModem: !global.inModem,
                });
              }}
            />
            <AssetsTextInput
              desc="Location filter:"
              value={filter}
              onChangeFunc={(value) => {
                setFilter(value);
              }}
            />
            <AssetsSelect
              desc="Location:"
              value={!global.inModem ? global.stock_id : global.modem}
              options={
                global.inModem
                  ? locationFilter(filter).map((modem) => {
                      return { key: modem.tag, value: modem.location };
                    })
                  : stockFilter(filter).map((stock) => {
                      return { key: stock.id, value: stock.name };
                    })
              }
              onChangeFunc={(value) => {
                setGlobal({
                  ...global,
                  locationChange: value === "- Select -" ? false : true,
                  stock_id: global.inModem ? null : value,
                  modem: !global.inModem ? null : value,
                });
              }}
            />
          </div>
        </div>
        <div className="notTop">
          <form className="scanFields">
            <div>
              <div className="scanLineTag">
                <div>
                  {"ID : "}
                  <input
                    name="idInput"
                    className="idInput"
                    ref={idField}
                    value={simId}
                    onKeyDown={handleEnter}
                    onChange={(e) => {
                      setSimId(e.target.value);
                    }}
                  />
                </div>

                <div>
                  {"ICC : "}
                  <input
                    name="iccInput"
                    className="iccInput"
                    ref={iccField}
                    value={simICC}
                    onKeyDown={handleEnter}
                    onChange={(e) => {
                      setSimICC(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {itemsArray.length > 0 ? (
          <div className="smallTableWrapper">
            <NewTable
              data={itemsArray
                .slice(0)
                .map((line) => {
                  return (line = {
                    ...line,
                    button: (
                      <button
                        className="removeButtonAAD"
                        onClick={(e) => {
                          e.preventDefault();
                          let arr = itemsArray.filter(
                            (arrItem) => line.id !== arrItem.id
                          );
                          setItemsArray(arr);
                        }}
                      >
                        Remove
                      </button>
                    ),
                  });
                })
                .reverse()}
              tit="updateSims"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    ) : (
      <div className="SimcardAddNew">
        <img className="landingLogo ll" src={logo} alt="company logo" />
      </div>
    );
  }
}
export default SimcardMoveBulk;

const initialState = {
  dirtyOrders: [],
  deliveries: [],
  mapPoints: [],
  lastCheck: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "orders/storeDirtyOrders":
      return { ...state, dirtyOrders: action.payload };
    case "orders/storelastOrderCheck":
      return { ...state, lastCheck: action.payload };
    case "orders/setDirtyOrdersToNull":
      return { ...state, dirtyOrders: [] };
    case "orders/setDeliveriesToNull":
      return { ...state, deliveries: [] };
    case "orders/storeDeliveries":
      return { ...state, deliveries: action.payload };
    case "orders/setMap":
      return { ...state, mapPoints: action.payload };
    default:
      return state;
  }
}

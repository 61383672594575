import "../../css/services.css";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import logo from "../../geuzenetlog.png";
import { getAllTickets } from "../../store/actions/tickets";
import { selectTicketsList } from "../../store/selectors/tickets";
import NewTable from "../../components/newTable/newTable";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";
const customTimeFormat = (date, locale) => {
  let d = `0${date.getDate()}`.slice(-2),
    m = date.toLocaleString(locale, { month: "2-digit" }),
    y = date.getFullYear(),
    t = date.toLocaleTimeString(locale, {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
    });
  return `${y}-${m}-${d} ${t}`;
};
const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function Services() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const headerRef = useRef();
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const tickets = useSelector(selectTicketsList);
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Tickets", "Overview", "list"));
      }
    };

    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (tickets.length === 0 && validUser(appUser)) {
        dispatch(getAllTickets());
      }
    };
    getItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tickets, navigation.menuSub, navigation.menuMain]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className={tickets.length === 0 ? "Services loading" : "Services"}>
        <>
          <div className="topBar"></div>
          <div className="bigTableWrapper" ref={headerRef}>
            {tickets.length > 0 ? (
              <NewTable
                wrapperRef={headerRef}
                tit={"ticketsOverview"}
                tabletype={"big"}
                data={tickets.map((page) => {
                  return {
                    ...page,
                    start: page.start
                      ? customTimeFormat(new Date(page.start), undefined)
                      : null,
                    end: page.end
                      ? customTimeFormat(new Date(page.end), undefined)
                      : null,
                  };
                })}
                width={headerRef.current ? headerRef.current.clientWidth : 100}
              />
            ) : (
              <img className="landingLogo" src={logo} alt="company logo" />
            )}
          </div>
        </>
      </div>
    );
  }
}

export default Services;

import React from "react";
import Table from "./Table";
import zipcelx from "zipcelx";
import xlsxIco from "../../xlsxIco.png";
import checkedImg from "../../checked.png";
import antIco from "../../antIco.svg";
import unCheckedImg from "../../unchecked.png";
import { useSelector } from "react-redux";
import { selectExportTables } from "../../store/selectors/tableXlslExport";
import { selectAssetDetails } from "../../store/selectors/assets";
import {
  selectServiceDetails,
  selectUsageStamps,
} from "../../store/selectors/services";

import { SelectColumnFilter, MultiSelectColumnFilter } from "./Table";

export default function NewTable(props) {
  const serviceDetails = useSelector(selectServiceDetails);
  const serviceUsageTimestamps = useSelector(selectUsageStamps);
  const exportTables = useSelector(selectExportTables);
  const details = useSelector(selectAssetDetails);
  let rawColumns;
  let className;
  let customFilters;
  let defaultSorted;
  let exportFileName;
  let exportReduxName;
  let checkValueIndex;
  let tableIdent;
  let defaultFilterValue;

  const exportButton = (tableReduxName) => {
    const exportBClass =
      props.tabletype === "big" || props.tabletype === "mid"
        ? "xlsxIcoTall"
        : "xlsxIco";
    return (
      <img
        className={exportBClass}
        src={xlsxIco}
        alt="Excell icon"
        onClick={(e) => {
          zipcelx(exportTables[tableReduxName]);
        }}
      />
    );
  };

  if (props.tit === "simsOverview") {
    className = "bigTable";
    exportFileName = "List of Sims";
    exportReduxName = "ListOfSims";
    checkValueIndex = 0;
    rawColumns = [
      {
        Header: "Sim_id",
        accessor: "id",
        width: 60,
        maxWidth: 100,
        id: "listOfSims_id",
        tableName: "listOfSims",
        link: true,
      },
      {
        Header: "ICC",
        accessor: "icc",
        width: 170,
        maxWidth: 210,
        id: "listOfSims_icc",
        tableName: "listOfSims",
        link: true,
      },
      {
        Header: "MSISDN",
        accessor: "msisdn",
        width: 110,
        maxWidth: 150,
        id: "listOfSims_msisdn",
        tableName: "listOfSims",
        link: true,
      },
      {
        Header: "Subscription",
        accessor: "subscription",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 120,
        maxWidth: 280,
        id: "listOfSims_subscription",
        tableName: "listOfSims",
        link: false,
      },
      {
        Header: "Operator",
        accessor: "networkOperator",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 110,
        maxWidth: 150,
        id: "listOfSims_operator",
        tableName: "listOfSims",
        link: false,
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 130,
        maxWidth: 170,
        id: "listOfSims_status",
        tableName: "listOfSims",
        link: false,
      },
      {
        Header: "Modem",
        accessor: "modem",
        width: 80,
        maxWidth: 120,
        id: "listOfSims_modem",
        tableName: "listOfSims",
        link: true,
      },
      {
        Header: "Location",
        accessor: "location",
        width: 250,
        id: "listOfSims_location",
        tableName: "listOfSims",
        link: true,
      },
      {
        Header: exportButton("ListOfSims"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 50,
        minWidth: 30,
      },
    ];
  } else if (props.tit === "devicesOverview") {
    exportFileName = "List of Devices";
    exportReduxName = "ListOfDevices";
    checkValueIndex = 0;
    className = "bigTable";
    rawColumns = [
      {
        Header: "Tag",
        accessor: "tag",
        width: 80,
        maxWidth: 120,
        id: "listOfDevices_tag",
        tableName: "listOfDevices",
        link: true,
      },
      {
        Header: "Serial",
        accessor: "serialNumber",
        width: 150,
        maxWidth: 200,
        id: "listOfDevices_serial",
        tableName: "listOfDevices",
        link: true,
      },
      {
        Header: "Type",
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 100,
        maxWidth: 160,
        id: "listOfDevices_type",
        tableName: "listOfDevices",
        link: false,
      },
      {
        Header: "Model",
        accessor: "model",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 160,
        maxWidth: 220,
        id: "listOfDevices_model",
        tableName: "listOfDevices",
        link: false,
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 90,
        maxWidth: 130,
        id: "listOfDevices_status",
        tableName: "listOfDevices",
        link: false,
      },
      {
        Header: "Location",
        accessor: "location",
        width: 350,
        id: "listOfDevices_location",
        tableName: "listOfDevices",
        link: true,
      },
      {
        Header: "Simcard_ICC",
        accessor: "simcard_icc",
        maxWidth: 210,
        minWidth: 160,
        id: "listOfDevices_icc",
        tableName: "listOfDevices",
        link: true,
      },
      {
        Header: exportButton("ListOfDevices"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "servicesOverview") {
    exportFileName = "List of Services";
    exportReduxName = "ListOfServices";
    checkValueIndex = 0;
    className = "bigTable";
    rawColumns = [
      {
        Header: "Service_id",
        accessor: "id",
        id: "listOfServices_serviceId",
        maxWidth: 90,
        width: 85,
        tableName: "listOfServices",
        link: true,
      },
      {
        Header: "Contract_id",
        id: "listOfServices_contract_id",
        accessor: "contractId",
        maxWidth: 90,
        width: 90,
        tableName: "listOfServices",
        link: false,
      },
      {
        Header: "User",
        accessor: "serviceDescription",
        id: "listOfServices_description",
        width: 200,
        tableName: "listOfServices",
        link: false,
      },
      {
        Header: "Address",
        accessor: "userAddress",
        id: "listOfServices_address",
        width: 250,
        tableName: "listOfServices",
        link: false,
      },
      {
        Header: "Customer",
        accessor: "customerName",
        id: "listOfServices_customer",
        width: 200,
        tableName: "listOfServices",
        link: false,
      },
      {
        Header: "Agent",
        accessor: "agentName",
        id: "listOfServices_agent",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        width: 150,
        tableName: "listOfServices",
        link: false,
      },
      {
        Header: "Status",
        accessor: "status",
        id: "listOfServices_status",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        width: 70,
        maxWidth: 110,
        tableName: "listOfServices",
        link: false,
      },
      {
        Header: "Configuration",
        accessor: "config",
        id: "listOfServices_configuration",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        // filterValue: [],
        width: 70,
        maxWidth: 110,
        tableName: "listOfServices",
        link: false,
      },
      {
        Header: exportButton("ListOfServices"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 50,
        minWidth: 30,
      },
    ];
  } else if (props.tit === "servicesContacts") {
    exportFileName = "List of Contacts";
    exportReduxName = "ListOfServiceContacts";
    checkValueIndex = 0;
    className = "bigTable";
    rawColumns = [
      {
        Header: "First_name",
        accessor: "firstName",
        id: "ListOfServiceContacts_first_name",
        // maxWidth: 300,
        width: 85,
        tableName: "ListOfServiceContacts",
        link: false,
      },
      {
        Header: "Last_name",
        accessor: "lastName",
        id: "ListOfServiceContacts_last_name",
        // maxWidth: 300,
        width: 85,
        tableName: "ListOfServiceContacts",
        link: false,
      },
      {
        Header: "Salutation",
        accessor: "salutation",
        id: "ListOfServiceContacts_salutation",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        maxWidth: 90,
        width: 85,
        tableName: "ListOfServiceContacts",
        link: false,
      },
      {
        Header: "Email",
        accessor: "email",
        id: "ListOfServiceContacts_email",
        // maxWidth: 250,
        width: 85,
        tableName: "ListOfServiceContacts",
        link: false,
      },
      {
        Header: "Language",
        accessor: "language",
        id: "ListOfServiceContacts_language",
        maxWidth: 90,
        width: 85,
        tableName: "ListOfServiceContacts",
        link: false,
      },
      {
        Header: "User_address",
        accessor: "usersAddress",
        id: "ListOfServiceContacts_usersAddress",
        // maxWidth: 300,
        width: 85,
        tableName: "ListOfServiceContacts",
        link: false,
      },
      {
        Header: "Org_Name",
        accessor: "orgName",
        id: "ListOfServiceContacts_orgName",
        // maxWidth: 300,
        width: 85,
        tableName: "ListOfServiceContacts",
        link: false,
      },
      {
        Header: "Org_Relation",
        accessor: "orgRelation",
        id: "ListOfServiceContacts_orgRelation",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        maxWidth: 150,
        width: 85,
        tableName: "ListOfServiceContacts",
        link: false,
      },
      {
        Header: exportButton("ListOfServiceContacts"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 50,
        minWidth: 30,
      },
    ];
  } else if (props.tit === "ordersOverview") {
    tableIdent = "ListOfDeliveries";
    exportFileName = "List of Orders";
    exportReduxName = "ListOfDeliveries";
    checkValueIndex = 0;
    className = "bigTable";
    rawColumns = [
      {
        Header: "Service_id",
        accessor: "service_id",
        id: "listOfDeliveries_serviceId",
        maxWidth: 80,
        width: 75,
        tableName: "listOfDeliveries",
        link: true,
      },
      {
        Header: "Delivery_id",
        accessor: "delivery_id",
        id: "listOfDeliveries_deliveryId",
        maxWidth: 80,
        width: 75,
        tableName: "listOfDeliveries",
        link: true,
      },
      {
        Header: "User",
        id: "listOfDeliveries_user",
        accessor: "user_name",
        width: 120,
        tableName: "listOfDeliveries",
        link: false,
      },
      {
        Header: "Description",
        id: "listOfDeliveries_description",
        accessor: "description",
        width: 120,
        tableName: "listOfDeliveries",
        link: false,
      },
      {
        Header: "Address",
        id: "listOfDeliveries_address",
        accessor: "address",
        width: 150,
        tableName: "listOfDeliveries",
        link: false,
      },
      {
        Header: "Delivery_type",
        id: "listOfDeliveries_deliveryType",
        accessor: "delivery_type",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        maxWidth: 120,
        width: 90,
        minWidth: 90,
        tableName: "listOfDeliveries",
        link: false,
      },
      {
        Header: "Wish_date",
        id: "listOfDeliveries_wish_date",
        accessor: "requested_date",
        maxWidth: 120,
        width: 90,
        minWidth: 90,
        tableName: "listOfDeliveries",
        link: false,
      },
      {
        Header: "Plan Date",
        id: "listOfDeliveries_planDate",
        accessor: "implementation_date_t",
        maxWidth: 120,
        width: 90,
        minWidth: 90,
        tableName: "listOfDeliveries",
        link: false,
      },
      {
        Header: "Status",
        id: "listOfDeliveries_status",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        accessor: "status",
        maxWidth: 100,
        width: 80,
        minWidth: 80,
        tableName: "listOfDeliveries",
        link: false,
      },
      {
        Header: "Responsible",
        id: "listOfDeliveries_responsible",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        accessor: "responsible",
        maxWidth: 85,
        width: 85,
        tableName: "listOfDeliveries",
        link: false,
      },
      {
        Header: exportButton("ListOfDeliveries"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 50,
        minWidth: 30,
      },
    ];
  } else if (props.tit === "deviceHistory") {
    exportFileName = `Device history (${details.tag_id})`;
    exportReduxName = "DeviceHistory";
    checkValueIndex = 0;
    className = "smallTable";
    rawColumns = [
      {
        Header: "Time",
        accessor: "timestamp",
        width: 120,
        maxWidth: 160,
        link: false,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 60,
        maxWidth: 120,
        link: false,
      },
      {
        Header: "Location",
        accessor: "location",
        width: 200,
        id: "assetHistory_location",
        link: true,
      },
      {
        Header: "Added_by",
        accessor: "addedBy",
        width: 140,
        maxWidth: 200,
        link: false,
      },
      {
        Header: exportButton("DeviceHistory"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "contractsSelect") {
    tableIdent = "contractsSelect";
    exportFileName = `Contracts selection`;
    exportReduxName = "ContractsSelection";
    checkValueIndex = 0;
    className = "smallTable";
    rawColumns = [
      {
        Header: "Contract",
        accessor: "contractId",
        width: 70,
        maxWidth: 70,
        link: false,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 100,
        // maxWidth: 120,
        link: false,
      },
      {
        Header: "User",
        accessor: "user",
        width: 120,
        // maxWidth: 120,
        link: false,
      },
      {
        Header: "Agent",
        accessor: "agent",
        width: 100,
        // maxWidth: 120,
        link: false,
      },
      // {
      //   Header: exportButton("DeviceHistory"),
      //   accessor: "none",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   maxWidth: 30,
      //   width: 30,
      // },
    ];
  } else if (props.tit === "contractsOrderlines") {
    tableIdent = "contractsOrderlines";
    exportFileName = `Contract Orderlines`;
    exportReduxName = "contracts Orderlines";
    checkValueIndex = 0;
    className = "smallTable2";
    rawColumns = [
      {
        Header: "Type",
        accessor: "type",
        width: 70,
        maxWidth: 70,
        link: false,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 90,
        maxWidth: 90,
        link: false,
      },
      {
        Header: "Unit",
        accessor: "item_unit",
        width: 90,
        maxWidth: 90,
        link: false,
      },
      {
        Header: "Start",
        accessor: "start_date_t",
        width: 100,
        maxWidth: 90,
        link: false,
      },
      {
        Header: "End",
        accessor: "end_date_t",
        width: 100,
        maxWidth: 90,
        link: false,
      },
      {
        Header: "Invoiced",
        accessor: "last_invoiced_t",
        width: 100,
        maxWidth: 90,
        link: false,
      },
      {
        Header: "Code",
        accessor: "item_id",
        width: 50,
        maxWidth: 50,
        link: false,
      },
      {
        Header: "Description",
        accessor: "item_description",
        width: 120,
        // maxWidth: 100,
        link: false,
      },
      {
        Header: "Quantity",
        accessor: "item_quantity",
        width: 70,
        maxWidth: 70,
        link: false,
      },
      {
        Header: "Price",
        accessor: "item_price",
        width: 70,
        maxWidth: 70,
        link: false,
      },
      // {
      //   Header: exportButton("DeviceHistory"),
      //   accessor: "none",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   maxWidth: 30,
      //   width: 30,
      // },
    ];
  } else if (props.tit === "contractsInvoicelines") {
    tableIdent = "contractsInvoicelines";
    exportFileName = `Contract Invoicelines`;
    exportReduxName = "contractsInvoicelines";
    checkValueIndex = 0;
    className = "smallTable2";
    rawColumns = [
      {
        Header: "Quantity",
        accessor: "item_quantity",
        width: 70,
        maxWidth: 70,
        link: false,
      },
      {
        Header: "Code",
        accessor: "item_id",
        width: 50,
        maxWidth: 50,
        link: false,
      },
      {
        Header: "Description",
        accessor: "item_description",
        width: 120,
        // maxWidth: 100,
        link: false,
      },
      {
        Header: "Unit",
        accessor: "item_unit",
        width: 90,
        maxWidth: 90,
        link: false,
      },
      {
        Header: "Price",
        accessor: "item_price",
        width: 70,
        maxWidth: 70,
        link: false,
      },
      {
        Header: "Total",
        accessor: "total",
        width: 90,
        maxWidth: 90,
        link: false,
      },
    ];
  } else if (props.tit === "invoicesPreselect") {
    tableIdent = "invoicesPreselect";
    exportFileName = `Invoices preselect`;
    exportReduxName = "invoicesPreselect";
    checkValueIndex = 0;
    className = "smallTable2";
    rawColumns = [
      // {
      //   Header: "Twinfield_ID",
      //   accessor: "twinfieldId",
      //   width: 70,
      //   maxWidth: 70,
      //   link: false,
      // },
      // {
      //   Header: "Customer",
      //   accessor: "customer",
      //   width: 150,
      //   maxWidth: 150,
      //   link: false,
      // },
      // {
      //   Header: "Contract_ID",
      //   accessor: "contractId",
      //   width: 50,
      //   maxWidth: 50,
      //   link: false,
      // },
      {
        Header: "Quantity",
        accessor: "item_quantity",
        // width: 25,
        maxWidth: 70,
        link: false,
      },
      {
        Header: "Code",
        accessor: "item_id",
        // width: 25,
        maxWidth: 50,
        link: false,
      },
      {
        Header: "Description",
        accessor: "item_description",
        minWidth: 120,
        width: 120,
        // maxWidth: 100,
        link: false,
      },
      {
        Header: " ",
        accessor: "copy",
        maxWidth: 20,

        link: false,
      },
      {
        Header: "Unit",
        accessor: "item_unit",
        // width: 25,
        maxWidth: 100,
        link: false,
      },
      {
        Header: "Price",
        accessor: "item_price",
        // width: 25,
        maxWidth: 70,
        link: false,
      },
      {
        Header: "Total",
        accessor: "total",
        // width: 90,
        maxWidth: 70,
        link: false,
      },
    ];
  } else if (props.tit === "unaprovedDeliveries") {
    exportFileName = `unaprovedDeliveries`;
    exportReduxName = "unaprovedDeliveries";
    checkValueIndex = 0;
    className = "smallTable2";
    rawColumns = [
      {
        Header: "Id",
        accessor: "deliveryId",
        width: 80,
        maxWidth: 80,
        link: false,
      },
      {
        Header: "Type",
        accessor: "type",
        width: 80,
        maxWidth: 80,
        link: false,
      },
      {
        Header: "Description",
        accessor: "desc",
        width: 100,
        link: false,
      },
      {
        Header: "Start",
        accessor: "startDate",
        width: 100,
        maxWidth: 110,
        id: "assetHistory_location",
        link: false,
      },
    ];
  } else if (props.tit === "unaprovedOrderlines") {
    exportFileName = `unaproved Orderlines`;
    exportReduxName = "unaprovedOrderlines";
    tableIdent = "unaprovedOrderlines";
    className = "smallTable2";
    rawColumns = [
      {
        Header: "Type",
        id: "unaprovedOrderlines_type",
        accessor: "type",
        width: 60,
        maxWidth: 80,
        link: false,
      },
      {
        Header: "Status",
        id: "unaprovedOrderlines_status",
        accessor: "status",
        width: 80,
        maxWidth: 80,
        link: false,
      },
      {
        Header: "Unit",
        id: "unaprovedOrderlines_unit",
        accessor: "unit",
        width: 80,
        maxWidth: 80,
        link: false,
      },
      {
        Header: "Start",
        id: "unaprovedOrderlines_start",
        accessor: "start_dis",
        width: 90,
        maxWidth: 90,
        link: false,
      },
      {
        Header: "End",
        id: "unaprovedOrderlines_end",
        accessor: "end_dis",
        width: 90,
        maxWidth: 90,
        link: true,
      },
      {
        Header: "Invoice",
        id: "unaprovedOrderlines_invoice",
        accessor: "invoice_dis",
        width: 90,
        maxWidth: 90,
        link: false,
      },
      {
        Header: "Code",
        id: "unaprovedOrderlines_code",
        accessor: "code",
        width: 50,
        maxWidth: 50,
        link: false,
      },
      {
        Header: "Description",
        id: "unaprovedOrderlines_description",
        accessor: "description",
        width: 120,
        // maxWidth: 80,
        link: false,
      },
      {
        Header: "Quantity",
        id: "unaprovedOrderlines_quantity",
        accessor: "quantity",
        width: 70,
        maxWidth: 70,
        link: false,
      },
      {
        Header: "Price",
        id: "unaprovedOrderlines_price",
        accessor: "price",
        width: 60,
        maxWidth: 60,
        link: false,
      },
    ];
  } else if (props.tit === "simHistory") {
    exportFileName = `Sim history (id:${details.id})`;
    exportReduxName = "SimHistory";
    checkValueIndex = 0;
    className = "smallTable";
    rawColumns = [
      {
        Header: "Time",
        accessor: "timestamp",
        width: 110,
        maxWidth: 150,
        link: false,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 50,
        maxWidth: 120,
        link: false,
      },
      {
        Header: "Subscription",
        accessor: "subscription",
        width: 50,
        link: false,
      },
      {
        Header: "Location",
        accessor: "location",
        id: "simHistory_location",
        width: 80,
        link: false,
      },
      {
        Header: "Modem",
        accessor: "modem",
        id: "simHistory_modem",
        width: 80,
        maxWidth: 80,
        link: false,
      },
      {
        Header: "Added_by",
        accessor: "addedBy",
        width: 140,
        maxWidth: 200,
        link: false,
      },
      {
        Header: exportButton("SimHistory"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "serviceHistory") {
    exportFileName = `Service ${serviceDetails.id} history`;
    exportReduxName = "ServiceHistory";
    checkValueIndex = 10;
    className = "midTable";
    rawColumns = [
      {
        id: "serviceHistory_Contract",
        Header: "Contract",
        accessor: "contract",
        width: 70,
        maxWidth: 110,
        link: true,
      },
      {
        id: "serviceHistory_User",
        Header: "User",
        accessor: "user.name",
        width: 150,
        link: true,
      },
      {
        id: "serviceHistory_Customer",
        Header: "Customer",
        accessor: "customer.name",
        width: 150,
        link: true,
      },
      {
        id: "serviceHistory_Agent",
        Header: "Agent",
        accessor: "agent.name",
        width: 100,
        maxWidth: 200,
        link: true,
      },
      {
        Header: "Type",
        accessor: "type",
        width: 60,
        maxWidth: 100,
        link: false,
      },
      {
        Header: "Description",
        accessor: "description",
        width: 120,
        maxWidth: 200,
        link: false,
      },
      {
        Header: "Configuration",
        accessor: "configuration",
        width: 130,
        maxWidth: 140,
        link: false,
      },
      {
        Header: "Bundle",
        accessor: "data_bundle",
        width: 60,
        maxWidth: 140,
        link: false,
      },
      {
        Header: "IP",
        accessor: "ip_address",
        width: 110,
        maxWidth: 140,
        link: false,
      },
      {
        Header: "Start",
        accessor: "start_t",
        width: 90,
        maxWidth: 140,
        link: false,
      },
      {
        Header: "Impl.",
        accessor: "implementation_t",
        width: 90,
        maxWidth: 140,
        link: false,
      },
      {
        Header: exportButton("ServiceHistory"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "ticketHistory") {
    exportFileName = `Service ${serviceDetails.id} ticket history`;
    exportReduxName = "ServiceTicketHistory";
    checkValueIndex = 0;
    className = "midTable";
    rawColumns = [
      {
        id: "ticketHistory_Reference",
        Header: "Reference",
        accessor: "ref",
        // width: 90,
        maxWidth: 75,
        link: true,
      },
      {
        id: "ticketHistory_Start",
        Header: "Start",
        accessor: "start",
        width: 90,
        maxWidth: 130,
        link: false,
      },
      {
        id: "ticketHistory_End",
        Header: "End",
        accessor: "end",
        width: 90,
        maxWidth: 130,
        link: false,
      },
      {
        id: "ticketHistory_Desc",
        Header: "Description",
        accessor: "desc",
        width: 120,
        // maxWidth: 200,
        link: false,
      },
      {
        id: "ticketHistory_Owner",
        Header: "Owner",
        accessor: "owner",
        width: 50,
        maxWidth: 50,
        link: false,
      },
      {
        id: "ticketHistory_Status",
        Header: "Status",
        accessor: "status",
        width: 60,
        maxWidth: 60,
        link: false,
      },
    ];
  } else if (props.tit === "ticketsOverview") {
    exportFileName = `Tickets overview`;
    exportReduxName = "TicketsOverview";
    checkValueIndex = 0;
    className = "bigTable";
    rawColumns = [
      {
        id: "ticketOverview_Reference",
        Header: "Reference",
        accessor: "ref",
        // width: 90,
        maxWidth: 75,
        link: true,
      },
      {
        id: "ticketOverview_User",
        Header: "User",
        accessor: "serviceDesc",
        width: 150,
        // maxWidth: 200,
        link: true,
      },
      {
        id: "ticketOverview_Start",
        Header: "Start",
        accessor: "start",
        width: 100,
        maxWidth: 130,
        link: false,
      },
      {
        id: "ticketOverview_End",
        Header: "End",
        accessor: "end",
        width: 100,
        maxWidth: 130,
        link: false,
      },
      {
        id: "ticketOverview_Description",
        Header: "Description",
        accessor: "desc",
        width: 200,
        // maxWidth: 200,
        link: true,
      },
      {
        id: "ticketOverview_Owner",
        Header: "Owner",
        accessor: "owner",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        width: 50,
        maxWidth: 50,
        link: false,
      },
      {
        id: "ticketOverview_Status",
        Header: "Status",
        accessor: "status",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        width: 60,
        maxWidth: 60,
        link: false,
      },

      {
        Header: exportButton("TicketsOverview"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "serivceAssetsHistory") {
    exportFileName = `Service ${serviceDetails.id} assets history`;
    exportReduxName = "ServiceAssetsHistory";
    checkValueIndex = 0;
    className = "midTable";
    rawColumns = [
      {
        Header: "Tag_id",
        accessor: "tag_id",
        width: 80,
        maxWidth: 80,
        id: "serviceAssets_Tag",
        link: true,
      },
      {
        Header: "Signal",
        accessor: "signal",
        width: 50,
        maxWidth: 50,
        id: "serviceAssets_signal",
        link: true,
        Cell: (row) => {
          row.cell.signalButton = true;
          return row.cell.value === "Online" ? (
            <img
              alt="antenna icon"
              src={antIco}
              width={"17px"}
              style={{ marginLeft: "12px" }}
            />
          ) : (
            ""
          );
        },
      },
      {
        Header: "Type",
        accessor: "type",
        width: 80,
        maxWidth: 100,
        link: false,
      },
      {
        Header: "Model",
        accessor: "model",
        width: 120,
        maxWidth: 160,
        link: false,
      },
      {
        Header: "Sim_ID",
        accessor: "simId",
        width: 60,
        maxWidth: 80,
        id: "serviceAssets_SimId",
        link: true,
      },
      {
        Header: "Sim_ICC",
        accessor: "simIcc",
        width: 160,
        maxWidth: 180,
        id: "serviceAssets_SimICC",
        link: true,
      },
      {
        Header: "Subscription",
        accessor: "simSubscription",
        width: 160,
        link: false,
      },
      {
        Header: "Network",
        accessor: "network",
        width: 160,
        link: false,
      },
      {
        Header: "Start",
        accessor: "start_t",
        width: 90,
        maxWidth: 140,
        link: false,
      },
      {
        Header: "End",
        accessor: "end_t",
        width: 90,
        maxWidth: 140,
        link: false,
      },
      {
        Header: exportButton("ServiceAssetsHistory"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "serivceContacts") {
    exportFileName = `Service ${serviceDetails.id} contacts`;
    exportReduxName = "ServiceContacts";
    checkValueIndex = 5;
    className = "midTable";
    rawColumns = [
      {
        Header: "Relation",
        accessor: "relation",
        width: 80,
        maxWidth: 120,
        link: false,
      },
      {
        Header: "Type",
        accessor: "contactType",
        width: 80,
        link: false,
      },
      {
        Header: "Name",
        accessor: "name",
        width: 120,
        link: false,
      },
      {
        Header: "Direct Phone",
        accessor: "directPhone",
        width: 120,
        maxWidth: 160,
        link: false,
      },
      {
        Header: "Mobile Phone",
        accessor: "mobilePhone",
        width: 120,
        maxWidth: 160,
        link: false,
      },
      {
        Header: "Email",
        accessor: "email",
        width: 120,
        link: false,
      },
      {
        Header: "Organisation",
        accessor: "organisation",
        width: 120,
        link: false,
      },
      {
        Header: exportButton("ServiceContacts"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "logsTable") {
    exportFileName = `logs`;
    exportReduxName = "logs";
    checkValueIndex = 5;
    className = "bigTable";
    rawColumns = [
      ...props.columns.map((column) => {
        if (column.filterStr === "SelectColumnFilter") {
          return {
            ...column,
            Filter: SelectColumnFilter,
            // filter: "includes",
          };
        } else {
          return column;
        }
      }),
      {
        Header: exportButton("logs"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "newAssets") {
    className = "smallTable";
    rawColumns = [
      {
        Header: "Tag",
        accessor: "tag",
        width: 80,
        maxWidth: 100,
        link: false,
      },
      {
        Header: "Serial",
        accessor: "serialNumber",
        width: 120,
        maxWidth: 160,
        link: false,
      },
      {
        Header: "Imei",
        accessor: "imei",
        width: 120,
        maxWidth: 160,
        link: false,
      },
      {
        Header: "Remark",
        accessor: "remark",
        width: 200,
        link: false,
      },
      {
        Header: " ",
        accessor: "button",
        minWidth: 83,
        width: 83,
        maxWidth: 83,
        link: false,
      },
    ];
  } else if (props.tit === "updateAssets") {
    className = "smallTable";
    rawColumns = [
      {
        Header: "Tag",
        accessor: "tag",
        width: 50,
        maxWidth: 100,
        link: false,
      },
      {
        Header: "Location",
        accessor: "dbLocation",
        width: 100,
        link: false,
      },
      {
        Header: "Status",
        accessor: "dbStatus",
        width: 50,
        maxWidth: 100,
        link: false,
      },
      {
        Header: "Remark",
        accessor: "remark",
        width: 100,
        link: false,
      },
      {
        Header: " ",
        accessor: "button",
        minWidth: 83,
        width: 83,
        maxWidth: 83,
        link: false,
      },
    ];
  } else if (props.tit === "newSims") {
    className = "smallTable";
    rawColumns = [
      {
        Header: "ICC",
        accessor: "icc",
        width: 180,
        maxWidth: 180,
        link: false,
      },
      {
        Header: "MSISDN",
        accessor: "msisdn",
        width: 80,
        maxWidth: 80,
        link: false,
      },
      {
        Header: "Pin",
        accessor: "pin",
        width: 50,
        maxWidth: 50,
        link: false,
      },
      {
        Header: "Puk",
        accessor: "puk",
        width: 80,
        maxWidth: 80,
        link: false,
      },
      {
        Header: "Remark",
        accessor: "remark",
        width: 100,
        link: false,
      },
      {
        Header: " ",
        accessor: "button",
        minWidth: 83,
        width: 83,
        maxWidth: 83,
        link: false,
      },
    ];
  } else if (props.tit === "updateSims") {
    className = "smallTable";
    rawColumns = [
      {
        Header: "ID",
        accessor: "simId",
        width: 30,
        maxWidth: 50,
        link: false,
      },
      {
        Header: "ICC",
        accessor: "icc",
        width: 150,
        maxWidth: 170,
        link: false,
      },
      {
        Header: "MSISDN",
        accessor: "msisdn",
        width: 80,
        maxWidth: 100,
        link: false,
      },
      {
        Header: "Remark",
        accessor: "remark",
        width: 100,
        link: false,
      },
      {
        Header: " ",
        accessor: "button",
        minWidth: 83,
        width: 83,
        maxWidth: 83,
        link: false,
      },
    ];
  } else if (props.tit === "poolsDataUsage") {
    className = "bigTable";
    exportFileName = `Pools data usage`;
    exportReduxName = "PoolsDataUsage";
    checkValueIndex = 0;
    rawColumns = [
      {
        Header: "Pool_id",
        accessor: "id",
        width: 60,
        maxWidth: 80,
        id: "poolsDataUsage_id",
        tableName: "poolsDataUsage",
        link: true,
      },
      {
        Header: "Pool_name",
        accessor: "name",
        width: 250,
        // maxWidth: 500,
        id: "poolsDataUsage_name",
        tableName: "poolsDataUsage",
        link: true,
      },
      {
        Header: "Sims",
        accessor: "sims",
        width: 80,
        maxWidth: 120,
        id: "poolsDataUsage_sims",
        tableName: "poolsDataUsage",
        link: false,
      },
      {
        Header: "Size_GB",
        accessor: "bundelSize",
        width: 80,
        maxWidth: 120,
        id: "poolsDataUsage_size",
        tableName: "poolsDataUsage",
        link: false,
      },
      {
        Header: "Used_GB",
        accessor: "totalGB",
        width: 80,
        maxWidth: 120,
        id: "poolsDataUsage_usedGB",
        tableName: "poolsDataUsage",
        link: false,
      },
      {
        Header: "Used_%",
        accessor: "totalPer",
        width: 80,
        maxWidth: 120,
        id: "poolsDataUsage_usedPer",
        tableName: "poolsDataUsage",
        link: false,
      },
      {
        Header: "10_Day_Alert",
        accessor: "tenth_day_alert_value",
        width: 80,
        maxWidth: 120,
        id: "poolsDataUsage_10Day",
        tableName: "poolsDataUsage",
        link: false,
      },
      {
        Header: "20_Day_Alert",
        accessor: "twentieth_day_alert_value",
        width: 80,
        maxWidth: 120,
        id: "poolsDataUsage_20Day",
        tableName: "poolsDataUsage",
        link: false,
      },
      {
        Header: "Total_Alert",
        accessor: "total_alert_value",
        width: 80,
        maxWidth: 120,
        id: "poolsDataUsage_total",
        tableName: "poolsDataUsage",
        link: false,
      },
      {
        Header: exportButton("PoolsDataUsage"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "poolTopUsage") {
    className = "midTable";
    exportFileName = `Pool top users`;
    exportReduxName = "PoolTopUsers";
    checkValueIndex = 0;
    rawColumns = [
      {
        Header: "Service_id",
        accessor: "service_id",
        width: 60,
        maxWidth: 100,
        id: "poolTopUsage_serviceId",
        link: true,
      },
      {
        Header: "Name",
        accessor: "name",
        width: 150,
        // maxWidth: 120,
        id: "poolTopUsage_name",
        link: true,
      },
      {
        Header: "Bundel",
        accessor: "bundel",
        width: 60,
        maxWidth: 120,
        link: false,
      },
      {
        Header: "Usage",
        accessor: "usage",
        width: 60,
        maxWidth: 120,
        id: "poolTopUsage_usage",
        link: true,
      },
      {
        Header: exportButton("PoolTopUsers"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "dirtyOrders") {
    exportFileName = `Dirty orders list`;
    exportReduxName = "DirtyOrdersList";
    checkValueIndex = 0;
    defaultSorted = [
      {
        id: "Reported on",
        desc: true,
      },
    ];
    className = "bigTable";
    rawColumns = [
      {
        Header: "Description",
        accessor: "name",
        width: 200,
        id: "dirtyOrders_description",
        tableName: "dirtyOrders",
        link: true,
      },
      {
        Header: "Resp",
        accessor: "responsibleInit",
        Filter: SelectColumnFilter,
        filter: "includes",
        maxWidth: 50,
        width: 50,
        id: "dirtyOrders_responsibleInit",
        tableName: "dirtyOrders",
      },
      {
        Header: "Issue",
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 200,
        id: "dirtyOrders_issue",
        tableName: "dirtyOrders",
      },
      {
        Header: "WebCrm_ID",
        accessor: "webcrm_id",
        maxWidth: 100,
        width: 100,
        id: "dirtyOrders_webcrmId",
        tableName: "dirtyOrders",
      },
      {
        Header: "Category",
        accessor: "table",
        Filter: SelectColumnFilter,
        filter: "includes",
        maxWidth: 100,
        width: 100,
        id: "dirtyOrders_category",
        tableName: "dirtyOrders",
      },

      {
        Header: "Reported on",
        accessor: "reported_t",
        maxWidth: 200,
        width: 120,
        id: "dirtyOrders_reportedOn",
        tableName: "dirtyOrders",
      },
      {
        Header: exportButton("DirtyOrdersList"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "maintenanceTable") {
    exportFileName = `Network Maintenance ${props.network}`;
    exportReduxName = `NetworkMaintenance${props.network}`;
    checkValueIndex = 0;
    defaultSorted = [
      {
        id: "Reported on",
        desc: true,
      },
    ];
    className = "bigTable";
    rawColumns = [
      {
        Header: "Service id",
        accessor: "serviceId",
        // width: 200,
        id: "maintenanceTable_serviceId",
        tableName: "maintenanceTable",
        link: true,
      },
      {
        Header: "User",
        accessor: "serviceDesc",
        // Filter: SelectColumnFilter,
        // filter: "includes",
        // maxWidth: 50,
        // width: 50,
        id: "maintenanceTable_responsibleInit",
        tableName: "maintenanceTable",
      },
      {
        Header: "Date",
        accessor: "swapDate",
        Filter: SelectColumnFilter,
        filter: "includes",
        //   // width: 200,
        id: "maintenanceTable_swapDate",
        tableName: "maintenanceTable",
      },
      {
        Header: "Week",
        accessor: "swapWeek",
        Filter: SelectColumnFilter,
        filter: "includes",
        // maxWidth: 100,
        // width: 100,
        id: "maintenanceTable_webcrmId",
        tableName: "maintenanceTable",
      },
      {
        Header: "Type",
        accessor: "swaptypeDesc",
        Filter: SelectColumnFilter,
        filter: "includes",
        // maxWidth: 100,
        // width: 100,
        id: "maintenanceTable_category",
        tableName: "maintenanceTable",
      },

      {
        Header: exportButton(`NetworkMaintenance${props.network}`),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "serviceDataUsageTable") {
    exportFileName = `${serviceUsageTimestamps.service.id} - ${serviceUsageTimestamps.service.description} Data Usage`;
    exportReduxName = "ServiceDataUsage";
    checkValueIndex = 0;
    className = "bigTable";
    // serviceDataUsage_time: "",
    // serviceDataUsage_serviceId: "",
    // serviceDataUsage_wanTag: "",
    // serviceDataUsage_total: "",
    rawColumns = [
      {
        Header: "Time",
        accessor: "time",
        // minWidth: 220,
        // width: 220,
        // maxWidth: 220,
      },
      {
        Header: "Service",
        accessor: "serviceId",
        // Filter: SelectColumnFilter,
        // filter: "includes",
        id: "serviceDataUsage_serviceId",
        tableName: "serviceDataUsage",
        // minWidth: 220,
        // width: 220,
        // maxWidth: 220,
      },
      {
        Header: "Router",
        accessor: "routerTag",
        Filter: SelectColumnFilter,
        filter: "includes",
        id: "serviceDataUsage_routerTag",
        tableName: "serviceDataUsage",
        // minWidth: 220,
        // width: 220,
        // maxWidth: 220,
      },
      {
        Header: "Wan",
        accessor: "wanTag",
        Filter: SelectColumnFilter,
        filter: "includes",
        id: "serviceDataUsage_wanTag",
        tableName: "serviceDataUsage",
        // minWidth: 220,
        // width: 220,
        // maxWidth: 220,
      },
      {
        Header: "Total GB",
        accessor: "total",
        // minWidth: 220,
      },
      {
        Header: exportButton("ServiceDataUsage"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "serivceUsageAlerts") {
    exportFileName = "High usage";
    exportReduxName = "HighUsage";
    checkValueIndex = 0;
    defaultFilterValue = props.thisMonth;
    className = "bigTable";
    customFilters = [{ id: "month", value: props.thisMonth }];
    rawColumns = [
      {
        Header: "Service",
        accessor: "service",
        width: 200,
        // maxWidth: 250,
        id: "highUsage_service",
        tableName: "highUsage",
        link: true,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 200,
        // maxWidth: 250,
        id: "highUsage_customer",
        tableName: "highUsage",
        link: true,
      },
      {
        Header: "Bundle GB",
        accessor: "bundle_gb",
        width: 100,
        maxWidth: 140,
        id: "highUsage_bundle",
        tableName: "highUsage",
        link: false,
      },
      {
        Header: "Current GB",
        accessor: "current_gb",
        width: 100,
        maxWidth: 140,
        id: "highUsage_current",
        tableName: "highUsage",
        link: true,
      },
      {
        Header: "Month",
        accessor: "month",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 80,
        maxWidth: 120,
        id: "highUsage_month",
        tableName: "highUsage",
        link: false,
        defFilt: props.thisMonth,
      },
      {
        Header: "Reported",
        accessor: "reported_on",
        width: 100,
        maxWidth: 140,
        id: "highUsage_repoted",
        tableName: "highUsage",
        link: false,
      },
      {
        Header: "Done",
        accessor: "done",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 60,
        maxWidth: 60,
        id: "highUsage_done",
        tableName: "highUsage",
        Cell: (row) => {
          row.cell.checkButton = true;
          return row.cell.value === "Yes" ? (
            <img alt="checked" src={checkedImg} width={"16px"} />
          ) : (
            <img alt="checked" src={unCheckedImg} width={"16px"} />
          );
        },
      },
      {
        Header: exportButton("HighUsage"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "koffersMap") {
    exportFileName = "Koffers";
    exportReduxName = "Koffers";
    checkValueIndex = 0;
    className = "bigTable";
    rawColumns = [
      {
        Header: "Customer",
        accessor: "customer_name",
        id: "highUsage_customer",
        tableName: "highUsage",
        link: true,
      },

      {
        Header: "Done",
        accessor: "visibility",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 60,
        maxWidth: 60,
        id: "highUsage_done",
        tableName: "highUsage",
        Cell: (row) => {
          row.cell.checkButton = true;
          row.cell.checkDesc = "koffers";
          return row.cell.value === "Yes" ? (
            <img alt="checked" src={checkedImg} width={"16px"} />
          ) : (
            <img alt="checked" src={unCheckedImg} width={"16px"} />
          );
        },
      },
      {
        Header: exportButton("HighUsage"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 30,
        width: 30,
      },
    ];
  } else if (props.tit === "antennaBandsTable") {
    tableIdent = "antennaSignalBands";
    className = "antennaBandsTable";
    rawColumns = [
      {
        accessor: "active",
        Cell: (row) => {
          if (row.cell.value === "Yes") {
            return "X";
          } else {
            return null;
          }
        },
        minWidth: 0,
        width: 1,
      },
      {
        Header: "dB",
        accessor: "rsrp",
        width: 2,
      },

      {
        Header: "enodeB",
        accessor: "enodeB",
        width: 4,
      },
      {
        Header: "PCC",
        accessor: "pcc",
        width: 4,
      },
      {
        Header: "SCC1",
        accessor: "scc1",
        width: 4,
      },
      {
        Header: "SCC2",
        accessor: "scc2",
        width: 4,
      },
      {
        Header: "SCC3",
        accessor: "scc3",
        width: 4,
      },
      {
        Header: "SCC4",
        accessor: "scc4",
        width: 4,
      },
    ];
  } else if (props.tit === "inventoryCheck") {
    exportFileName = "Inventory Check";
    exportReduxName = "InventoryCheck";
    checkValueIndex = 0;
    className = "bigTable";
    rawColumns = [
      {
        Header: "Tag_id",
        accessor: "tagId",
        id: "inventoryCheck_tagId",
        maxWidth: 85,
        width: 85,
        tableName: "inventoryCheck",
        link: true,
      },
      {
        Header: "Type",
        id: "inventoryCheck_type",
        accessor: "type",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        maxWidth: 130,
        width: 85,
        tableName: "inventoryCheck",
        link: false,
      },
      {
        Header: "Model",
        id: "inventoryCheck_model",
        accessor: "model",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        width: 85,
        maxWidth: 250,
        tableName: "inventoryCheck",
        link: false,
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        id: "inventoryCheck_status",
        width: 85,
        maxWidth: 130,
        tableName: "inventoryCheck",
        link: false,
      },
      {
        Header: "Sim_ICC",
        accessor: "simIcc",
        id: "inventoryCheck_simIcc",
        width: 170,
        maxWidth: 170,
        tableName: "inventoryCheck",
        link: true,
      },
      {
        Header: "Location",
        accessor: "location",
        id: "inventoryCheck_location",
        width: 170,
        tableName: "inventoryCheck",
        link: true,
      },
      {
        Header: "Service_status",
        accessor: "serviceStatus",
        id: "inventoryCheck_serviceStatus",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 105,
        maxWidth: 105,
        tableName: "inventoryCheck",
        link: false,
      },
      {
        Header: "Grafana",
        accessor: "grafana",
        id: "inventoryCheck_grafana",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 125,
        maxWidth: 125,
        tableName: "inventoryCheck",
        link: false,
      },
      {
        Header: "Controller",
        accessor: "controller",
        id: "inventoryCheck_controller",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 100,
        maxWidth: 100,
        tableName: "inventoryCheck",
        link: false,
      },
      {
        Header: "Contr. status",
        accessor: "controllerStatus",
        id: "inventoryCheck_controllerStatus",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 125,
        maxWidth: 125,
        tableName: "inventoryCheck",
        link: false,
      },
      {
        Header: "Maintenance",
        accessor: "maintenance",
        id: "inventoryCheck_maintenance",
        Filter: SelectColumnFilter,
        filter: "includes",
        width: 100,
        maxWidth: 100,
        tableName: "inventoryCheck",
        link: false,
      },
      {
        Header: exportButton("InventoryCheck"),
        accessor: "none",
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 50,
        minWidth: 30,
      },
    ];
  }
  const columns = React.useMemo(() => rawColumns, [rawColumns]);
  const filters = React.useMemo(() => customFilters, [customFilters]);
  // const tabletype =React.useMemo(() => customFilters, [customFilters])
  const data = React.useMemo(() => props.data, [props]);
  return (
    <Table
      wrapperRef={props.wrapperRef}
      topBarRef={props.topBarRef}
      tabletype={props.tabletype}
      defaultFilterValue={defaultFilterValue}
      tableIdent={tableIdent}
      checkValueIndex={checkValueIndex}
      exportFileName={exportFileName}
      exportReduxName={exportReduxName}
      initialState={filters}
      columns={columns}
      data={data}
      className={className}
      defaultSorted={defaultSorted}
    />
  );
}

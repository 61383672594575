import "../../css/services.css";
import KoffersMap from "../koffersMap";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import logo from "../../geuzenetlog.png";
import NewTable from "../../components/newTable/newTable";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

import { getAllKoffers } from "../../store/actions/services";
import { selectKoffers } from "../../store/selectors/services";

const validUser = (user) => {
  console.log(user);
  return user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
};
function Koffers() {
  const appUser = useSelector(selectAppUser);
  const navigation = useSelector(selectNavigation);

  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const koffers = useSelector(selectKoffers);

  const headerRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Services", "Koffers", "list"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (koffers.length === 0 && validUser(appUser)) {
        dispatch(getAllKoffers());
      }
    };
    getItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigation.menuSub, navigation.menuMain, koffers]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className={koffers.length === 0 ? "Koffers loading" : "Koffers"}>
        <>
          <div className="topBar"></div>
          <div className="koffersMainWrapper">
            <KoffersMap />
            <div className="bigTableWrapper" ref={headerRef}>
              {koffers.length > 0 ? (
                <NewTable
                  wrapperRef={headerRef}
                  tit={"koffersMap"}
                  tabletype={"mid"}
                  data={koffers.map((line) => {
                    return {
                      ...line,
                      visibility: line.visibility ? "Yes" : "No",
                    };
                  })}
                  width={
                    headerRef.current ? headerRef.current.clientWidth : 100
                  }
                />
              ) : (
                <img className="landingLogo" src={logo} alt="company logo" />
              )}
            </div>
          </div>
        </>
      </div>
    );
  }
}

export default Koffers;

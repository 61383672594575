import "../../css/ServiceDetails.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "../../geuzenetlog.png";
import NewTable from "../../components/newTable/newTable";
import { getServiceDetails, newTicketReq } from "../../store/actions/services";
import { useNavigate } from "react-router-dom";
import { selectServiceDetails } from "../../store/selectors/services";
import { selectExportTables } from "../../store/selectors/tableXlslExport";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
} from "../../store/selectors/navigation";

import { getModemsTags } from "../../store/actions/admin";
import { selectOnlineModems } from "../../store/selectors/admin";

function PaperComponent(PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...PaperProps} />
    </Draggable>
  );
}

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
const apiUrl = "https://api.webcrm.com/";
const loginPath = "Auth/ApiLogin";
const apiToken = process.env.REACT_APP_WEBCRM_API_URL;
let webcrmLogin;

function ServiceDetails() {
  const [login, setLogin] = useState();

  const appUser = useSelector(selectAppUser);
  if (validUser(appUser) && !login) {
    webcrmLogin = async () => {
      try /*login to api*/ {
        let loginRes = await axios.post(
          `${apiUrl}${loginPath}`,
          {},
          {
            headers: {
              authCode: apiToken,
            },
          }
        );
        setLogin(loginRes);
      } catch (e) {
        console.log(e);
      }
    };
  }
  const exportTables = useSelector(selectExportTables);

  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openDelivery, setOpenDelivery] = React.useState(false);
  const [openTicket, setOpenTicket] = React.useState(false);

  const handleClickOpen = (popupType) => {
    if (popupType === "delivery") {
      setOpenDelivery(true);
    } else if (popupType === "ticket") {
      setOpenTicket(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDelivery(false);
      setOpenTicket(false);
    }
  };

  const navigate = useNavigate();
  const headerRef = useRef();
  const panelARef = useRef();
  const panelBRef = useRef();
  const navigation = useSelector(selectNavigation);
  const details = useSelector(selectServiceDetails);
  const onlineModems = useSelector(selectOnlineModems);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [item, setItem] = useState({});
  const isBigScreen2 = useMediaQuery({ query: "(min-width: 983px)" });

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(
          setCategories(
            "Services",
            `${details.description ? details.description : id}`,
            "details"
          )
        );
      }
    };
    setMenu(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appUser, details]);
  useEffect(() => {
    setItem({});
    dispatch(getAppUser());
    if (validUser(appUser) && (!details.id || details.id !== id)) {
      dispatch(getModemsTags());
      dispatch(getServiceDetails("Overview", id));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigation.menuSub]);
  const resetItem = () => {
    setItem({
      ...item,
      id: details.id,
      influxService: details.influxService,
      description: details.description,
      user: details.user,
      customer: details.customer,
      agent: details.agent,
      configuration: details.configuration,
      ipAddress: details.ipAddress,
      status: details.status,
      dataBundle: details.dataBundle,
      fullHistory: details.fullHistory,
      uisp: details.uisp,
      unifi: details.unifi,
    });
  };

  if (appUser.webcrmId && !login) {
    webcrmLogin();
  }
  const customTimeFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear(),
      t = date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
    return `${y}-${m}-${d} ${t}`;
  };

  const customDateFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear();
    return `${y}-${m}-${d}`;
  };
  const [newDelRequest, setnewDelRequest] = useState({});
  const clearDelivery = () => {
    setnewDelRequest({
      DeliveryAssignedTo: appUser.webcrmId,
      DeliveryDescription: "",
      DeliveryId: 0,
      DeliveryOpportunityCustom1:
        details.customer && details.user
          ? details.customer.organisation_id === details.user.organisation_id
            ? "Customer is user"
            : "Another user"
          : "-- Select --",
      DeliveryOpportunityCustom3: details.user
        ? details.user.organisation_id
        : "",
      DeliveryOpportunityCustom4: details.partner_fee,
      DeliveryOpportunityCustom8: details.service_desc_extra,
      DeliveryOpportunityCustom11: "",
      DeliveryOpportunityCustom12: 0,
      DeliveryOpportunityCustom13: "-- Select --",
      DeliveryOrderDate: new Date(),
      DeliveryOrganisationId: details.customer
        ? details.customer.organisation_id
        : 0,
      DeliveryPersonId: 0,
      DeliveryOpportunityPersonId: 0,
      DeliveryRisk: "NoRisk",
      DeliverySpentTime: "00:00:00",
      DeliveryStatus: "New",
      DeliveryPlus1: "",
      DeliveryPlus5: "-- Select --",
      DeliveryPlus8: "0",
      DeliveryPlus10: "-- Select --",
      DeliveryCustom1: details.id,
      DeliveryCustom2: "-- Select --",
      DeliveryCustom3: "",
      DeliveryCustom4: "-- Select --",
      DeliveryCustom5: "-- Select --",
      DeliveryCustom6: "",
      DeliveryCustom8: "",
      DeliveryCustom9: `${details.contract}`,
      DeliveryCustom10: "-- Select --",
      DeliveryCustom13: "-- Select --",
      DeliveryCustom14: "-- nvt --",
    });
  };
  const [newTicket, setnewTicket] = useState({});
  const clearTicket = () => {
    setnewTicket({
      serviceId: details.id,
      serviceDesc: `${details.description ? details.description : ""}`,
      date: `${customDateFormat(new Date(), undefined)}`,
      subTitle: `${
        details.description ? details.description : ""
      } ${customDateFormat(new Date(), undefined)}`,
      pageTitle: `${details.id} - ${
        details.description ? details.description : ""
      } support ${customDateFormat(new Date(), undefined)}`,
      pageDesc: "",
      dateTime: `${customTimeFormat(new Date(), undefined)}`,
      reporter: [],
      serviceAddress: [
        `${details.user.address_street} ${details.user.address_house_number}${
          details.user.address_suffix ? details.user.address_suffix : ""
        }`,
        `${details.user.address_postcode} ${
          details.user.address_city
        }, ${details.user.address_country.slice(5)}`,
      ],
      linkToService: window.location.href,
      serviceOrgs: [
        `User: ${details.user.name ? details.user.name : ""}`,
        `Customer: ${details.customer.name ? details.customer.name : ""}`,
        `Agent: ${details.agent.name ? details.agent.name : ""}`,
      ],
      serviceConf: [
        `Configuration: ${details.configuration}`,
        `IP address: ${details.ipAddress}`,
        `Data bundle: ${details.dataBundle}`,
      ],
      serviceAssets: details.assetsHistory
        ? details.assetsHistory
            .filter((asset) => asset.end_t === null)
            .map(
              (asset) =>
                `${asset.tag_id} (${asset.model})${
                  asset.simIcc ? ` Sim: ${asset.simIcc} (${asset.network})` : ``
                }`
            )
        : [],
    });
  };
  if (Object.keys(details).length !== 0 && !item.id) {
    resetItem();
    clearDelivery();
    clearTicket();
  }

  const dataLine = (desc, value, onClick) => {
    return (
      <div className="dasLine">
        <div className="dasDesc">{desc}</div>
        <div className="dasValue" onClick={onClick}>
          {value}
        </div>
      </div>
    );
  };
  const stringCut = (string, stringLength) => {
    const allParts = string.split(" ");
    let newIndex = 0;
    let newArr = [];
    for (let part of allParts) {
      if (
        `${newArr[newIndex] ? newArr[newIndex] : ""}${part} `.length >
        stringLength
      ) {
        newIndex++;
      }
      newArr[newIndex] = `${newArr[newIndex] ? newArr[newIndex] : ""}${part} `;
    }
    return newArr;
  };
  let userAddress;
  if (item.user) {
    userAddress = `${item.user.address_street} ${
      item.user.address_house_number
    }${item.user.address_suffix ? item.user.address_suffix : ""}, ${
      item.user.address_postcode
    } ${item.user.address_city} ${item.user.address_country.slice(5)}`;
  }
  console.log(item);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className="ServiceDetailsWrapper">
        <div className="serDetails">
          <div className="ServiceDetails">
            <div className="topBar"></div>
            <div>
              <button
                className={
                  item.id ? "serviceUsageButton" : "serviceUsageButton inactive"
                }
                onClick={(e) => {
                  if (item.id) {
                    navigate(`/serviceUsage/${item.id}`);
                  }
                }}
              >
                Data usage
              </button>
              <button
                className={
                  item.id
                    ? item.uisp.uispSiteData
                      ? "serviceUsageButton"
                      : "sdbInactive"
                    : "sdbInactive"
                }
                onClick={(e) => {
                  if (item.uisp.uispSiteData) {
                    window.open(
                      `${item.uisp.controller.url}/nms/sites/${item.uisp.uispSiteData.id}/devices`
                    );
                  }
                }}
              >
                {item.uisp
                  ? item.uisp.uispSiteData
                    ? item.uisp.controller.name
                    : "No uisp site"
                  : "No uisp cont."}
              </button>

              <button
                className={
                  item.id
                    ? item.unifi !== "-"
                      ? "serviceUsageButton"
                      : "sdbInactive"
                    : "sdbInactive"
                }
                onClick={(e) => {
                  if (item.unifi !== "-") {
                    if (item.unifi.site) {
                      window.open(
                        `${item.unifi.url}:8443/manage/site/${item.unifi.site}/dashboard`
                      );
                    }
                  }
                }}
              >
                {item.unifi !== "-"
                  ? item.unifi && item.id
                    ? item.unifi.name
                    : "No unifi site"
                  : "No unifi site"}
              </button>

              <button
                className={
                  item.influxService ? "serviceUsageButton" : "sdbInactive"
                }
                onClick={(e) => {
                  if (item.influxService) {
                    console.log(
                      `${item.id} - ${item.description ? item.description : ""}`
                    );
                    // if (item.unifi.site) {
                    window.open(
                      `https://monitoring.geuzenet.nl:3003/d/fdp1ra5h5z5kwa/service-overview?orgId=1&refresh=5s&var-allHost=All&var-allCustomer=All&var-allServiceId=All&var-serviceId=All&var-host=All&var-deviceType=All&var-latency_addr=All&var-description=${
                        item.id
                      } - ${item.description ? item.description : ""}`
                    );
                    // }
                  }
                }}
              >
                {item.influxService ? "Grafana" : "No Grafana"}
              </button>

              <button
                className={
                  item.id && appUser.webcrmId && details.customer
                    ? "serviceUsageButton"
                    : "sdbInactive"
                }
                onClick={(e) => {
                  if (item.id && appUser.webcrmId && details.customer) {
                    handleClickOpen("delivery");
                  }
                }}
              >
                New delivery
              </button>

              <button
                className={
                  item.id && appUser.confluenceId
                    ? "serviceUsageButton"
                    : "sdbInactive"
                }
                onClick={(e) => {
                  if (item.id && appUser.confluenceId) {
                    handleClickOpen("ticket");
                  }
                }}
              >
                New ticket
              </button>
            </div>
            {item.id && userAddress ? (
              <div
                className={
                  isBigScreen2 ? "globalSelection wide" : "globalSelection"
                }
              >
                <div
                  className="globalSelectionGroup"
                  ref={panelARef}
                  onClick={(e) =>
                    navigator.clipboard.writeText(e.target.textContent)
                  }
                >
                  {stringCut(userAddress ? userAddress : "", 39).map(
                    (line, i) => dataLine(i === 0 ? "Address:" : "", line)
                  )}
                  {stringCut(item.customer ? item.customer.name : "", 39).map(
                    (line, i) => dataLine(i === 0 ? "Customer:" : "", line)
                  )}
                  {stringCut(
                    item.user
                      ? item.user.organisation_id !==
                        item.customer.organisation_id
                        ? item.user.name
                        : "The customer is the user"
                      : "",
                    39
                  ).map((line, i) => dataLine(i === 0 ? "User:" : "", line))}
                  {stringCut(
                    item.agent ? (item.agent.name ? item.agent.name : "") : "",
                    39
                  ).map((line, i) => dataLine(i === 0 ? "Agent:" : "", line))}
                </div>
                <div className="globalSelectionSpacer"></div>
                <div className="globalSelectionGroup" ref={panelBRef}>
                  {stringCut(
                    `${item.id} - ${item.description ? item.description : ""}`,
                    39
                  ).map((line, i) => dataLine(i === 0 ? "Service:" : "", line))}
                  {dataLine(
                    "Status:",
                    item.status.status.charAt(0).toUpperCase() +
                      item.status.status.slice(1)
                  )}
                  {dataLine("Configuration:", item.configuration)}
                  {dataLine("IP address:", item.ipAddress)}
                  {dataLine("Data bundle:", item.dataBundle)}
                </div>
              </div>
            ) : (
              <img className="landingLogo ll" src={logo} alt="company logo" />
            )}
            <>
              {details.fullHistory ? (
                <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>
                  Service history
                </h2>
              ) : (
                ""
              )}
              {item.fullHistory ? (
                <div className="midTableWrapper" ref={headerRef}>
                  <NewTable
                    tabletype={"small"}
                    tit="serviceHistory"
                    data={item.fullHistory.map((line) => {
                      return {
                        ...line,
                        start_t: line.start_t
                          ? customDateFormat(new Date(line.start_t), undefined)
                          : null,
                        implementation_t: line.implementation_t
                          ? customDateFormat(
                              new Date(line.implementation_t),
                              undefined
                            )
                          : null,
                      };
                    })}
                    width={
                      headerRef.current ? headerRef.current.clientWidth : 100
                    }
                  />
                </div>
              ) : (
                ""
              )}
              {item.fullHistory ? (
                <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>
                  Ticket history
                </h2>
              ) : (
                ""
              )}
              {item.fullHistory && details.supportPages.length > 0 ? (
                <div className="midTableWrapper" ref={headerRef}>
                  <NewTable
                    tabletype={"small"}
                    tit="ticketHistory"
                    data={details.supportPages.map((page) => {
                      return {
                        ...page,
                        start: page.start
                          ? customTimeFormat(new Date(page.start), undefined)
                          : null,
                        end: page.end
                          ? customTimeFormat(new Date(page.end), undefined)
                          : null,
                      };
                    })}
                    width={
                      headerRef.current ? headerRef.current.clientWidth : 100
                    }
                  />
                </div>
              ) : item.fullHistory && details.supportPages.length === 0 ? (
                "No existing tickets"
              ) : (
                ""
              )}
              {details.fullHistory ? (
                <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>
                  Service assets history
                </h2>
              ) : (
                ""
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Dialog
                  open={openDelivery}
                  onClose={handleClose}
                  PaperComponent={PaperComponent}
                  sx={{
                    borderRadius: "20px",
                  }}
                >
                  <DialogTitle
                    sx={{
                      minWidth: 400,
                      padding: 0,
                      maxWidth: 1000,
                      backgroundColor: "#35647e",
                      color: "white",
                      textAlign: "center",
                      marginBottom: 2,
                      fontFamily: "Quenda, serif",
                    }}
                    style={{ cursor: "move" }}
                    id="draggable-dialog-title"
                  >
                    New delivery
                  </DialogTitle>
                  <DialogContent
                    sx={{ minWidth: 400, padding: 1, maxWidth: 1000, top: 5 }}
                  >
                    <Box
                      component="form"
                      sx={{
                        marginTop: 1,
                        marginBottom: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "80%",
                          zIndex: 9999,
                          alignItems: "center",
                        }}
                        size="small"
                      >
                        <InputLabel
                          id="typeLabel"
                          sx={{
                            minWidth: 20,
                            zIndex: 99999,
                            bgcolor: "#fff",
                            // marginTop: "-9px",
                          }}
                        >
                          Delivery type
                        </InputLabel>
                        <Select
                          id="selectType"
                          size="small"
                          labelId="typeLabel"
                          value={newDelRequest.DeliveryCustom8}
                          sx={{ width: "100%", height: "40px" }}
                          onChange={(e) =>
                            setnewDelRequest({
                              ...newDelRequest,
                              DeliveryCustom8: e.target.value,
                            })
                          }
                        >
                          <MenuItem value={""}>
                            <ListItemText primary={""} />
                          </MenuItem>
                          <MenuItem value={"Change"}>
                            <ListItemText primary={"Change"} />
                          </MenuItem>
                          <MenuItem value={"Repair"}>
                            <ListItemText primary={"Repair"} />
                          </MenuItem>
                          <MenuItem value={"Termination"}>
                            <ListItemText primary={"Termination"} />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        marginTop: 1,
                        marginBottom: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <DatePicker
                        disabled={
                          !["Change", "Repair", "Termination"].includes(
                            newDelRequest.DeliveryCustom8
                          )
                        }
                        className="newDelDate"
                        format="YYYY/MM/DD"
                        value={""}
                        id="selectDate"
                        slotProps={{
                          textField: { size: "small", width: 5 },
                        }}
                        label="Start date"
                        sx={{ width: "80%" }}
                        // on
                        onChange={(e) =>
                          setnewDelRequest({
                            ...newDelRequest,
                            DeliveryOpportunityCustom11: `${e.$d.getFullYear()}-${`0${
                              e.$d.getMonth() + 1
                            }`.slice(-2)}-${`0${e.$d.getDate()}`.slice(-2)}`,
                          })
                        }
                      />
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        marginTop: 1,
                        marginBottom: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "80%",
                          zIndex: 9999,
                          alignItems: "center",
                        }}
                        size="small"
                      >
                        <TextField
                          className="newDelText"
                          label="Delivery description"
                          variant="outlined"
                          id="deliveryDesc"
                          size="small"
                          disabled={
                            !["Change", "Repair", "Termination"].includes(
                              newDelRequest.DeliveryCustom8
                            )
                          }
                          value={newDelRequest.DeliveryDescription}
                          sx={{ width: "100%" }}
                          onChange={(e) =>
                            setnewDelRequest({
                              ...newDelRequest,
                              DeliveryDescription: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: "Quenda, serif !important",
                        background: "#f9323f",
                        color: "white",
                      }}
                      onClick={(e) => {
                        handleClose();
                        clearDelivery();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !["Change", "Repair", "Termination"].includes(
                          newDelRequest.DeliveryCustom8
                        ) ||
                        newDelRequest.DeliveryOpportunityCustom11 === "" ||
                        newDelRequest.DeliveryOpportunityCustom11 ===
                          "NaN-aN-aN"
                      }
                      variant="contained"
                      sx={{
                        fontFamily: "Quenda, serif !important",
                        background: "#4ab217",
                        color: "white",
                      }}
                      onClick={async (e) => {
                        handleClose();
                        const webcrmUpdate = await axios.post(
                          `${apiUrl}Deliveries`,
                          newDelRequest,
                          {
                            headers: {
                              Authorization: `Bearer ${login.data.AccessToken}`,
                            },
                          }
                        );

                        clearDelivery();
                        window.open(
                          `https://app.webcrm.com/MainMenu/Deliveries/DeliveryView.aspx?action=edit&eid=${webcrmUpdate.data}`
                        );
                      }}
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openTicket}
                  onClose={handleClose}
                  PaperComponent={PaperComponent}
                  sx={{
                    borderRadius: "20px",
                  }}
                >
                  <DialogTitle
                    sx={{
                      minWidth: 400,
                      padding: 0,
                      maxWidth: 1000,
                      backgroundColor: "#35647e",
                      color: "white",
                      textAlign: "center",
                      marginBottom: 2,
                      fontFamily: "Quenda, serif",
                    }}
                    style={{ cursor: "move" }}
                    id="draggable-dialog-title"
                  >
                    New ticket
                  </DialogTitle>
                  <DialogContent
                    sx={{ minWidth: 500, padding: 1, maxWidth: 1000, top: 5 }}
                  >
                    <Box
                      component="form"
                      sx={{
                        marginTop: 1,
                        marginBottom: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "80%",
                          zIndex: 9999,
                          alignItems: "center",
                        }}
                        size="small"
                      >
                        <TextField
                          className="newDelText"
                          label="Ticket description"
                          variant="outlined"
                          id="deliveryDesc"
                          size="small"
                          value={newTicket.pageDesc}
                          sx={{ width: "100%" }}
                          onChange={(e) =>
                            setnewTicket({
                              ...newTicket,
                              pageDesc: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        marginTop: 1,
                        marginBottom: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "80%",
                          zIndex: 9999,
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                        size="small"
                      >
                        <InputLabel
                          id="typeLabel"
                          sx={{
                            minWidth: 20,
                            zIndex: 99999,
                            bgcolor: "#fff",
                            // marginTop: "-9px",
                          }}
                        >
                          Reporter
                        </InputLabel>
                        <Select
                          id="selectType"
                          size="small"
                          labelId="typeLabel"
                          value={newTicket.reporter}
                          sx={{ width: "100%", height: "50px" }}
                          onChange={(e) =>
                            setnewTicket({
                              ...newTicket,
                              reporter: e.target.value,
                            })
                          }
                        >
                          <MenuItem value={""}>
                            <ListItemText primary={""} />
                          </MenuItem>{" "}
                          <MenuItem value={"other"}>
                            <ListItemText
                              primary={"Other"}
                              secondary={"Unlisted contact"}
                            />
                          </MenuItem>
                          {details.contacts ? (
                            details.contacts.map((contact) => (
                              <MenuItem
                                value={JSON.stringify(
                                  [
                                    contact.name,
                                    contact.directPhone,
                                    contact.mobilePhone,
                                    contact.email,
                                  ].filter((option) => option !== "-")
                                )}
                              >
                                <ListItemText
                                  primary={`${contact.name}`}
                                  secondary={`${
                                    contact.directPhone !== "-"
                                      ? contact.directPhone + ", "
                                      : ""
                                  }${
                                    contact.mobilePhone !== "-"
                                      ? contact.mobilePhone + ", "
                                      : ""
                                  }${
                                    contact.email !== "-" ? contact.email : ""
                                  }`}
                                />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value={""}>
                              <ListItemText primary={""} />
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: "Quenda, serif !important",
                        background: "#f9323f",
                        color: "white",
                      }}
                      onClick={(e) => {
                        handleClose();
                        clearTicket();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        newTicket.pageDesc === "" ||
                        (newTicket.reporter && newTicket.reporter.length < 1)
                      }
                      variant="contained"
                      sx={{
                        fontFamily: "Quenda, serif !important",
                        background: "#4ab217",
                        color: "white",
                      }}
                      onClick={async (e) => {
                        handleClose();

                        setItem({});
                        dispatch(
                          newTicketReq(
                            { ...newTicket },
                            id,
                            appUser.confluenceId
                          )
                        );
                        clearTicket();
                      }}
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </LocalizationProvider>
              {details.assetsHistory && exportTables.ServiceHistory ? (
                <div className="midTableWrapper" ref={headerRef}>
                  <NewTable
                    tabletype={"small"}
                    tit="serivceAssetsHistory"
                    data={details.assetsHistory.map((line) => {
                      return {
                        ...line,
                        start_t: line.start_t
                          ? customTimeFormat(new Date(line.start_t), undefined)
                          : null,
                        end_t: line.end_t
                          ? customTimeFormat(new Date(line.end_t), undefined)
                          : null,
                        signal: line.online ? "Online" : "",
                        //  line.type.slice(-5) === "Modem"
                        // onlineModems.includes(line.tag_id) ? "Online" : "",
                      };
                    })}
                    width={
                      headerRef.current ? headerRef.current.clientWidth : 100
                    }
                  />
                </div>
              ) : (
                ""
              )}
              {details.contacts ? (
                <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>
                  Contacts
                </h2>
              ) : (
                ""
              )}
              {details.contacts && exportTables.ServiceAssetsHistory ? (
                <div className="midTableWrapper" ref={headerRef}>
                  <NewTable
                    tabletype={"small"}
                    tit="serivceContacts"
                    data={details.contacts}
                    width={
                      headerRef.current ? headerRef.current.clientWidth : 100
                    }
                  />
                </div>
              ) : (
                ""
              )}
            </>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceDetails;

const colorsArray = (amount) => {
  const baseArray = ["#35647e", "#E62E3A", "#2BA3E3", "#E3DD14", "#99974E"];
  let newArray = [];
  let index = 0;
  const loop = () => {
    if (index > baseArray.length - 1) {
      index = index - baseArray.length;
    }
    newArray.push(baseArray[index]);
    index++;
    if (newArray.length < amount) {
      loop();
    }
  };
  loop();

  return newArray;
};

export default colorsArray;

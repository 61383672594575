import "../../css/table.css";
// import "npm-overlapping-marker-spiderfier";
import React, {
  createRef,
  createContext,
  useRef,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useResizeColumns,
  usePagination,
  useFlexLayout,
} from "react-table";
import PropTypes from "prop-types";
import { matchSorter } from "match-sorter";
import { useWindowSize } from "./useWindowSize";
import { makeStyles } from "@material-ui/core/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Box from "@mui/material/Box";
import { updateServiceUsageAlertDone } from "../../store/actions/services";
import { updateKoffers } from "../../store/actions/koffers";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { storeExportTable } from "../../store/actions/tableXlsxExport";
import { selectExportTables } from "../../store/selectors/tableXlslExport";
import {
  storeTablesFilters,
  setBigTableLines,
} from "../../store/actions/navigation";
import {
  setSelectedContract,
  setOrderlineEndDateEdit,
} from "../../store/actions/invoicing";
import {
  selectNavigation,
  selectTablesFilters,
} from "../../store/selectors/navigation";
import { selectSelectedContract } from "../../store/selectors/invoicing";

import icoCC from "../../ico_c_c.png";
import icoCN from "../../ico_c_n.png";
import icoCO from "../../ico_c_o.png";
import icoCP from "../../ico_c_p.png";

import icoNC from "../../ico_n_c.png";
import icoNN from "../../ico_n_n.png";
import icoNO from "../../ico_n_o.png";
import icoNP from "../../ico_n_p.png";

import icoTC from "../../ico_t_c.png";
import icoTN from "../../ico_t_n.png";
import icoTO from "../../ico_t_o.png";
import icoTP from "../../ico_t_p.png";

import icoRC from "../../ico_r_c.png";
import icoRN from "../../ico_r_n.png";
import icoRO from "../../ico_r_o.png";
import icoRP from "../../ico_r_p.png";

import icoOC from "../../ico_o_c.png";
import icoON from "../../ico_o_n.png";
import icoOO from "../../ico_o_o.png";
import icoOP from "../../ico_o_p.png";

import icoMC from "../../ico_m_c.png";
import icoMN from "../../ico_m_n.png";
import icoMO from "../../ico_m_o.png";
import icoMP from "../../ico_m_p.png";

import icoDots from "../../ico_dots.png";

import { MenuItem } from "@material-ui/core";
import colorsArray from "../colorsArray";

export const TTableContext = createContext();
export const getCellStyles = (col) => {
  const style = { ...col.style };
  if (style.width) {
    style.flexBasis = style.width;
    delete style.width;
  }
  if (col.fixedWidth) style.flexGrow = 0;
  return style;
};
const containerStyle = {
  width: "100%",
  height: "600px",
};

let headersX;
let rowsX;
const useStyles = makeStyles({
  ellipsis: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "1px !important",
    textAlign: "left !important",
  },
  wrap: {
    maxWidth: 200,
    minHeight: 16,
    whiteSpace: "normal",
    wordWrap: "break-word",
    margin: "auto 0",
    padding: "1px !important",
    textAlign: "left !important",
    border: "unset",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#35647e",
    color: "#ffffff",
    fontWeight: 800,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const DefaultColumnFilter = React.memo(
  ({ column: { filterValue, preFilteredRows, setFilter, tableName, id } }) => {
    const dispatch = useDispatch();
    const count = preFilteredRows.length;
    return (
      <input
        size="small"
        value={filterValue || ""}
        onChange={(e) => {
          dispatch(storeTablesFilters({ [id]: e.target.value }));
          setFilter(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, tableName, defFilt },
}) {
  const dispatch = useDispatch();
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  return (
    <Select
      // multiselect
      value={
        filterValue
          ? filterValue
          : filterValue === "" && defFilt
          ? defFilt
          : "All"
      }
      MenuProps={MenuProps}
      onChange={(e) => {
        dispatch(storeTablesFilters({ [id]: e.target.value }));
        setFilter(e.target.value === "All" ? undefined : e.target.value);
      }}
    >
      <MenuItem value={"All"}>
        <ListItemText primary={"All"} />
      </MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          <ListItemText primary={option} />
        </MenuItem>
      ))}
    </Select>
  );
}

export function MultiSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, tableName },
}) {
  const dispatch = useDispatch();
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  if (!filterValue) {
    filterValue = options;
  }

  return (
    <div className="multiSelectFilter">
      <Select
        multiple
        value={filterValue}
        MenuProps={MenuProps}
        sx={{ width: "93%", padding: 1, borderRadius: 0 }}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              width: "93%",
              padding: 1,
              paddingLeft: 0,
              fontSize: "12px",
            }}
          >
            {JSON.stringify(options.sort()) ===
            JSON.stringify(filterValue.sort())
              ? "All"
              : filterValue.length === 1
              ? filterValue[0]
              : "..."}
          </Box>
        )}
        onChange={(e) => {
          if (
            e.target.value.includes("all") &&
            JSON.stringify(options.sort()) !==
              JSON.stringify(filterValue.sort())
          ) {
            dispatch(storeTablesFilters({ [id]: options }));
            setFilter(options);
          } else if (
            e.target.value.includes("all") &&
            JSON.stringify(options.sort()) ===
              JSON.stringify(filterValue.sort())
          ) {
            dispatch(storeTablesFilters({ [id]: [] }));
            setFilter([]);
          } else {
            dispatch(storeTablesFilters({ [id]: e.target.value }));
            setFilter(e.target.value);
          }
        }}
      >
        <MenuItem
          value={"all"}
          onClick={() => {
            if (
              JSON.stringify(options.sort()) !==
              JSON.stringify(filterValue.sort())
            ) {
              dispatch(storeTablesFilters({ [id]: options }));
              setFilter(options);
            } else if (
              JSON.stringify(options.sort()) ===
              JSON.stringify(filterValue.sort())
            ) {
              dispatch(storeTablesFilters({ [id]: [] }));
              setFilter([]);
            }
          }}
        >
          <Checkbox
            checked={
              JSON.stringify(options.sort()) ===
              JSON.stringify(filterValue.sort())
            }
          />
          <ListItemText primary={"All"} />
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option}>
            <Checkbox checked={filterValue.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
export let zipcelxConfig = (tableTitle) => {
  const headers = headersX.map((row) => {
    if (typeof row.Header === "string") {
      return { value: row.Header, type: "string" };
    } else if (typeof row.Header === "number") {
      return { value: row.Header, type: "number" };
    } else return { value: "" };
  });
  let rows = [];
  for (let row of rowsX) {
    let rowArr = [];
    for (let header of headersX) {
      if (header.id !== "none") {
        if (typeof row.values[header.id] === "string") {
          rowArr.push({ value: row.values[header.id], type: "string" });
        } else if (typeof row.values[header.id] === "number") {
          rowArr.push({ value: row.values[header.id], type: "number" });
        } else rowArr.push({ value: "" });
      }
    }
    rows.push(rowArr);
  }
  return {
    filename: `Geuzenet-${tableTitle}`,
    sheet: {
      data: [[...headers], ...rows],
    },
  };
};
let exportReduxNameX;
let exportFileNameX;
let exportTablesX;
let dispatchX;
const createExportConfig = () => {
  if (headersX && rowsX) {
    const headers = headersX.map((row) => {
      if (typeof row.Header === "string") {
        return { value: row.Header, type: "string" };
      } else if (typeof row.Header === "number") {
        return { value: row.Header, type: "number" };
      } else return { value: "" };
    });
    let rows = [];

    for (let row of rowsX) {
      let rowArr = [];
      for (let header of headersX) {
        if (header.id !== "none") {
          if (typeof row.values[header.id] === "string") {
            rowArr.push({ value: row.values[header.id], type: "string" });
          } else if (typeof row.values[header.id] === "number") {
            rowArr.push({ value: row.values[header.id], type: "number" });
          } else rowArr.push({ value: "" });
        }
      }
      rows.push(rowArr);
    }
    return {
      [exportReduxNameX]: {
        filename: `${exportFileNameX} ${new Date(Date.now()).toLocaleString(
          "nl-NL"
        )}`,
        sheet: {
          data: [[...headers], ...rows],
        },
      },
    };
  }
};

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;

const propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
};

function TTable({
  tabletype,
  columns,
  data,
  className,
  exportFileName,
  exportReduxName,
  checkValueIndex,
  tableIdent,
}) {
  const navigation = useSelector(selectNavigation);
  const selectedContract = useSelector(selectSelectedContract);
  const exportTables = useSelector(selectExportTables);
  const tablesFilters = useSelector(selectTablesFilters);
  const dispatch = useDispatch();
  dispatchX = useDispatch();
  exportReduxNameX = exportReduxName;
  exportFileNameX = exportFileName;
  exportTablesX = exportTables;
  const [activeMarker, setActiveMarker] = useState();
  const [center, setCenter] = useState({
    lat: 52.29808970000001,
    lng: 4.707587,
  });
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_mapApi, // ,
    // ...otherOptions
  });
  useEffect(() => {
    let tableObject = createExportConfig();
    if (tableObject) {
      if (tableObject[exportReduxNameX]) {
        if (!exportTablesX[exportReduxNameX]) {
          dispatchX(storeExportTable(tableObject));
        } else if (
          exportTablesX[exportReduxNameX].sheet.data.length !==
          tableObject[exportReduxNameX].sheet.data.length
        ) {
          dispatch(storeExportTable(tableObject));
        } else if (exportTablesX[exportReduxNameX]) {
          for (
            let i = 0;
            i < exportTablesX[exportReduxNameX].sheet.data.length;
            i++
          ) {
            if (
              checkValueIndex <
              exportTablesX[exportReduxNameX].sheet.data[i].length
            ) {
              if (
                exportTablesX[exportReduxNameX].sheet.data[i][checkValueIndex]
                  .value !==
                tableObject[exportReduxNameX].sheet.data[i][checkValueIndex]
                  .value
              ) {
                i = exportTablesX[exportReduxNameX].sheet.data.length;
                dispatch(storeExportTable(tableObject));
              }
            }
          }
        }
      }
    }
  });
  const classes = useStyles();
  const goToServiceDetails = (id) => {
    navigate(`/serviceDetails/${id}`);
  };

  const goToDeviceDetails = (id) => {
    navigate(`/hardwareDetails/${id}`);
  };

  const goToSimDetails = (id) => {
    navigate(`/simcardsDetails/${id}`);
  };

  const cellLink = (cell) => {
    if (cell.column.id === "unaprovedOrderlines_end") {
      dispatch(
        setOrderlineEndDateEdit({
          id: cell.row.original.qlineId,
          date: cell.row.original.end,
        })
      );
    }
    if (
      ["ticketHistory_Reference", "ticketOverview_Reference"].includes(
        cell.column.id
      )
    ) {
      window.open(
        `https://geuzenet.atlassian.net/wiki/spaces/Support/pages/${cell.row.original.id}`
      );
    }
    if (["listOfServices_serviceId"].includes(cell.column.id)) {
      goToServiceDetails(cell.row.values.listOfServices_serviceId);
    }
    if (["ticketOverview_User"].includes(cell.column.id)) {
      goToServiceDetails(cell.row.values.ticketOverview_Reference.slice(0, 4));
    }
    if (["listOfDeliveries_serviceId"].includes(cell.column.id)) {
      window.open(`/serviceDetails/${cell.value}`);
    }

    if (["serviceHistory_Contract"].includes(cell.column.id)) {
      window.open(
        `https://app.webcrm.com/MainMenu/Deliveries/DeliveryView.aspx?action=edit&eid=${cell.row.original.id}`
      );
    }
    if (["listOfDeliveries_deliveryId"].includes(cell.column.id)) {
      window.open(
        `https://app.webcrm.com/MainMenu/Deliveries/DeliveryView.aspx?action=edit&eid=${cell.row.original.delivery_id}`
      );
    }

    if (["serviceHistory_User"].includes(cell.column.id) && cell.value) {
      window.open(
        `https://app.webcrm.com/MainMenu/Organisations/OrganisationCard/OrganisationCard.aspx?orgId=${cell.row.original.user.organisation_id}`
      );
    }
    if (["serviceHistory_Customer"].includes(cell.column.id)) {
      window.open(
        `https://app.webcrm.com/MainMenu/Organisations/OrganisationCard/OrganisationCard.aspx?orgId=${cell.row.original.customer.organisation_id}`
      );
    }
    if (
      ["serviceHistory_Agent"].includes(cell.column.id) &&
      cell.value !== "-"
    ) {
      window.open(
        `https://app.webcrm.com/MainMenu/Organisations/OrganisationCard/OrganisationCard.aspx?orgId=${cell.row.original.agent.organisation_id}`
      );
    }

    if (["dirtyOrders_description"].includes(cell.column.id)) {
      if (cell.row.values.dirtyOrders_category === "delivery") {
        window.open(
          `https://app.webcrm.com/MainMenu/Deliveries/DeliveryView.aspx?action=edit&eid=${cell.row.values.dirtyOrders_webcrmId}`
        );
      }
      if (cell.row.values.dirtyOrders_category === "organisation") {
        window.open(
          `https://app.webcrm.com/MainMenu/Organisations/OrganisationCard/OrganisationCard.aspx?orgId=${cell.row.values.dirtyOrders_webcrmId}`
        );
      }
    }

    if (["poolsDataUsage_id", "poolsDataUsage_name"].includes(cell.column.id)) {
      navigate(`/poolUsageDetails/${cell.row.values.poolsDataUsage_id}`);
    }

    if (
      ["poolTopUsage_serviceId", "poolTopUsage_name"].includes(cell.column.id)
    ) {
      goToServiceDetails(cell.row.original.service_id);
    }
    if (["highUsage_service"].includes(cell.column.id)) {
      window.open(`/serviceDetails/${cell.row.original.service_id}`);
    }

    if (["highUsage_current", "poolTopUsage_usage"].includes(cell.column.id)) {
      navigate(`/serviceUsageDetails/${cell.row.original.service_id}`);
    }

    if (
      ["listOfDevices_tag", "listOfDevices_serial"].includes(cell.column.id)
    ) {
      goToDeviceDetails(cell.row.original.tag);
    }

    if (["inventoryCheck_tagId"].includes(cell.column.id)) {
      goToDeviceDetails(cell.value);
    }

    if (
      [
        "listOfDevices_location",
        "listOfSims_location",
        "assetHistory_location",
        "simHistory_location",
        "inventoryCheck_location",
      ].includes(cell.column.id) &&
      parseInt(cell.value.slice(0, 4))
    ) {
      goToServiceDetails(cell.value.slice(0, 4));
    }

    if (
      ["listOfDevices_icc", "inventoryCheck_simIcc"].includes(cell.column.id) &&
      cell.value !== "N/A" &&
      cell.value !== "-"
    ) {
      goToSimDetails(cell.row.original.simcard_id);
    }

    if (
      ["listOfSims_id", "listOfSims_icc", "listOfSims_msisdn"].includes(
        cell.column.id
      )
    ) {
      goToSimDetails(cell.row.values.listOfSims_id);
    }

    if (
      ["listOfSims_modem", "serviceAssets_Tag", "simHistory_modem"].includes(
        cell.column.id
      ) &&
      cell.value !== "<empty>"
    ) {
      goToDeviceDetails(cell.value);
    }

    if (
      ["serviceAssets_SimId", "serviceAssets_SimICC"].includes(cell.column.id)
    ) {
      goToSimDetails(cell.row.values.serviceAssets_SimId);
    }

    if (cell.checkButton) {
      if (cell.checkDesc === "koffers") {
        dispatch(updateKoffers(cell));
      } else {
        dispatch(updateServiceUsageAlertDone(cell));
      }
    }
    if (cell.signalButton) {
      window.open(`/AdminSignal test/${cell.row.original.tag_id}`);
    }
  };

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== null
            ? String(rowValue)
                .toLowerCase()
                .indexOf(String(filterValue).toLowerCase()) > -1
            : true;
        });
      },
      multiple: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined && filterValue
            ? filterValue.includes(rowValue)
            : true;
        });
      },
    }),
    []
  );

  const listRef = createRef();

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const headerRef = useRef();
  const rowRef = useRef();
  const buttonsRef = useRef();

  let amountOfLines =
    tabletype === "big"
      ? navigation.bigTableLines
      : tabletype === "mid"
      ? navigation.bigTableLines / 2
      : data.length;
  let tableHeight;
  if (rowRef.current && headerRef.current) {
    tableHeight =
      rowRef.current.clientHeight * amountOfLines +
      headerRef.current.clientHeight +
      20;
  } else if (headerRef.current) {
    tableHeight = 23 * amountOfLines + headerRef.current.clientHeight + 20;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetFilters: false,
      defaultColumn,
      filterTypes,
      initialState: {
        pageSize: amountOfLines,
        filters: Object.keys(tablesFilters).map((filter) => {
          return { id: filter, value: tablesFilters[filter] };
        }),
      },
    },
    useResizeColumns,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout
  );

  useEffect(() => {
    setPageSize(amountOfLines);
  }, [setPageSize, amountOfLines]);
  const { pageIndex, pageSize } = state;
  const sizeMap = React.useRef({});
  headersX = headerGroups[0].headers;
  rowsX = rows;
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
  }, []);
  const [windowWidth] = useWindowSize();

  var Spiderfy = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

  const [oms, setOms] = useState(null);
  const onLoad = (map) => {
    let newOms;
    function initOMS() {
      newOms = new Spiderfy.OverlappingMarkerSpiderfier(map, {
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        nearbyDistance: 15,
        circleFootSeparation: 40,
        bubblingMouseEvents: false,
      });
    }
    initOMS();
    setOms(newOms);
  };

  useEffect(() => {
    if (oms) {
      oms.clearMarkers();
      page
        .map((full) => full.original)
        .map((point) => {
          const newMarker = window.google
            ? new window.google.maps.Marker()
            : null;
          newMarker.setPosition({
            lat: parseFloat(point.latitude),
            lng: parseFloat(point.longitude),
          });

          window.google.maps.event.addListener(
            newMarker,
            "spider_format",
            function (status) {
              if (status === "SPIDERFIABLE") {
                newMarker.setIcon(icoDots);
                newMarker.setTitle("Multiple deliveries");
              } else {
                newMarker.setIcon(
                  point.delivery_type === "New"
                    ? point.status === "New"
                      ? icoNN
                      : point.status === "OnGoing"
                      ? icoNO
                      : point.status === "Planned"
                      ? icoNP
                      : icoNC
                    : point.delivery_type === "Termination"
                    ? point.status === "New"
                      ? icoTN
                      : point.status === "OnGoing"
                      ? icoTO
                      : point.status === "Planned"
                      ? icoTP
                      : icoTC
                    : point.delivery_type === "Change"
                    ? point.status === "New"
                      ? icoCN
                      : point.status === "OnGoing"
                      ? icoCO
                      : point.status === "Planned"
                      ? icoCP
                      : icoCC
                    : point.delivery_type === "One-off"
                    ? point.status === "New"
                      ? icoON
                      : point.status === "OnGoing"
                      ? icoOO
                      : point.status === "Planned"
                      ? icoOP
                      : icoOC
                    : point.delivery_type === "Repair"
                    ? point.status === "New"
                      ? icoRN
                      : point.status === "OnGoing"
                      ? icoRO
                      : point.status === "Planned"
                      ? icoRP
                      : icoRC
                    : point.delivery_type === "Move"
                    ? point.status === "New"
                      ? icoMN
                      : point.status === "OnGoing"
                      ? icoMO
                      : point.status === "Planned"
                      ? icoMP
                      : icoMC
                    : icoDots
                );
                newMarker.setTitle(
                  `${point.delivery_id}/${point.service_id}-${point.user_name}`
                );
              }
            }
          );
          window.google.maps.event.addListener(newMarker, "click", (e) => {
            if (activeMarker === point.delivery_id) {
              infowindow.open(oms.map, newMarker);
              setActiveMarker(point.delivery_id);
            } else {
              setActiveMarker(point.delivery_id);
            }
          });

          let lines = [
            { k: "Delivery id: ", v: point.delivery_id },
            { k: "Service id: ", v: point.service_id },
            { k: "User: ", v: point.user_name },
            { k: "Description:", v: point.description },
            { k: "Address:", v: point.address },
            { k: "Delivery type:", v: point.delivery_type },
            { k: "Wish date:", v: point.requested_date },
            { k: "Plan date:", v: point.implementation_date_t },
            { k: "Status:", v: point.status },
            { k: "Comment:", v: point.comment },
          ]
            .map((line) => {
              return `<p class = "mapInfoLine">
          <span class = "mapInfoKeys">${line.k}</span>
          <span class= "mapInfoValues">${
            line.k === "Delivery id: "
              ? `<a href="https://app.webcrm.com/MainMenu/Deliveries/DeliveryView.aspx?action=edit&eid=${line.v}" target = "_blank">${line.v}</a>`
              : line.k === "Service id: "
              ? `<a href="/serviceDetails/${line.v}" target="_blank" >${line.v}</a>`
              : line.v
          }</span>
          </p>`;
            })
            .join(" ");
          let infowindow = new window.google.maps.InfoWindow({
            content: `<div class="mapInfoWindow"> ${lines} </div>`,
          });
          if (activeMarker === point.delivery_id) {
            infowindow.open(oms.map, newMarker);
          } else {
            infowindow.close(oms.map, newMarker);
          }
          // });

          window.google.maps.event.addListener(oms.map, "click", (e) => {
            setActiveMarker(null);
            infowindow.close(oms.map, newMarker);
          });
          oms.addMarker(newMarker);
          return newMarker;
        });
    }
  }, [page, activeMarker, oms]);

  return (
    <>
      <TTableContext.Provider
        value={{
          windowWidth,
          setSize,
          getCellStyles,
          page,
          prepareRow,
          listRef,
        }}
      >
        <Table
          stickyHeader
          aria-label="customized table"
          {...getTableProps()}
          className={className}
          tabletype={tabletype}
          style={
            tabletype === "big" || tabletype === "mid"
              ? { height: tableHeight }
              : {}
          }
        >
          <TableHead
            style={
              tabletype === "big" || tabletype === "mid"
                ? {
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    margin: 0,
                    display: "inline-table",
                  }
                : {
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    margin: 0,
                  }
            }
            ref={headerRef}
          >
            {" "}
            {tabletype === "big" || tabletype === "mid" ? (
              <div className="tableButtons" ref={buttonsRef}>
                <div className="tableButtonsG1">
                  <button
                    onClick={() =>
                      dispatch(setBigTableLines(navigation.bigTableLines - 10))
                    }
                    disabled={pageSize < 11}
                  >
                    {"-"}
                  </button>{" "}
                  <button
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>{" "}
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {"<"}
                  </button>{" "}
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {">"}
                  </button>{" "}
                  <button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>{" "}
                  <button
                    onClick={() => {
                      // setPageSize(pageSize + 10)
                      dispatch(setBigTableLines(navigation.bigTableLines + 10));
                    }}
                    disabled={pageOptions.length < 2}
                  >
                    {"+"}
                  </button>{" "}
                </div>
              </div>
            ) : (
              ""
            )}
            {headerGroups.map((headerGroup, index) => {
              return (
                <TableRow
                  key={index}
                  align="center"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, i) => {
                    if (column.Header !== "") {
                      return (
                        <StyledTableCell
                          {...column.getHeaderProps({
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                              maxWidth: column.maxWidth,
                              padding: 0,
                            },
                          })}
                        >
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              display: "flex",
                            }}
                            className="oko4"
                          >
                            {column.render("Header")}
                            <span>
                              {tabletype === "big" || tabletype === "mid" ? (
                                column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDownwardIcon sx={{ fontSize: 12 }} />
                                  ) : (
                                    <ArrowUpwardIcon sx={{ fontSize: 12 }} />
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          {tabletype === "big" || tabletype === "mid" ? (
                            <div className="oko5">
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </div>
                          ) : (
                            ""
                          )}
                        </StyledTableCell>
                        // </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </TableRow>
              );
            })}
          </TableHead>

          <TableBody className="oko8" {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <TableRow
                  className={
                    tableIdent === "antennaSignalBands"
                      ? row.cells[0]
                        ? row.cells[0].value === "Yes"
                          ? "activeAntennaBand"
                          : "inactiveAntennaBand"
                        : "inactiveAntennaBand"
                      : tableIdent === "ListOfDeliveries" &&
                        row.original.delivery_id === activeMarker
                      ? "activeDelivery"
                      : tableIdent === "contractsSelect" &&
                        row.original.contractId === selectedContract
                      ? "activeDelivery"
                      : "normalTableRow"
                  }
                  onClick={
                    tableIdent === "ListOfDeliveries"
                      ? (e) => {
                          if (activeMarker === row.original.delivery_id) {
                            setActiveMarker(null);
                          } else {
                            setActiveMarker(row.original.delivery_id);
                          }
                          setCenter({
                            lat: parseFloat(row.original.latitude),
                            lng: parseFloat(row.original.longitude),
                          });
                        }
                      : tableIdent === "contractsSelect"
                      ? (e) => {
                          if (selectedContract === row.original.contractId) {
                            dispatch(setSelectedContract(0));
                          } else {
                            dispatch(
                              setSelectedContract(row.original.contractId)
                            );
                          }
                        }
                      : (e) => console.log(e)
                  }
                  align={center}
                  ref={rowRef}
                  // class
                  sx={
                    tableIdent === "antennaSignalBands"
                      ? {
                          padding: 1,
                          bgcolor: colorsArray(25)[index + 1],
                        }
                      : { padding: 1 }
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    if (cell.column.Header !== "") {
                      return (
                        <StyledTableCell
                          id={
                            cell.column.id === "item_description"
                              ? `${cell.row.original.qt_line_id}${cell.value}`
                              : ""
                          }
                          className={
                            cell.column.link &&
                            tableIdent === "unaprovedOrderlines"
                              ? `${classes.wrap} tableLink`
                              : !cell.value
                              ? `elipsisRow`
                              : cell.value === "<empty>"
                              ? `elipsisRow`
                              : cell.value === "N/A"
                              ? `elipsisRow`
                              : cell.value === "no Simcard"
                              ? `elipsisRow`
                              : cell.value.toString().slice(0, 7) === "<stock>"
                              ? `elipsisRow`
                              : cell.column.Header === "10_Day_Alert" &&
                                cell.row.original.tenth_day_alert
                              ? `elipsisRow tableList${cell.column.Header} alertRed`
                              : cell.column.Header === "20_Day_Alert" &&
                                cell.row.original.twentieth_day_alert
                              ? `elipsisRow tableList${cell.column.Header} alertRed`
                              : cell.column.Header === "Total_Alert" &&
                                cell.row.original.total_alert
                              ? `elipsisRow tableList${cell.column.Header} alertRed`
                              : cell.column.link && cell.value !== ""
                              ? `elipsisRow tableLink`
                              : tableIdent === "contractsOrderlines" ||
                                tableIdent === "unaprovedOrderlines"
                              ? `${classes.wrap}`
                              : `elipsisRow`
                          }
                          title={cell.value}
                          onClick={(e) => {
                            cellLink(cell);
                          }}
                          align="left"
                          style={{
                            padding: "1px",
                            height: "auto",
                          }}
                          {...cell.getCellProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                              maxWidth: cell.column.maxWidth,
                            },
                          })}
                        >
                          {cell.render("Cell")}
                        </StyledTableCell>
                      );
                    } else {
                      return null;
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TTableContext.Provider>
      {tableIdent === "ListOfDeliveries" && isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          center={center}
          zoom={7.5}
        ></GoogleMap>
      ) : (
        ""
      )}
    </>
  );
}

TTable.propTypes = propTypes;

export default TTable;

const initialState = {
  services: [],
  serviceDetails: {},
  filteredContacts: [],
  maintenance: { networks: [], infoSources: [], info: [] },
  koffers: [],
  usageAlerts: [],
  dataUsage: {
    timestamps: {},
    serviceUsage: {
      wans: [],
      usageArray: [],
      tableData: [],
    },
    serviceAcuArray: [],
    selection: { scope: "" },
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "services/storeAllServices":
      return { ...state, services: action.payload };
    case "services/storeMaintenanceNetworks":
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          networks: action.payload,
        },
      };
    case "services/storeMaintenanceInfoSources":
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          infoSources: action.payload,
        },
      };
    case "services/storeMaintenanceInfo":
      return {
        ...state,
        maintenance: { ...state.maintenance, info: action.payload },
      };
    case "services/nullMaintenanceInfoSources":
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          infoSources: [],
        },
      };
    case "services/nullMaintenanceInfo":
      return { ...state, maintenance: { ...state.maintenance, info: [] } };
    case "services/storeFilteredContacts":
      return { ...state, filteredContacts: action.payload };
    case "services/setFilteredContactsToNull":
      return { ...state, filteredContacts: [] };
    case "services/storeAllKoffers":
      return { ...state, koffers: action.payload };
    case "services/storeServicesAlerts":
      return { ...state, usageAlerts: action.payload };
    case "services/storeServiceDetails":
      return { ...state, serviceDetails: action.payload };
    case "services/storeServiceUsageTimestamps":
      return {
        ...state,
        dataUsage: { ...state.dataUsage, timestamps: action.payload },
      };
    case "services/storeUsageAcuArray":
      return {
        ...state,
        dataUsage: { ...state.dataUsage, serviceAcuArray: action.payload },
      };
    case "services/storeServiceUsage":
      return {
        ...state,
        dataUsage: { ...state.dataUsage, serviceUsage: action.payload },
      };
    case "services/setUsageSelection":
      return {
        ...state,
        dataUsage: { ...state.dataUsage, selection: action.payload },
      };
    case "services/setServicesToNull":
      return { ...state, services: [] };

    case "services/init":
      return { ...initialState };
    default:
      return state;
  }
}

import React, { useState, useRef, useEffect, memo } from "react";
import { IconButton, OutlinedInput } from "@material-ui/core";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const geuzenetBlue = {
  main: "#35647e",
  light: "#35647e",
  dark: "#35647e",
  contrastText: "#35647e",
};

const inputBoxProps = {
  component: "form",
  sx: {
    height: 26,
    width: "100%",
    margin: 0,
    marginTop: 0.5,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "end",
    alignItems: "center",
  },
};
const inputBoxProps2 = {
  component: "form",
  sx: {
    height: 26,
    width: "160px",
    margin: 0,
    // marginTop: 0.5,
    // display: "flex",
    flexDirection: "row",
    flexWrap: "noWrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
const inputBoxProps3 = {
  component: "form",
  sx: {
    height: 26,
    // width: "160px",
    // margin: 0,
    // marginTop: 0.5,
    // display: "flex",
    flexDirection: "row",
    flexWrap: "noWrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
const inputFormProps = {
  sx: {
    width: "70%",
    margin: "0 5px 0 10px",
    alignItems: "center",
    justifyContent: "center",
  },
  size: "small",
};
const inputFormProps2 = {
  sx: {
    // width: "70%",
    margin: "0 0px 0 0px",
    alignItems: "center",
    justifyContent: "center",
  },
  size: "small",
};

const datePickerProps = {
  id: "selectDate",
  sx: {
    width: "100%",
    margin: "0 5px 0 10px",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    height: "26px",
  },
  slotProps: {
    textField: {
      onKeyPress: (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      },
      style: { width: "70%" },
      padding: 0,
      id: "datePickerAddDevices",
      height: "26px",
      width: "90%",
      fullWidth: true,
      inputProps: {
        style: {
          marginLeft: "5px",
          fontSize: "14px",
          fontFamily: '"Open Sans","Roboto","Helvetica","Arial",sans-serif',
        },
      },
    },
    openPickerButton: { style: { margin: 0 } },
    field: { style: { width: "100%" } },
  },
};
const datePickerProps2 = {
  id: "selectDate",
  sx: {
    width: "100%",
    margin: "0 5px 0 10px",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    height: "26px",
  },
  slotProps: {
    textField: {
      onKeyPress: (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      },
      style: { width: "90%" },
      padding: 0,
      id: "datePickerAddDevices",
      height: "26px",
      width: "100%",
      fullWidth: true,
      inputProps: {
        style: {
          marginLeft: "5px",
          fontSize: "14px",
          fontFamily: '"Open Sans","Roboto","Helvetica","Arial",sans-serif',
        },
      },
    },
    openPickerButton: { style: { margin: 0 } },
    field: { style: { width: "100%" } },
  },
};
export const AssetsSelect = (props) => {
  const { desc, value, options, onChangeFunc, disabled } = props;
  return (
    <Box {...inputBoxProps}>
      <div className="dasDesc">{desc}</div>
      <FormControl {...inputFormProps}>
        <Select
          disabled={disabled}
          id="dropSelectAddDevices"
          size="small"
          labelId="typeLabel"
          value={value}
          sx={{
            width: "100%",
            paddingTop: 0,
            height: 26,
            textAlign: "start",
          }}
          onChange={(e) => onChangeFunc(e.target.value)}
        >
          <MenuItem value={"- Select -"}>
            <ListItemText primary={"- Select -"} />
          </MenuItem>
          {options.map((option) => {
            return (
              <MenuItem value={option.key} key={option.key}>
                <ListItemText primary={option.value} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
export const AssetsSelect2 = (props) => {
  const { desc, value, options, onChangeFunc, disabled } = props;
  return (
    <Box {...inputBoxProps3}>
      <div className="dasDesc">{desc}</div>
      <FormControl {...inputFormProps}>
        <Select
          displayEmpty
          disabled={disabled}
          id="dropSelectAddDevices"
          size="small"
          labelId="typeLabel"
          value={value}
          sx={{
            fontSize: "14px",
            width: "100%",
            paddingTop: 2,
            height: 26,
            textAlign: "start",
          }}
          onChange={(e) => onChangeFunc(e.target.value)}
        >
          <MenuItem value={"-"}>
            <ListItemText primary={"-"} />
          </MenuItem>
          {options.map((option) => {
            return (
              <MenuItem value={option.key} key={option.key}>
                <ListItemText primary={option.value} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
export const AssetsCheckbox = (props) => {
  const { desc, value, onChangeFunc } = props;
  return (
    <Box {...inputBoxProps}>
      <div className="dasDesc">{desc}</div>
      <FormControl {...inputFormProps}>
        <Checkbox
          sx={{ alignSelf: "start" }}
          checked={value}
          value={value}
          onChange={(e) => onChangeFunc(e.target.value)}
        />
      </FormControl>
    </Box>
  );
};
export const AssetsCheckbox2 = (props) => {
  const { desc, value, onChangeFunc, className, disabled } = props;
  return (
    <Box className={className} {...inputBoxProps2} justifyContent={"start"}>
      <div> {desc}</div>
      <FormControl {...inputFormProps2}>
        <Checkbox
          sx={{ alignSelf: "start" }}
          checked={value}
          value={value}
          onChange={(e) => onChangeFunc(e.target.value)}
          disabled={disabled}
        />
      </FormControl>
    </Box>
  );
};
export const AssetsCheckbox3 = (props) => {
  const { desc, value, onChangeFunc, className } = props;
  return (
    <Box className={className} {...inputBoxProps3} justifyContent={"start"}>
      <div> {desc}</div>
      <FormControl {...inputFormProps2}>
        <Checkbox
          sx={{
            alignSelf: "start",
            color: "#35647e",
            "&.Mui-checked": {
              color: "#4ab217",
            },
          }}
          checked={value}
          value={value}
          onChange={(e) => onChangeFunc(e.target.value)}
        />
      </FormControl>
    </Box>
  );
};
export const AssetsTextInput = (props) => {
  const { desc, value, onChangeFunc, disabled } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  console.log(value ? value.length : "");
  return (
    <Box
      {...inputBoxProps}
      className={desc === "Security code : " ? "secCodeInput" : ""}
    >
      <div
        className={
          desc === "E-Mail : "
            ? value
              ? value.length < 4 ||
                !value.includes("@") ||
                value[0] === "@" ||
                value[value.length - 1] === "@"
                ? "dasDesc errorDesc"
                : "dasDesc"
              : "dasDesc"
            : "dasDesc"
        }
      >
        {desc}
      </div>
      <FormControl {...inputFormProps}>
        {desc !== "Security code : " ? (
          <TextField
            error={
              desc === "E-Mail : "
                ? value
                  ? value.length < 4 ||
                    !value.includes("@") ||
                    value[0] === "@" ||
                    value[value.length - 1] === "@"
                    ? true
                    : false
                  : false
                : false
            }
            disabled={disabled}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            id="textFieldAddDevices"
            size="small"
            value={value}
            sx={{
              width: "100%",
              paddingTop: 0,
              textAlign: "start",
            }}
            inputProps={{
              style: {
                marginLeft: "5px",
                fontSize: "14px",
                fontFamily:
                  '"Open Sans","Roboto","Helvetica","Arial",sans-serif',
              },
            }}
            onChange={(e) => onChangeFunc(e.target.value)}
          />
        ) : (
          <OutlinedInput
            id="filled-adornment-password"
            type={showPassword ? "text" : "password"}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            size="small"
            value={value}
            // sx={{
            //   width: "100%",
            //   paddingTop: 0,
            //   textAlign: "start",
            // }}
            inputProps={{
              style: {
                marginLeft: "5px",
                fontSize: "14px",
                fontFamily:
                  '"Open Sans","Roboto","Helvetica","Arial",sans-serif',
              },
            }}
            onChange={(e) => onChangeFunc(e.target.value)}
          />
        )}
      </FormControl>
    </Box>
  );
};
export const AssetsTextMultiInput = (props) => {
  const { desc, value, onChangeFunc, disabled } = props;
  return (
    <Box {...inputBoxProps}>
      <div className="dasDesc">{desc}</div>
      <FormControl {...inputFormProps}>
        <TextField
          fullWidth
          multiline
          disabled={disabled}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          InputProps={{
            rows: 4,
          }}
          id="textFieldAddDevices"
          size="small"
          value={value}
          sx={{
            width: "100%",
            paddingTop: 0,
            textAlign: "start",
          }}
          inputProps={{
            style: {
              marginLeft: "5px",
              fontSize: "14px",
              fontFamily: '"Open Sans","Roboto","Helvetica","Arial",sans-serif',
            },
          }}
          onChange={(e) => onChangeFunc(e.target.value)}
        />
      </FormControl>
    </Box>
  );
};
export const AssetsTextInput2 = (props) => {
  const { desc, value, onChangeFunc, disabled, descClass } = props;
  return (
    <Box {...inputBoxProps3}>
      <div className={descClass}>{desc}</div>
      <FormControl {...inputFormProps}>
        <TextField
          disabled={disabled}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          id="textFieldAddDevices"
          size="small"
          value={value}
          sx={{
            width: "100%",
            paddingTop: 0,
            textAlign: "start",
          }}
          inputProps={{
            style: {
              marginLeft: "5px",
              fontSize: "14px",
              fontFamily: '"Open Sans","Roboto","Helvetica","Arial",sans-serif',
            },
          }}
          onChange={(e) => onChangeFunc(e.target.value)}
        />
      </FormControl>
    </Box>
  );
};
export const AssetsDatePicker = (props) => {
  const dateInputRef = useRef();
  const { desc, value, onChangeFunc } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
      <Box {...inputBoxProps}>
        <div className="dasDesc">{desc}</div>
        <DatePicker
          inputRef={dateInputRef}
          format="YYYY/MM/DD"
          value={dayjs(value)}
          {...datePickerProps}
          onChange={(e) => (e ? onChangeFunc(e.$d) : onChangeFunc(null))}
        />
      </Box>
    </LocalizationProvider>
  );
};
export const AssetsDatePicker2 = (props) => {
  const dateInputRef = useRef();
  const { desc, value, onChangeFunc, disabled } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
      <Box {...inputBoxProps2}>
        <div className="dasDesc">{desc}</div>
        <DatePicker
          disabled={disabled}
          inputRef={dateInputRef}
          format="YYYY/MM/DD"
          value={dayjs(value)}
          {...datePickerProps2}
          onChange={(e) => (e ? onChangeFunc(e.$d) : onChangeFunc(null))}
        />
      </Box>
    </LocalizationProvider>
  );
};

export const MemoAssetsDatePicker = memo(AssetsDatePicker);
export const AssetsDateTimePicker = (props) => {
  const { desc, value, onChangeFunc } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
      <Box {...inputBoxProps}>
        <div className="dasDesc">{desc}</div>
        <DateTimePicker
          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
          variant="inline"
          showToolbar={true}
          ampm={false}
          format="YYYY/MM/DD HH:mm"
          value={dayjs(value)}
          {...datePickerProps}
          onChange={(e) => (e ? onChangeFunc(e.$d) : onChangeFunc(null))}
        />
      </Box>
    </LocalizationProvider>
  );
};

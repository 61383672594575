const initialState = { selected: [], all: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "koffers/storeKoffers":
      return { ...state, selected: action.payload };
    case "koffers/storeAllKoffers":
      return { ...state, all: action.payload };
    case "koffers/cleanKoffers":
      return initialState;
    default:
      return state;
  }
}

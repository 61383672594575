import axios from "axios";
const key = process.env.REACT_APP_API_KEY;

export const assetsInit = () => {
  return {
    type: "assets/init",
  };
};

const storeHardwareProps = (props) => {
  return {
    type: "assets/storeHardwareProps",
    payload: props,
  };
};
export const getHardwareProps = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/hardware_props?code=${key}`);
    dispatch(storeHardwareProps(response.data));
  };
};

const storeSimcardsProps = (props) => {
  return {
    type: "assets/storeSimProps",
    payload: props,
  };
};
export const getSimProps = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/sim_props?code=${key}`);
    dispatch(storeSimcardsProps(response.data));
  };
};

const storeAllHardware = (allHardware) => {
  return {
    type: "assets/storeAllHardware",
    payload: allHardware,
  };
};
export const getAllHardware = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/hardware_all?code=${key}`);
    dispatch(storeAllHardware(response.data));
  };
};

export const addHardware = (data) => {
  return async (dispatch, getState) => {
    await axios.post(`/api/hardware_add?code=${key}`, {
      data,
    });
  };
};

export const bulkUpdateHardware = (data) => {
  return async (dispatch, getState) => {
    await axios.post(`/api/hardware_bulk_update?code=${key}`, {
      data,
    });
  };
};

const storeAllSimcards = (allSimcards) => {
  return {
    type: "assets/storeAllSimcards",
    payload: allSimcards,
  };
};
export const getAllSimcards = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/sims_all?code=${key}`);
    dispatch(storeAllSimcards(response.data));
    console.log(response);
  };
};

export const addSimcards = (data) => {
  return async (dispatch, getState) => {
    await axios.post(`/api/sims_add?code=${key}`, {
      data,
    });
  };
};

export const bulkUpdateSimcards = (data) => {
  return async (dispatch, getState) => {
    await axios.post(`/api/sims_bulk_update?code=${key}`, {
      data,
    });
  };
};

export const storeAssetDetails = (details) => {
  console.log(details);
  return {
    type: "assets/storeAssetDetails",
    payload: details,
  };
};
export const getHardwareDetails = (tag) => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/hardware_details?code=${key}&&id=${tag}`
    );
    dispatch(storeAssetDetails(response.data[0]));
  };
};

export const updateHardwareDetails = (tag, data) => {
  return async (dispatch, getState) => {
    dispatch(storeAssetDetails({}));
    await axios.post(`/api/hardware_update?code=${key}&&id=${tag}`, { data });
    dispatch(getHardwareDetails(tag));
  };
};

export const getSimDetails = (simId) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/sim_details?code=${key}&&id=${simId}`);
    console.log(response.data[0]);
    dispatch(storeAssetDetails(response.data[0]));
  };
};

export const updateSimDetails = (simId, data) => {
  return async (dispatch, getState) => {
    dispatch(storeAssetDetails({}));
    await axios.post(`/api/sim_update?code=${key}&&id=${simId}`, { data });
    dispatch(getSimDetails(simId));
  };
};

export const dateFormat = (timestamp) => {
  let d = new Date(timestamp);
  let month = (d.getMonth() + 1).toString().padStart(2, "0");
  let day = d.getDate().toString().padStart(2, "0");
  let year = d.getFullYear();
  return [year, month, day].join("-");
};

export const dateTimeFormat = (timestamp) => {
  let d = new Date(timestamp);
  let month = (d.getMonth() + 1).toString().padStart(2, "0");
  let day = d.getDate().toString().padStart(2, "0");
  let year = d.getFullYear();
  let hour = d.getHours().toString().padStart(2, "0");
  let minute = d.getMinutes().toString().padStart(2, "0");
  return [[year, month, day].join("-"), [hour, minute].join(":")].join(" ");
};

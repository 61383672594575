import React, { memo } from "react";
import GaugeChart from "react-gauge-chart";

const SignalGaugeUnMemo = (props) => {
  const chartStyle = {
    height: 250,
    width: "100%",
  };
  let beeper = props.beeper ? "#35647e" : "#f9323f";
  return (
    <div style={{ width: "250px" }}>
      <GaugeChart
        id={"gauge1"}
        arcsLength={[6, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 6]}
        style={chartStyle}
        nrOfLevels={30}
        colors={["rgb(250,0,0)", "rgb(0,250,0)"]}
        cornerRadius={0}
        arcPadding={0.02}
        textColor={"#000000"}
        needleColor={"#35647e"}
        needleBaseColor={"#35647e"}
        animDelay={0}
        animateDuration={1000}
        percent={props.gaugePerc}
        formatTextValue={
          props.gaugePerc !== 0
            ? (value) => Math.round(value * 0.6 - 120) + "dB"
            : () => "-"
        }
      />
    </div>
  );
};
const SignalGauge = memo(SignalGaugeUnMemo);
export default SignalGauge;

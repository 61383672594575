// import "../css/landing.css";
import logo from "../../geuzenetlog.png";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material";
import { selectAppUser } from "../../store/selectors/navigation";
import {
  selectUnconfirmedContract,
  selectOrderlineEndDateEdit,
} from "../../store/selectors/invoicing";

import NewTable from "../../components/newTable/newTable";
import { getAppUser } from "../../store/actions/navigation";
import {
  storeUnconifrmed,
  updateUnconifrmed,
  getUnconifrmedContracts,
  setOrderlineEndDateEdit,
} from "../../store/actions/invoicing";
import { AssetsDatePicker } from "../../components/assetsInputs";
import Draggable from "react-draggable";

function ChangesConfirm() {
  const [editedEndDate, setEditedEndDate] = useState(null);
  const dispatch = useDispatch();
  const headerRef = useRef();
  const panelARef = useRef();
  const panelBRef = useRef();
  const dateInputRef = useRef();
  useEffect(() => {
    dispatch(getAppUser());
    dispatch(getUnconifrmedContracts());
  }, [dispatch]);

  const isBigScreen2 = useMediaQuery({ query: "(min-width: 983px)" });

  let frontUser;
  const appUser = useSelector(selectAppUser);
  const uncoinfrmedContract = useSelector(selectUnconfirmedContract);
  const orderlineEndDateEdit = useSelector(selectOrderlineEndDateEdit);
  let selectedLine;
  if (uncoinfrmedContract && uncoinfrmedContract !== "nothingToConfirm") {
    selectedLine = uncoinfrmedContract.orderLines.find(
      (line) => line.qlineId === orderlineEndDateEdit.id
    );
  }
  useEffect(() => {
    setEditedEndDate(selectedLine ? selectedLine.end : null);
  }, [orderlineEndDateEdit]);
  if (appUser) {
    if (appUser.userDetails) {
      frontUser = `${appUser.userDetails.charAt(0).toUpperCase()}${
        appUser.userDetails.slice(1).split("@")[0]
      }`;
    }
  }
  const customDateFormat = (date, locale) => {
    let d = `0${date.getDate()}`.slice(-2),
      m = date.toLocaleString(locale, { month: "2-digit" }),
      y = date.getFullYear();
    return `${y}-${m}-${d}`;
  };
  const stringCut = (string, stringLength) => {
    const allParts = string.split(" ");
    let newIndex = 0;
    let newArr = [];
    for (let part of allParts) {
      if (
        `${newArr[newIndex] ? newArr[newIndex] : ""}${part} `.length >
        stringLength
      ) {
        newIndex++;
      }
      newArr[newIndex] = `${newArr[newIndex] ? newArr[newIndex] : ""}${part} `;
    }
    return newArr;
  };
  const dataLine = (desc, value, key, onClick) => {
    return (
      <div className="dasLine" key={key}>
        <div className="dasDesc">{desc}</div>
        <div className="dasValue" onClick={onClick}>
          {value}
        </div>
      </div>
    );
  };
  useEffect(() => {}, [dateInputRef]);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      dispatch(setOrderlineEndDateEdit({ id: 0, date: null }));
    }
  };

  const { contractId, customer, user, serviceId, twinfieldId } =
    uncoinfrmedContract ? uncoinfrmedContract : {};

  return (
    <div className="body">
      {uncoinfrmedContract && uncoinfrmedContract !== "nothingToConfirm" ? (
        <h3
          style={{ marginTop: "0", marginBottom: "0" }}
        >{`1/${uncoinfrmedContract.total}`}</h3>
      ) : uncoinfrmedContract === "nothingToConfirm" ? (
        <h3
          style={{ marginTop: "0", marginBottom: "0" }}
        >{`No changes to confirm`}</h3>
      ) : (
        ""
      )}
      {uncoinfrmedContract && uncoinfrmedContract !== "nothingToConfirm" ? (
        <div
          className={isBigScreen2 ? "globalSelection wide" : "globalSelection"}
        >
          <div className="globalSelectionGroup matching panel" ref={panelBRef}>
            {stringCut(customer ? customer : "", 40).map((line, i) => {
              return dataLine(i === 0 ? "Customer:" : "", line, i);
            })}
            {stringCut(user ? user : "", 40).map((line, i) =>
              dataLine(i === 0 ? "User:" : "", line, i)
            )}
          </div>
          <div className="globalSelectionSpacer"></div>
          <div className="globalSelectionGroup" ref={panelARef}>
            {dataLine("Contract:", contractId)}
            {dataLine("Service:", serviceId)}
            {dataLine("Twinfield:", twinfieldId)}
          </div>
        </div>
      ) : uncoinfrmedContract === "nothingToConfirm" ? (
        ""
      ) : (
        <img className="landingLogo ll" src={logo} alt="company logo" />
      )}
      {uncoinfrmedContract && uncoinfrmedContract !== "nothingToConfirm" ? (
        <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>
          Contract history
        </h2>
      ) : (
        ""
      )}
      {uncoinfrmedContract && uncoinfrmedContract !== "nothingToConfirm" ? (
        <div className="midTableWrapper" ref={headerRef}>
          <NewTable
            tabletype={"small"}
            tit="serviceHistory"
            data={uncoinfrmedContract.contractHistory.map((line) => {
              return {
                ...line,
                start_t: line.start_t
                  ? customDateFormat(new Date(line.start_t), undefined)
                  : null,
                implementation_t: line.implementation_t
                  ? customDateFormat(new Date(line.implementation_t), undefined)
                  : null,
              };
            })}
            // tit="unaprovedDeliveries"
            // data={uncoinfrmedContract.deliveries.map((line) => {
            //   return {
            //     ...line,
            //     startDate: line.startDate
            //       ? customDateFormat(new Date(line.startDate), undefined)
            //       : null,
            //   };
            // })}
            width={headerRef.current ? headerRef.current.clientWidth : 100}
          />
        </div>
      ) : (
        ""
      )}
      {uncoinfrmedContract && uncoinfrmedContract !== "nothingToConfirm" ? (
        <h2 style={{ marginTop: "20px", marginBottom: "5px" }}>Orderlines</h2>
      ) : (
        ""
      )}
      {uncoinfrmedContract && orderlineEndDateEdit.id !== 0 ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Draggable>
            <Dialog
              hideBackdrop={true}
              open={orderlineEndDateEdit.id !== 0}
              onClose={handleClose}
              // onOpe
              // PaperComponent={PaperComponent}
              sx={{
                borderRadius: "20px",
              }}
            >
              <DialogTitle
                sx={{
                  minWidth: 400,
                  padding: 1,
                  maxWidth: 1000,
                  backgroundColor: "#35647e",
                  color: "white",
                  textAlign: "center",
                  marginBottom: 1,
                  fontFamily: "Quenda, serif",
                }}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                Set the end date
              </DialogTitle>
              <DialogContent
                sx={{ minWidth: 400, padding: 1, maxWidth: 1000, top: 5 }}
              >
                <Box
                  component="form"
                  sx={{
                    marginTop: 0,
                    marginBottom: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                  }}
                >
                  <p style={{ margin: "2px" }}>
                    {uncoinfrmedContract.orderLines.find(
                      (orderline) =>
                        orderline.qlineId === orderlineEndDateEdit.id
                    )
                      ? uncoinfrmedContract.orderLines.find(
                          (orderline) =>
                            orderline.qlineId === orderlineEndDateEdit.id
                        ).description
                      : ""}
                  </p>
                </Box>
                <Box
                  component="form"
                  onSubmit={(e) => e.preventDefault()}
                  sx={{
                    marginTop: 0,
                    marginBottom: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                  }}
                >
                  <AssetsDatePicker
                    ref={dateInputRef}
                    desc="Invoice date:"
                    value={dayjs(editedEndDate)}
                    onChangeFunc={(value) => {
                      setEditedEndDate(value);
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Quenda, serif !important",
                    background: "#35647e",
                    color: "white",
                  }}
                  onClick={async (e) => {
                    setEditedEndDate(null);
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Quenda, serif !important",
                    background: "#f9323f",
                    color: "white",
                  }}
                  onClick={(e) => {
                    setEditedEndDate(null);
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Quenda, serif !important",
                    background: "#4ab217",
                    color: "white",
                  }}
                  disabled={
                    editedEndDate === null
                      ? false
                      : !new Date(editedEndDate).getDate()
                      ? true
                      : false
                  }
                  onClick={async (e) => {
                    let updatedLineIndex =
                      uncoinfrmedContract.orderLines.indexOf(
                        uncoinfrmedContract.orderLines.find(
                          (orderline) =>
                            orderline.qlineId === orderlineEndDateEdit.id
                        )
                      );
                    let updatedLine = {
                      ...uncoinfrmedContract.orderLines[updatedLineIndex],
                      end: !editedEndDate
                        ? null
                        : new Date(editedEndDate).toISOString(),
                    };
                    let newArr = [...uncoinfrmedContract.orderLines];
                    newArr[updatedLineIndex] = updatedLine;
                    dispatch(
                      storeUnconifrmed({
                        ...uncoinfrmedContract,
                        orderLines: newArr,
                      })
                    );
                    handleClose();
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Draggable>
        </LocalizationProvider>
      ) : (
        ""
      )}
      {uncoinfrmedContract && uncoinfrmedContract !== "nothingToConfirm" ? (
        <div className="midTableWrapper">
          <NewTable
            tabletype={"small"}
            tit="unaprovedOrderlines"
            data={uncoinfrmedContract.orderLines.map((line) => {
              return {
                ...line,
                start_dis: line.start
                  ? customDateFormat(new Date(line.start), undefined)
                  : null,
                end_dis: line.end
                  ? customDateFormat(new Date(line.end), undefined)
                  : null,
                invoice_dis: line.invoice
                  ? customDateFormat(new Date(line.invoice), undefined)
                  : null,
              };
            })}
            width={headerRef.current ? headerRef.current.clientWidth : 100}
          />
        </div>
      ) : (
        ""
      )}
      {uncoinfrmedContract && uncoinfrmedContract !== "nothingToConfirm" ? (
        <div style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Quenda, serif !important",
              background: "#4ab217",
              color: "white",
            }}
            onClick={async (e) => {
              dispatch(
                updateUnconifrmed({
                  contractId: uncoinfrmedContract.contractId,
                  orderLines: uncoinfrmedContract.orderLines.map((line) => {
                    return { qlineId: line.qlineId, endDate: line.end };
                  }),
                })
              );
            }}
          >
            Confirm
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ChangesConfirm;

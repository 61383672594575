import "../../css/assets.css";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import logo from "../../geuzenetlog.png";
import NewTable from "../../components/newTable/newTable";

import {
  setCategories,
  getAppUser,
  reduxInit,
} from "../../store/actions/navigation";
import { selectAppUser } from "../../store/selectors/navigation";
import { getAllHardware } from "../../store/actions/assets";
import { selectAllHardware } from "../../store/selectors/assets";

const validUser = (user) =>
  user
    ? !(!user.userRoles.includes("user") && !user.userRoles.includes("admin"))
    : false;
function AssetsDevices() {
  const appUser = useSelector(selectAppUser);
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const headerRef = useRef();
  const dispatch = useDispatch();
  const hardware = useSelector(selectAllHardware);

  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Assets", "Devices", "list"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);

  useEffect(() => {
    console.log(hardware.length, validUser(appUser));
    dispatch(getAppUser());
    const getItems = () => {
      if (hardware.length === 0) {
        dispatch(getAllHardware());
      }
    };
    if (validUser(appUser)) {
      getItems();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hardware.length, document.title]);
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <div className={hardware.length === 0 ? "Assets loading" : "Assets"}>
        <>
          <div className="topBar"></div>
          <div className="bigTableWrapper hwListTable" ref={headerRef}>
            {hardware.length > 0 ? (
              <NewTable
                wrapperRef={headerRef}
                tit={"devicesOverview"}
                tabletype={"big"}
                data={hardware}
                width={headerRef.current ? headerRef.current.clientWidth : 100}
              />
            ) : (
              <img className="landingLogo" src={logo} alt="company logo" />
            )}
          </div>
        </>
      </div>
    );
  }
}

export default AssetsDevices;

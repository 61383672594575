import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import { selectInvoicingSteps } from "../../store/selectors/navigation";
import { selectNewInvoices } from "../../store/selectors/invoicing";
import { getAppUser, setInvoicingSteps } from "../../store/actions/navigation";
import { MemoPanelComponent } from "../../components/invPreselPanel";

function NewInvoicesPreselect() {
  const invoicingSteps = useSelector(selectInvoicingSteps);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppUser());
  }, [dispatch]);

  const newInvoices = useSelector(selectNewInvoices);
  let selectedInvoices = [];
  if (invoicingSteps.singleInvoices) {
    selectedInvoices = newInvoices
      .filter(
        (invoice) =>
          invoicingSteps.includedInvoicesIds.includes(invoice.id) &&
          !invoice.collective_invoice
      )
      .map((invoice) => {
        return {
          customer: invoice.customer,
          twinfield_id: invoice.twinfield_id,
          language: invoice.language,
          agent: invoice.agent,
          subInvoices: [
            {
              id: invoice.id,
              description: invoice.description,
              invoiceLines: invoice.invoiceLines,
            },
          ],
        };
      })
      .sort(
        (a, b) =>
          a.subInvoices[0].description.split("\n")[3].split(" ")[2] -
          b.subInvoices[0].description.split("\n")[3].split(" ")[2]
      );
  } else if (invoicingSteps) {
    let collectiveTwinFieldIds = [
      ...new Set(
        newInvoices
          .filter(
            (invoice) =>
              invoicingSteps.includedInvoicesIds.includes(invoice.id) &&
              invoice.collective_invoice
          )
          .map((invoice) => invoice.twinfield_id)
      ),
    ];
    for (let org of collectiveTwinFieldIds) {
      let orgInvoices = newInvoices.filter(
        (invoice) => invoice.twinfield_id === org
      );
      let genDescBit = {
        "en-GB": {
          reverse: "Collective invoice with VAT reverse charged (BTW verlegd).",
          nonReverse:
            "Collective invoice with VAT reverse charged (BTW verlegd).",
        },
        "nl-NL": {
          reverse: "Verzamelfactuur met BTW verlegd.",
          nonReverse: "Verzamelfactuur.",
        },
        "de-DE": {
          reverse:
            "Sammelrechnung mit Reverse-Charge-Mehrwertsteuer (BTW verlegd).",
          nonReverse: "Sammelrechnung.",
        },
      };
      selectedInvoices.push({
        customer: orgInvoices[0].customer,
        twinfield_id: orgInvoices[0].twinfield_id,
        language: orgInvoices[0].language,
        generalDesc: orgInvoices[0].transfer_vat
          ? genDescBit[orgInvoices[0].language].reverse
          : genDescBit[orgInvoices[0].language].nonReverse,
        subInvoices: orgInvoices
          .filter((invoice) =>
            invoicingSteps.includedInvoicesIds.includes(invoice.id)
          )
          .map((invoice) => {
            return {
              id: invoice.id,
              description: invoice.description,
              invoiceLines: invoice.invoiceLines,
            };
          })
          .sort(
            (a, b) =>
              a.description.split("\n")[3].split(" ")[2] -
              b.description.split("\n")[3].split(" ")[2]
          ),
      });
    }
    selectedInvoices.sort(
      (a, b) =>
        a.subInvoices[0].description.split("\n")[3].split(" ")[2] -
        b.subInvoices[0].description.split("\n")[3].split(" ")[2]
    );
  }
  return (
    <div className="invoicingBody">
      {selectedInvoices.length > 0 ? (
        <button
          onClick={() => {
            dispatch(
              setInvoicingSteps({
                ...invoicingSteps,
                invoiceIndex: invoicingSteps.invoiceIndex - 1,
              })
            );
          }}
          disabled={invoicingSteps.invoiceIndex < 1}
        >
          {"<"}
        </button>
      ) : (
        ""
      )}
      {selectedInvoices.length > 0
        ? `${invoicingSteps.invoiceIndex + 1}/${selectedInvoices.length}`
        : ""}
      {selectedInvoices.length > 0 ? (
        <button
          onClick={() => {
            dispatch(
              setInvoicingSteps({
                ...invoicingSteps,
                invoiceIndex: invoicingSteps.invoiceIndex + 1,
              })
            );
          }}
          disabled={invoicingSteps.invoiceIndex + 1 >= selectedInvoices.length}
        >
          {">"}
        </button>
      ) : (
        ""
      )}
      {selectedInvoices.length > 0 ? (
        <MemoPanelComponent
          customer={selectedInvoices[invoicingSteps.invoiceIndex].customer}
          twinfieldId={
            selectedInvoices[invoicingSteps.invoiceIndex].twinfield_id
          }
          generalDesc={
            selectedInvoices[invoicingSteps.invoiceIndex].generalDesc
          }
          subInvoices={
            selectedInvoices[invoicingSteps.invoiceIndex].subInvoices
          }
          language={selectedInvoices[invoicingSteps.invoiceIndex].language}
          agent={selectedInvoices[invoicingSteps.invoiceIndex].agent}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default NewInvoicesPreselect;

import "../css/landing.css";
import logo from "../geuzenetlog.png";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import { selectAppUser } from "../store/selectors/navigation";

import { getAppUser } from "../store/actions/navigation";

function LandingPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppUser());
  }, [dispatch]);
  let frontUser;
  const appUser = useSelector(selectAppUser);
  if (appUser) {
    if (appUser.userDetails) {
      frontUser = `${appUser.userDetails.charAt(0).toUpperCase()}${
        appUser.userDetails.slice(1).split("@")[0]
      }`;
    }
  }
  return (
    <div className="body">
      <div className="LandingPage">
        <h1>{frontUser ? `Welcome ${frontUser}.` : ""}</h1>
        {frontUser ? (
          <p>Use navigation to continue.</p>
        ) : (
          <p>Log in to continue.</p>
        )}
        <img className="landingLogo still" src={logo} alt="company logo" />
      </div>
    </div>
  );
}

export default LandingPage;

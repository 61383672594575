const initialState = {
  allTables: [],
  theTableMeta: [],
  theTable: [],
  originalTable: [],
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "psqlEdit/storeAllTables":
      return { ...state, allTables: action.payload };
    case "psqlEdit/storeTheTable":
      return {
        ...state,
        theTable: action.payload,
        originalTable: action.payload,
      };
    case "psqlEdit/cleanTheTable":
      return { ...state, theTable: [] };
    case "psqlEdit/storeTheTableMeta":
      return { ...state, theTableMeta: action.payload };
    case "psqlEdit/storeError":
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
